import { useEffect, useState } from "react";
import {
    addConnectionHandler,
    addDisconnectionHandler,
    removeConnectionHandler,
    removeDisconnectionHandler,
} from "./socket";

export function useConnection() {
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        const disconnectCb = () => setConnected(false);
        const connectCb = () => setConnected(true);
        addDisconnectionHandler(disconnectCb);
        addConnectionHandler(connectCb);

        return () => {
            removeDisconnectionHandler(disconnectCb);
            removeConnectionHandler(connectCb);
        };
    }, []);

    return [connected];
}
