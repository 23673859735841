export const JUMP_DURATION = 800;

export const SPOTLIGHT_TRANSITION_DURATION = 1000;
export const BLINDFOLD_TRANSITION_DURATION = 1000;
export const LIMIT_DRAW_RANDOM_INTERVAL = 600;
const AFK_SPECTATE_MINUTES = 3;
export const AFK_SPECTATE_DURATION = 60000 * AFK_SPECTATE_MINUTES;

export const EXHIBIT_INTERVAL = 15000;
export const EXHIBIT_FADE_DURATION = 1000;
const AFK_LOBBY_MINUTES = 1;
export const AFK_LOBBY_DURATION = 60000 * AFK_LOBBY_MINUTES;

export const MESSAGE_BLOCK_DURATION = 200;
export const MESSAGE_DURATION = 7000;

export const NOTIFICATION_DURATION = 6000;
export const NOTIFICATION_RESUME_DURATION = 1000;
export const NOTIFICATION_ENTER_DURATION = 400;
export const NOTIFICATION_EXIT_DURATION = 300;

export const MOVE_ANIMATION_BECOMES_IDLE_DURATION = 100;

export const MOVE_FRAMES_SWAP_TIME = 150;
export const IDLE_FRAMES_SWAP_TIME = 500;

export const RESIZE_THROTTLE = 100;

export const BLINK_DURATION = 30;
export const BLINK_CONSECUTIVE_INTERVAL = 50;
export const BLINK_INTERVAL_MIN = 3000;
export const BLINK_INTERVAL_MAX = 4800;

export const COIN_ADDITION_APPEARANCE_DURATION = 2500;
