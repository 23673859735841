import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CHAR_WIDTH } from "../../constants/sizes";
import { getBoundedX } from "../../services/positions";
import { addMessageToHistory } from "../chat/ChatHistory";
import ChatTooltip from "../chat/ChatTooltip";
import LazyChar from "./char/LazyChar";
import { CharPosition } from "./CharPosition";
import { JumpContainer } from "./JumpContainer";
import NameContainer from "./NameContainer";

const CharContainer = styled.div``;

const ChatContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const TurnRightAttributes = {
    transform: "scaleX(-1)",
    transformOrigin: `${CHAR_WIDTH / 2}px`,
};

const TurnLeftAttributes = {};

/** @type {{[id: string]: React.MutableRefObject}} */
const actorRefs = {};
/** @type {{[id: string]: number}} */
export const actorXs = {};

export function setActorX(id, x) {
    const actorRef = actorRefs[id];
    if (actorRef && actorRef.current) {
        const boundedX = getBoundedX(x);
        actorRef.current.style.transform = `translate(${boundedX}px)`;
        actorXs[id] = boundedX;
    }
}

const MoveableActor = memo(function MoveableActor(props) {
    const {
        id,
        x: initialX,
        name,
        turningRight,
        jumpIndex,
        message,
        messageIndex,
        isMoving,
        style,
        emote,
        ...indexes
    } = props;
    const actorRef = useRef(null);
    const jumpRef = useRef(null);
    const chatContainerRef = useRef(null);
    const [isJumpingIndex, setIsJumpingIndex] = useState(0);
    const [disableJumpingAnimation, setDisableJumpingAnimation] = useState(false);

    useEffect(() => {
        actorRefs[id] = actorRef;
        return () => delete actorRefs[id];
    }, [id]);

    if (actorXs[id] === undefined) {
        actorXs[id] = getBoundedX(initialX);
    }
    useEffect(() => {
        return () => delete actorXs[id];
    }, [id, initialX]);

    useEffect(() => {
        if (jumpIndex) {
            setIsJumpingIndex((isJumpingIndex) => isJumpingIndex + 1);
        }
    }, [jumpIndex]);

    useEffect(() => {
        if (messageIndex) {
            addMessageToHistory({ message, messageIndex, actorId: id });
        }
    }, [message, messageIndex, id]);

    const reduceJumpCount = useCallback((event) => {
        // can't have more than one jump pending in queue - that's just a real lag.
        setIsJumpingIndex((isJumpingIndex) => Math.min(isJumpingIndex - 1, 1));
        setDisableJumpingAnimation(true);
        const timerId = setTimeout(() => setDisableJumpingAnimation(false));
        return () => clearTimeout(timerId);
    }, []);

    const stopPropagationHandler = useCallback((event) => event.stopPropagation(), []);

    return (
        <CharPosition
            style={{
                transform: `translateX(${actorXs[id]}px)`,
                ...style,
            }}
            ref={actorRef}>
            <ChatTooltip message={message} messageIndex={messageIndex} charId={id} containerRef={chatContainerRef} />
            <JumpContainer
                ref={jumpRef}
                isJumping={!disableJumpingAnimation && isJumpingIndex}
                onAnimationEnd={reduceJumpCount}>
                <ChatContainer ref={chatContainerRef}>
                    <CharContainer
                        onAnimationEnd={stopPropagationHandler}
                        style={turningRight ? TurnRightAttributes : TurnLeftAttributes}>
                        <LazyChar
                            {...indexes}
                            emote={emote}
                            isMoving={isMoving}
                            shadow
                            isJumping={!disableJumpingAnimation && isJumpingIndex}
                        />
                    </CharContainer>
                    <NameContainer>{name}</NameContainer>
                </ChatContainer>
            </JumpContainer>
        </CharPosition>
    );
});

export default MoveableActor;
