import { Fab, Tooltip } from "@material-ui/core";
import { AssignmentTurnedInOutlined, FeedbackOutlined, HomeOutlined, PhonelinkSetup } from "@material-ui/icons";
import React, { memo } from "react";
import { Link, useLocation } from "react-router-dom";

const SECRET_DRAWINGS_PATH = "/review-drawings";
const SECRET_FEEDBACK_PATH = "/review-feedback";
const SECRET_CONFIG_PATH = "/config";

const SecretNavigation = memo(function SecretNavigation() {
    const routerLocation = useLocation();
    const isInReview = routerLocation.pathname === SECRET_DRAWINGS_PATH;
    const isInFeedback = routerLocation.pathname === SECRET_FEEDBACK_PATH;
    const isInConfig = routerLocation.pathname === SECRET_CONFIG_PATH;

    return (
        <div>
            <Tooltip title={isInReview ? "Home" : "Drawings"}>
                <Fab color="secondary" size="small" component={Link} to={isInReview ? "/" : SECRET_DRAWINGS_PATH}>
                    {isInReview ? <HomeOutlined /> : <AssignmentTurnedInOutlined />}
                </Fab>
            </Tooltip>
            <Tooltip title={isInFeedback ? "Home" : "Feedback"}>
                <Fab
                    color="secondary"
                    size="small"
                    component={Link}
                    to={isInFeedback ? "/" : SECRET_FEEDBACK_PATH}
                    style={{ marginInlineStart: "4px" }}>
                    {isInFeedback ? <HomeOutlined /> : <FeedbackOutlined />}
                </Fab>
            </Tooltip>
            <Tooltip title={isInConfig ? "Home" : "Config"}>
                <Fab
                    color="secondary"
                    size="small"
                    component={Link}
                    to={isInConfig ? "/" : SECRET_CONFIG_PATH}
                    style={{ marginInlineStart: "4px" }}>
                    {isInConfig ? <HomeOutlined /> : <PhonelinkSetup />}
                </Fab>
            </Tooltip>
        </div>
    );
});

export default SecretNavigation;
