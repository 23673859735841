import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { CHAR_BOTTOM_DISTANCE, TOY_GAMES_HEIGHT, TOY_GAMES_OFFSET } from "../../constants/sizes";
import { showNotification } from "../../services/room";
import { getStorageVisibility } from "../../services/storageHandlers";
import { isSmallScreen } from "../../services/windowSize";
import { ACTION_CALLBACK, ACTION_DISMISS, ACTION_SET_SHOWN } from "./Notifications";

const PERSIST_KEY = "first-time-spotlighted";

const Spotlight = styled.div`
    background: radial-gradient(
        1px 1px at calc(50% - ${TOY_GAMES_OFFSET}px) calc(100% - ${CHAR_BOTTOM_DISTANCE + TOY_GAMES_HEIGHT / 2}px),
        transparent 0,
        transparent ${TOY_GAMES_HEIGHT - Math.floor(TOY_GAMES_HEIGHT / 10)}px,
        rgba(0, 0, 0, 0.6) ${TOY_GAMES_HEIGHT}px
    );
    height: 100%;
    position: absolute;
    width: 100%;
`;

const FirstTimeMessage = memo(function FirstTimeMessage() {
    const [showSpotlight, setShowSpotlight] = useState(false);

    useEffect(() => {
        if (!getStorageVisibility(PERSIST_KEY)) {
            setShowSpotlight(true);
            showNotification({
                messageKey: "noticeFirstTime",
                persistKey: PERSIST_KEY,
                variant: "info",
                actions: [
                    {
                        key: "dialogButtonOkay",
                        onClicks: [
                            { action: ACTION_DISMISS },
                            { action: ACTION_SET_SHOWN },
                            { action: ACTION_CALLBACK, value: () => setShowSpotlight(false) },
                        ],
                    },
                ],
                showOnce: true,
            });
        }
    }, []);
    return showSpotlight ? <Spotlight isSmall={isSmallScreen()} /> : null;
});

export default FirstTimeMessage;
