import { TextField } from "@material-ui/core";
import React, { memo, useCallback } from "react";
import { useName } from "../../services/profile/useName";
import { useLocale } from "../locale/LocaleProvider";

const NameInput = memo(function NameInput({ autoFocus = false }) {
    const [name, setName] = useName();
    const { translate } = useLocale();

    const updateName = useCallback(
        (event) => {
            setName(event.target.value);
        },
        [setName]
    );

    return (
        <TextField
            label={translate("labelName")}
            variant="outlined"
            size="small"
            autoFocus={autoFocus}
            value={name}
            onChange={updateName}
            inputProps={{ maxLength: 16, style: { unicodeBidi: "plaintext" } }}
        />
    );
});

export default NameInput;
