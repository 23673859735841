import { Fade, IconButton, withStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useLocale } from "../locale/LocaleProvider";

const PADDING = 16;
const MAX_MESSAGES = 200;

const Container = styled.div`
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    max-width: calc(100vw - 16px);
    height: 550px;
    max-height: calc(100vh - 100px);
    box-sizing: border-box;
    width: 720px;
    padding: ${PADDING}px;
    margin-bottom: 12px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    color: white;
    text-align: initial;
    white-space: break-spaces;
    overflow-y: auto;
    word-break: break-word;
`;

const ChatRow = styled.div``;

const CustomIconButton = withStyles({
    root: {
        position: "sticky",
        marginInlineEnd: -PADDING,
        marginTop: -PADDING,
        top: -PADDING,
    },
})(IconButton);

let addMessageToHistoryHandler = () => {};
export function addMessageToHistory() {
    addMessageToHistoryHandler.apply(null, arguments);
}

const ChatHistory = memo(function ChatHistory({ showHistory, toggleShowHistory, actors }) {
    const [messages, setMessages] = useState([]);
    const chatEndRef = useRef(null);
    const { isRtl } = useLocale();

    useEffect(() => {
        addMessageToHistoryHandler = ({ message, messageIndex, actorId }) => {
            setMessages((oldMessages) => {
                const clonedMessages = [...oldMessages];
                clonedMessages.push({
                    message,
                    key: actorId + "-" + messageIndex,
                    name: actors[actorId].name,
                });
                if (clonedMessages.length > MAX_MESSAGES) {
                    clonedMessages.shift();
                }
                return clonedMessages;
            });
        };
        return () => (addMessageToHistoryHandler = () => {});
    }, [actors]);

    useEffect(() => {
        if (chatEndRef && messages.length) {
            chatEndRef.current.scrollIntoView();
        }
    }, [chatEndRef, messages]);

    return (
        <Fade in={showHistory}>
            <Container>
                <CustomIconButton
                    onClick={toggleShowHistory}
                    color="inherit"
                    style={isRtl ? { float: "left" } : { float: "right" }}>
                    <Close />
                </CustomIconButton>
                {messages.map(({ message, key, name }) => {
                    return (
                        <ChatRow key={key}>
                            <bdi>{name}</bdi>: {message}
                        </ChatRow>
                    );
                })}
                <div ref={chatEndRef} />
            </Container>
        </Fade>
    );
});

export default ChatHistory;
