import { useCallback } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { REWARDS_BY_PART } from "../../constants/skins";
import { getStorageUnlocks, setStorageHasSkin } from "../storageHandlers";
import { useCoins } from "./coins";

const initialState = {
    unlocks: getStorageUnlocks(),
};
const { useGlobalState } = createGlobalState(initialState);

export function useUnlocks() {
    const [unlocks, setUnlocks] = useGlobalState("unlocks");
    const [, { removeCoins }] = useCoins();

    const unlockSkin = useCallback(
        (part, partIndex) => {
            setUnlocks((unlocks) => {
                const cloned = { ...unlocks };
                cloned[part] = { ...cloned[part] };
                cloned[part][partIndex] = true;
                setStorageHasSkin(part, partIndex);
                return cloned;
            });
            const cost = REWARDS_BY_PART[part][partIndex].cost;
            removeCoins(cost);
        },
        [setUnlocks, removeCoins],
    );

    return [unlocks, { unlockSkin }];
}

export function isSkinPartLocked(unlocks, part, partIndex) {
    // doesn't need to be unlockable.
    if (!REWARDS_BY_PART[part] || !REWARDS_BY_PART[part][partIndex]) {
        return false;
    }
    // unlocked.
    if (unlocks[part] && unlocks[part][partIndex]) {
        return false;
    }
    return true;
}
