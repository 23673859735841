import { FormControl, InputLabel, MenuItem, Select, SvgIcon } from "@material-ui/core";
import React, { memo } from "react";
import { LOCALES } from "../../../constants/locales";

const SettingLocale = memo(function SettingLocale({ text, selectLocale, locale, disabled, style, placeholder }) {
    return (
        <FormControl disabled={disabled} style={style}>
            {text ? <InputLabel>{text}</InputLabel> : null}
            <Select
                label={text}
                onChange={selectLocale}
                value={locale}
                displayEmpty
                SelectDisplayProps={{ style: { paddingInlineStart: 16 } }}>
                {placeholder ? (
                    <MenuItem key="" value="" style={{ height: 50 }}>
                        {placeholder}
                    </MenuItem>
                ) : null}
                {Object.keys(LOCALES).map((supportedLocale, index) => {
                    const Icon = LOCALES[supportedLocale].icon;
                    return (
                        <MenuItem key={index} value={supportedLocale}>
                            <div style={{ display: "flex", alignItems: "center", gap: 8, padding: "8px 0" }}>
                                <SvgIcon fontSize="small">
                                    <Icon />
                                </SvgIcon>
                                {LOCALES[supportedLocale].display}
                            </div>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
});

export default SettingLocale;
