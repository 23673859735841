import io from "socket.io-client";
import { apiDomain } from "./api";
import { inDesktop } from "./desktop/inDesktop";
import { getPotato2, setPotato2 } from "./storageHandlers";

/** @type {!SocketIOClient.Socket} */
let socket;

let connectionHandlers = new Set();
let disconnectionHandlers = new Set();
let errorHandlers = new Set();

export function getSocket({ roomCode, serverName } = {}) {
    if (socket) {
        return socket;
    }

    const potato2 = getPotato2();
    const query = { potato2, roomCode };
    if (inDesktop) {
        query.platform = "desktop";
    }
    socket = io(serverName || apiDomain, {
        reconnection: false,
        transports: ["websocket"],
        query,
    });
    socket.on("connect_error", errorHandler);
    socket.on("connect_timeout", errorHandler);
    socket.on("error", errorHandler);
    socket.on("disconnect", errorHandler);
    socket.on("connect", onConnected);
    socket.on("potato2", potatoHandler);

    return socket;
}

export function getSocketMaybe() {
    return socket;
}

export function disconnectSocket() {
    onDisconnected();
}

export function getYourId() {
    return socket && socket.id;
}
export function isYourId(id) {
    return socket && socket.id === id;
}

export function addErrorHandler(cb) {
    errorHandlers.add(cb);
}
export function removeErrorHandler(cb) {
    errorHandlers.delete(cb);
}
function errorHandler(error) {
    console.error("Server error:", error);
    for (const cb of errorHandlers) {
        cb(error);
    }
    onDisconnected();
}
export function addDisconnectionHandler(cb) {
    disconnectionHandlers.add(cb);
}
export function removeDisconnectionHandler(cb) {
    disconnectionHandlers.delete(cb);
}
function onDisconnected() {
    if (socket) {
        socket.removeAllListeners();
        socket.disconnect();
    }
    socket = null;

    for (const cb of disconnectionHandlers) {
        cb();
    }
}
export function addConnectionHandler(cb) {
    connectionHandlers.add(cb);
}
export function removeConnectionHandler(cb) {
    connectionHandlers.delete(cb);
}
function onConnected() {
    for (const cb of connectionHandlers) {
        cb();
    }
}

function potatoHandler({ potato2 }) {
    setPotato2(potato2);
}
