import nipplejs from "nipplejs";
import { memo, useEffect } from "react";
import { JUMP_DURATION } from "../../constants/durations";
import { ACC_MODIFIER, MIN_MOVE_DEGREE, SPEED_MODIFIER } from "../../constants/modifiers";
import { isInputActive } from "../../services/input/active";
import { getBoundedX } from "../../services/positions";
import { jumpActor, moveActor } from "../../services/room";

const KEY_W = 87;
const KEY_A = 65;
const KEY_D = 68;
const KEY_UP = 38;
const KEY_RIGHT = 39;
const KEY_LEFT = 37;
const KEY_SPACE = 32;

const ACTION_RIGHT = "RIGHT";
const ACTION_LEFT = "LEFT";
const ACTION_JUMP = "JUMP";

const MAP_KEY_TO_ACTION = {
    [KEY_W]: ACTION_JUMP,
    [KEY_A]: ACTION_LEFT,
    [KEY_D]: ACTION_RIGHT,
    [KEY_UP]: ACTION_JUMP,
    [KEY_RIGHT]: ACTION_RIGHT,
    [KEY_LEFT]: ACTION_LEFT,
    [KEY_SPACE]: ACTION_JUMP,
};

const ActorInput = memo(function ActorInput() {
    useEffect(() => {
        const activeActions = {};
        let mostRecentSideAction = null;
        let x = 0;
        let dx = 0;
        let wasMoving = false;
        let moveAnimationFrame = null;
        let stopJumpingTimer = null;
        let isJumping = false;

        window.addEventListener("keydown", startMovingIfKeyMatch);
        window.addEventListener("keyup", stopMovingIfKeyMatch);

        function startMovingIfKeyMatch(event) {
            // Ignore when an input is focused
            if (isInputActive()) {
                return;
            }
            startMovingIfMatch(MAP_KEY_TO_ACTION[event.which]);
            stopDefaultBehaviors(event);
        }

        function startMovingIfMatch(action) {
            if (action === ACTION_RIGHT || action === ACTION_LEFT) {
                activeActions[action] = true;
                mostRecentSideAction = action;
            }
            if (action === ACTION_JUMP) {
                activeActions[action] = true;
                if (!isJumping) {
                    startJumping();
                }
            }
        }

        function stopMovingIfKeyMatch(event) {
            stopMovingIfMatch(MAP_KEY_TO_ACTION[event.which]);
            stopDefaultBehaviors(event);
        }
        function stopMovingIfMatch(action) {
            if (!activeActions[action]) {
                return;
            }
            if (action === ACTION_RIGHT || action === ACTION_LEFT) {
                activeActions[action] = false;
                mostRecentSideAction = null;
            }
            if (action === ACTION_JUMP) {
                activeActions[action] = false;
            }
        }

        function stopMovingActions() {
            activeActions[ACTION_RIGHT] = false;
            activeActions[ACTION_LEFT] = false;
            activeActions[ACTION_JUMP] = false;
        }

        function tickMoveTimer(lastTimestamp) {
            moveAnimationFrame = requestAnimationFrame(() => {
                const nowTimestamp = Date.now();
                const frameDuration = nowTimestamp - lastTimestamp;
                const normalFrameDuration = 1000 / 60;
                const frameFraction = frameDuration / normalFrameDuration;
                let movingRight = false;
                let movingLeft = false;
                if (activeActions[ACTION_RIGHT] && mostRecentSideAction !== ACTION_LEFT) {
                    movingRight = true;
                }
                if (activeActions[ACTION_LEFT] && mostRecentSideAction !== ACTION_RIGHT) {
                    movingLeft = true;
                }
                const lastDx = dx;
                const acc = ACC_MODIFIER * frameFraction;
                if (movingRight) {
                    dx = Math.min(dx + acc, SPEED_MODIFIER);
                } else if (movingLeft) {
                    dx = Math.max(dx - acc, -SPEED_MODIFIER);
                } else {
                    if (dx > 0) {
                        dx = Math.max(dx - acc, 0);
                    } else {
                        dx = Math.min(dx + acc, 0);
                    }
                }
                const isMoving = movingRight || movingLeft;
                if (lastDx || dx || wasMoving) {
                    x += dx * frameFraction;
                    x = getBoundedX(x);
                    moveActor(x, isMoving, movingRight);
                }
                wasMoving = isMoving;
                tickMoveTimer(nowTimestamp);
            });
        }

        function startJumping() {
            isJumping = true;
            jumpActor();
            stopJumpingTimer = setTimeout(() => {
                isJumping = false;
                if (activeActions[ACTION_JUMP]) {
                    startJumping();
                }
            }, JUMP_DURATION);
        }

        // Prevent the annoying default behavior of keys, so you don't click buttons while jumping.
        function stopDefaultBehaviors(event) {
            if (event.which === KEY_SPACE) {
                event.preventDefault();
            }
        }

        const manager = nipplejs.create({
            dynamicPage: true,
            zone: document.getElementById("touch-zone"),
        });
        manager.on("start", () => {
            // nipplejs doesn't blur focuses, so buttons / inputs are need to manually blur.
            if (document.activeElement) {
                document.activeElement.blur();
            }
        });
        manager.on("move", (event, data) => {
            if (!data.direction) {
                stopMovingActions();
                return;
            }
            if (data.direction.angle === "up") {
                startMovingIfMatch(ACTION_JUMP);
            } else {
                stopMovingIfMatch(ACTION_JUMP);
            }
            const { degree } = data.angle;
            if (degree < 90 - MIN_MOVE_DEGREE || degree > 270 + MIN_MOVE_DEGREE) {
                startMovingIfMatch(ACTION_RIGHT);
            } else {
                stopMovingIfMatch(ACTION_RIGHT);
            }
            if (degree > 90 + MIN_MOVE_DEGREE && degree < 270 - MIN_MOVE_DEGREE) {
                startMovingIfMatch(ACTION_LEFT);
            } else {
                stopMovingIfMatch(ACTION_LEFT);
            }
        });
        manager.on("end", () => {
            stopMovingActions();
        });

        tickMoveTimer(Date.now());

        return () => {
            window.removeEventListener("keydown", startMovingIfKeyMatch);
            window.removeEventListener("keyup", stopMovingIfKeyMatch);
            cancelAnimationFrame(moveAnimationFrame);
            clearTimeout(stopJumpingTimer);
            if (manager) {
                manager.destroy();
            }
        };
    }, []);

    return null;
});

export default ActorInput;
