import { memo, useEffect, useState } from "react";
import { SECONDS_TO_PLAY_TICK } from "../../constants/modifiers";
import { playSfx, sfxClockTick } from "../../services/audio";
import { offNotificationTimer, onNotificationTimer } from "../../services/room";
import { useLocale } from "../locale/LocaleProvider";

const NotificationMessage = memo(function NotificationMessage({
    message,
    coins,
    initialTimerSeconds,
    notificationPersistKey,
    messageRemovedCallbacks,
}) {
    const [isHiding, setIsHiding] = useState(false);
    const [seconds, setSeconds] = useState(null);
    const [timestampEnd, setTimestampEnd] = useState(0);
    const { translate } = useLocale();

    useEffect(() => {
        if (initialTimerSeconds) {
            updateTimestampEnd(initialTimerSeconds);
        }
    }, [initialTimerSeconds]);

    useEffect(() => {
        if (notificationPersistKey) {
            messageRemovedCallbacks[notificationPersistKey] = () => {
                setIsHiding(true);
                delete messageRemovedCallbacks[notificationPersistKey];
            };
        }
    }, [messageRemovedCallbacks, notificationPersistKey]);

    useEffect(() => {
        if (notificationPersistKey && !isHiding) {
            function callback({ persistKey, timerSeconds }) {
                if (notificationPersistKey === persistKey) {
                    updateTimestampEnd(timerSeconds);
                }
            }
            onNotificationTimer(callback);
            return () => offNotificationTimer(callback);
        }
    }, [notificationPersistKey, isHiding]);

    function updateTimestampEnd(timerSeconds) {
        setTimestampEnd(Date.now() + timerSeconds * 1000);
    }

    useEffect(() => {
        if (timestampEnd && !isHiding) {
            function updateSeconds() {
                const timeNow = Date.now();
                const timeDiffMs = timestampEnd - timeNow;
                const secondsLeft = Math.ceil(timeDiffMs / 1000);
                setSeconds(Math.max(secondsLeft, 0));
            }
            updateSeconds();
            const timerId = setInterval(updateSeconds, 1000);
            return () => clearInterval(timerId);
        }
    }, [timestampEnd, isHiding]);

    useEffect(() => {
        if (seconds !== null && seconds <= SECONDS_TO_PLAY_TICK) {
            playSfx(sfxClockTick);
        }
    }, [seconds]);

    let displayMessage = message;
    if (seconds !== null) {
        displayMessage += `\n${translate("noticeTime", { seconds })}`;
    }
    if (coins) {
        displayMessage += `\n${translate("noticeCoins", { coins })}`;
    }
    return displayMessage;
});

export default NotificationMessage;
