import { Colorize } from "@material-ui/icons";
import { fabric } from "fabric";
import { range } from "lodash";
import React, { memo, useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import styled from "styled-components";
import { SPOTLIGHT_TRANSITION_DURATION } from "../../constants/durations";
import { PENCIL_ERASE_COLOR } from "../../constants/modifiers";
import {
    DRAW_PEN_CIRCLE,
    DRAW_PEN_EDIT,
    DRAW_PEN_ERASE,
    DRAW_PEN_EYEDROP,
    DRAW_PEN_FILL,
    DRAW_PEN_PENCIL,
    DRAW_PEN_SQUARE,
    DRAW_PEN_TRIANGLE,
} from "../../constants/storage";
import { isBigRoom } from "../../services/actors";
import { playSfx, sfxDraw, sfxJump, sfxUndo } from "../../services/audio";
import { floodFill } from "../../services/drawings/floodFill";
import { sendDoneDrawing, sendDrawEdit, sendDrawPath, sendStartDrawing } from "../../services/room";
import { getYourId, isYourId } from "../../services/socket";
import { useWindowSize } from "../../services/useWindowSize";
import { eyedropColor } from "../actions/FloatingActions";
import "./DisplayCanvas.css";

const CANVAS_DEFAULT_WIDTH = 1366;
const CANVAS_DEFAULT_HEIGHT = 768;
const CANVAS_MAX_WIDTH = CANVAS_DEFAULT_WIDTH * 2;
const CANVAS_MAX_HEIGHT = CANVAS_DEFAULT_HEIGHT * 2;

const DRAW_PEN_TO_SHAPE = {
    [DRAW_PEN_SQUARE]: fabric.Rect,
    [DRAW_PEN_CIRCLE]: fabric.Ellipse,
    [DRAW_PEN_TRIANGLE]: fabric.Triangle,
};

const DRAW_EDIT_FIELDS_BOOL = ["flipX", "flipY"];

const DRAW_EDIT_FIELDS = ["angle", "skewX", "skewY", "left", "top", "scaleX", "scaleY", ...DRAW_EDIT_FIELDS_BOOL];

const EDGES_SIZE = 2;
const Container = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: ${EDGES_SIZE}px dashed ${({ showBorders }) => (showBorders ? "rgba(0, 0, 0, 0.3)" : "transparent")};
    transition: border 0.3s linear;
`;

const DimContainer = styled.div`
    transition: opacity ${SPOTLIGHT_TRANSITION_DURATION}ms;
`;

const disabledStyle = {
    pointerEvents: "none",
};

const DRAWING_LIMIT_TO_STYLES = {
    limitBlurred: {
        filter: "blur(18px)",
    },
    limitFlipped: {
        transform: "scaleX(-1)",
    },
    limitTiny: {
        transform: "scale(0.25)",
    },
    limitUpside: {
        transform: "rotate(180deg)",
    },
    limitMoving: {
        animation: "DrivingKeyframes 4s linear infinite",
    },
    limitResizing: {
        animation: "ResizingKeyframes 4s linear infinite",
    },
    limitFlashing: {
        animation: "Flashingframes 2s linear infinite",
    },
    limitBlind: {
        opacity: 0,
    },
};

/** @type {{[id: string]: fabric.Canvas}} */
const canvases = {};
let lastColor = "";
let lastWidth = "";

export function loadCanvasObjects(id, objectsJson) {
    loadObjects(canvases[id], objectsJson, { clear: true });
}
export function appendCanvasObjectsAndSend(objectsJson) {
    loadObjects(canvases[getYourId()], objectsJson);
    sendDrawPath(objectsJson);
}

function loadObjects(canvas, objectsJson, { clear = false } = {}) {
    canvas.renderOnAddRemove = false;
    if (clear) {
        canvas.clear();
    }
    fabric.util.enlivenObjects(objectsJson, (/** @type {fabric.Object[]} */ pathObjects) => {
        canvas.add(...pathObjects);
    });
    canvas.renderAll();
    canvas.renderOnAddRemove = true;
}

function getIndex(id, wantedTarget) {
    const objects = canvases[id].getObjects();
    return objects.findIndex((target) => target === wantedTarget);
}

export function undoPaths(id, amount) {
    const objects = [...canvases[id].getObjects()];
    canvases[id].renderOnAddRemove = false;
    for (let i = 0; i < amount; i++) {
        const object = objects.pop();
        if (object) {
            canvases[id].remove(object);
        }
    }
    canvases[id].renderAll();
    canvases[id].renderOnAddRemove = true;
    if (!isBigRoom() || isYourId(id)) {
        playSfx(sfxUndo);
    }
}
export function getCanvas(id) {
    return canvases[id];
}
export function clearDraw(ids) {
    for (const id of ids) {
        canvases[id].clear();
    }

    playSfx(sfxUndo);
}

function discardActiveGroup(canvas) {
    const activeObject = canvas.getActiveObject();
    const isGroupObject = activeObject && activeObject.getObjects;
    let groupObjects = null;
    if (isGroupObject) {
        groupObjects = activeObject.getObjects();
        canvas.discardActiveObject();
    }
    return groupObjects;
}

function maybeRestoreActiveGroup(canvas, groupObjects) {
    if (groupObjects) {
        const selection = new fabric.ActiveSelection(groupObjects, { canvas });
        canvas.setActiveObject(selection);
        canvas.renderAll();
    }
}

function getCanvasDimensions(windowWidth, windowHeight) {
    const limitedWidth = Math.min(windowWidth - EDGES_SIZE * 2, CANVAS_MAX_WIDTH);
    const limitedHeight = Math.min(windowHeight - EDGES_SIZE * 2, CANVAS_MAX_HEIGHT);
    const scaleRatio = Math.min(limitedWidth / CANVAS_DEFAULT_WIDTH, limitedHeight / CANVAS_DEFAULT_HEIGHT);
    return {
        width: CANVAS_DEFAULT_WIDTH * scaleRatio,
        height: CANVAS_DEFAULT_HEIGHT * scaleRatio,
        scaleRatio,
    };
}

const DisplayCanvas = memo(function DisplayCanvas({
    id,
    dim,
    drawSettings: { inDrawMode, color, pencilWidth, drawPen } = {},
    drawPaths,
    drawEditData,
    drawLimit,
    disableDrawing,
}) {
    /** @type {[!fabric.Canvas, Function]} */
    const [canvas, setCanvas] = useState(null);
    const [fillPromiseData, setFillPromiseData] = useState(null);
    const { width: windowWidth, height: windowHeight } = useWindowSize();
    const [displayCursors, setDisplayCursors] = useState({ defaultCursor: "crosshair" });

    // Global variables
    useEffect(() => {
        lastColor = color;
    }, [color]);
    useEffect(() => {
        lastWidth = pencilWidth;
    }, [pencilWidth]);

    // Init
    useEffect(() => {
        const canvas = new fabric.Canvas(`canvas-${id}`, {
            selection: false,
            preserveObjectStacking: true,
        });
        canvas.on("object:added", (event) => {
            event.target.selectable = false;
        });
        canvases[id] = canvas;
        setCanvas(canvas);
        return () => {
            delete canvases[id];
            canvas.dispose();
        };
    }, [id]);

    useEffect(() => {
        if (canvas) {
            if (drawLimit === "limitNoCursor") {
                canvas.defaultCursor = canvas.hoverCursor = canvas.moveCursor = canvas.freeDrawingCursor = "none";
            } else {
                canvas.defaultCursor = displayCursors.defaultCursor;
                canvas.hoverCursor = displayCursors.hoverCursor || displayCursors.defaultCursor;
                canvas.freeDrawingCursor = "crosshair";
                canvas.moveCursor = "move";
            }
        }
    }, [canvas, displayCursors, drawLimit]);

    // Events
    useEffect(() => {
        if (canvas) {
            function mouseDown() {
                sendStartDrawing();
            }
            function mouseUp() {
                sendDoneDrawing();
            }
            canvas.on("mouse:down", mouseDown);
            canvas.on("mouse:up", mouseUp);
            return () => {
                canvas.off("mouse:down", mouseDown);
                canvas.off("mouse:up", mouseUp);
            };
        }
    }, [canvas]);

    // Settings
    useEffect(() => {
        if (canvas && color) {
            const drawingColor = drawPen === DRAW_PEN_ERASE ? PENCIL_ERASE_COLOR : color;
            canvas.freeDrawingBrush.color = drawingColor;
        }
    }, [canvas, color, drawPen]);
    useEffect(() => {
        if (canvas && pencilWidth) {
            const drawingWidth = drawPen === DRAW_PEN_ERASE ? pencilWidth * 2 : pencilWidth;
            canvas.freeDrawingBrush.width = drawingWidth;
        }
    }, [canvas, pencilWidth, drawPen]);

    // Pencil pen
    useEffect(() => {
        if (canvas && inDrawMode && (drawPen === DRAW_PEN_PENCIL || drawPen === DRAW_PEN_ERASE)) {
            canvas.isDrawingMode = true;
            function callback(event) {
                if (drawPen === DRAW_PEN_ERASE) {
                    event.path.globalCompositeOperation = "destination-out";
                }
                event.path.objectCaching = false;
                sendDrawPath([event.path.toJSON()]);
                playSfx(sfxDraw);
            }
            canvas.on("path:created", callback);
            return () => {
                canvas.isDrawingMode = false;
                canvas.off("path:created", callback);
            };
        }
    }, [id, canvas, drawPen, inDrawMode]);
    useEffect(() => {
        if (canvas && drawPaths) {
            loadObjects(canvas, drawPaths);
            if (!isBigRoom() || isYourId(id)) {
                playSfx(sfxDraw);
            }
        }
    }, [id, canvas, drawPaths]);

    // Shapes pen
    useEffect(() => {
        if (canvas && inDrawMode && DRAW_PEN_TO_SHAPE[drawPen]) {
            setDisplayCursors({ defaultCursor: "crosshair" });

            let currentObject = null;
            let originalPointer = {};
            const widthKey = drawPen === DRAW_PEN_CIRCLE ? "rx" : "width";
            const heightKey = drawPen === DRAW_PEN_CIRCLE ? "ry" : "height";

            function mouseDown(event) {
                const pointer = canvas.getPointer(event.e);
                originalPointer = { ...pointer };
                const Shape = DRAW_PEN_TO_SHAPE[drawPen];
                currentObject = new Shape({
                    left: pointer.x,
                    top: pointer.y,
                    originX: "left",
                    originY: "top",
                    [widthKey]: 1,
                    [heightKey]: 1,
                    fill: null,
                    stroke: lastColor,
                    strokeWidth: lastWidth,
                    objectCaching: false,
                    strokeLineJoin: "round",
                });
                canvas.add(currentObject);
            }
            function mouseMove(event) {
                if (!currentObject) {
                    return;
                }
                const pointer = canvas.getPointer(event.e);

                let width = Math.abs(originalPointer.x - pointer.x);
                if (drawPen === DRAW_PEN_CIRCLE) {
                    width /= 2;
                }
                currentObject.set(widthKey, width);
                let height = Math.abs(originalPointer.y - pointer.y);
                if (drawPen === DRAW_PEN_CIRCLE) {
                    height /= 2;
                }
                currentObject.set(heightKey, height);
                if (originalPointer.x > pointer.x) {
                    currentObject.set("left", Math.abs(pointer.x));
                }
                if (originalPointer.y > pointer.y) {
                    currentObject.set("top", Math.abs(pointer.y));
                }

                if (drawPen === DRAW_PEN_TRIANGLE) {
                    currentObject.set("flipY", originalPointer.y > pointer.y ? true : false);
                }

                canvas.renderAll();
            }
            function mouseUp(event) {
                if (!currentObject) {
                    return;
                }
                currentObject.set("stroke", lastColor);
                currentObject.set("strokeWidth", lastWidth);
                canvas.renderAll();
                currentObject.setCoords();
                sendDrawPath([currentObject.toJSON()]);
                playSfx(sfxDraw);
                currentObject = null;
            }
            canvas.on("mouse:down", mouseDown);
            canvas.on("mouse:move", mouseMove);
            canvas.on("mouse:up", mouseUp);
            return () => {
                canvas.off("mouse:down", mouseDown);
                canvas.off("mouse:move", mouseMove);
                canvas.off("mouse:up", mouseUp);
            };
        }
    }, [id, canvas, drawPen, inDrawMode]);

    // Fill pen
    useEffect(() => {
        if (canvas && drawPen === DRAW_PEN_FILL && !fillPromiseData) {
            setDisplayCursors({ defaultCursor: "cell" });

            async function callback(event) {
                const { scaleRatio } = getCanvasDimensions(windowWidth, windowHeight);
                const scaledCanvas = scaleRatio * window.devicePixelRatio;
                const { x, y } = canvas.getPointer(event.e);
                const pointer = { x: x * scaledCanvas, y: y * scaledCanvas };
                setFillPromiseData({ promise: floodFill(canvas, color, pointer), scaledCanvas, color });
            }
            canvas.on("mouse:down", callback);
            return () => canvas.off("mouse:down", callback);
        }
    }, [canvas, drawPen, color, windowWidth, windowHeight, fillPromiseData]);

    useEffect(() => {
        if (fillPromiseData) {
            setDisplayCursors({ defaultCursor: "progress" });
            (async () => {
                const { data } = await fillPromiseData.promise;
                if (data && data.borderPoints && data.borderPoints.length) {
                    const object = new fabric.Polygon(data.borderPoints, {
                        left: data.x / fillPromiseData.scaledCanvas,
                        top: data.y / fillPromiseData.scaledCanvas,
                        scaleX: 1 / fillPromiseData.scaledCanvas,
                        scaleY: 1 / fillPromiseData.scaledCanvas,
                        fill: fillPromiseData.color,
                        stroke: fillPromiseData.color,
                        strokeWidth: 1,
                    });
                    canvas.add(object);
                    playSfx(sfxDraw);
                    sendDrawPath([object]);
                }
                setFillPromiseData(null);
            })();
        }
    }, [canvas, fillPromiseData]);

    // Move pen
    useEffect(() => {
        if (canvas) {
            if (inDrawMode && drawPen === DRAW_PEN_EDIT) {
                setDisplayCursors({ defaultCursor: "copy", hoverCursor: "move" });
                // Allow selecting multiple objects
                canvas.selection = true;

                // Make each existing object selectable
                for (const object of canvas.getObjects()) {
                    object.selectable = true;
                }

                // Make new objects selectable.
                const setObjectSelectable = (event) => {
                    event.target.selectable = true;
                };
                canvas.on("object:added", setObjectSelectable);

                return () => {
                    canvas.off("object:added", setObjectSelectable);
                    canvas.selection = false;
                    for (const object of canvas.getObjects()) {
                        object.selectable = false;
                    }
                    if (canvas.getContext()) {
                        canvas.discardActiveObject().renderAll();
                    }
                };
            } else {
                const setObjectUnselectable = (event) => {
                    event.target.selectable = false;
                };
                canvas.on("object:added", setObjectUnselectable);
                return () => {
                    canvas.off("object:added", setObjectUnselectable);
                };
            }
        }
    }, [id, canvas, drawPen, inDrawMode]);

    useEffect(() => {
        if (canvas) {
            function callback(event) {
                const changesMap = {};
                const objects = event.target.getObjects ? event.target.getObjects() : [event.target];
                const groupObjects = discardActiveGroup(canvas);
                for (const object of objects) {
                    const changes = {};
                    for (const field of DRAW_EDIT_FIELDS) {
                        changes[field] = object[field];
                    }
                    const index = getIndex(id, object);
                    changesMap[index] = changes;
                }
                sendDrawEdit({ changes: changesMap });
                playSfx(sfxDraw);
                maybeRestoreActiveGroup(canvas, groupObjects);
            }
            canvas.on("object:modified", callback);
            return () => canvas.off("object:modified", callback);
        }
    }, [id, canvas]);
    useEffect(() => {
        if (canvas && drawEditData) {
            canvas.discardActiveObject();
            const objects = canvases[id].getObjects();
            for (let index in drawEditData.changes) {
                for (const field of DRAW_EDIT_FIELDS) {
                    objects[index].set(field, drawEditData.changes[index][field]);
                }
                objects[index].setCoords();
            }
            canvases[id].renderAll();

            if (!isBigRoom() || isYourId(id)) {
                playSfx(sfxDraw);
            }
        }
    }, [id, canvas, drawEditData]);

    // Eyedrop pen
    useEffect(() => {
        if (canvas && drawPen === DRAW_PEN_EYEDROP) {
            function mouseMove(event) {
                const rgba = getColor(event);
                setCursor(rgba);
            }
            function mouseDown(event) {
                let rgba = getColor(event);
                if (rgba === `rgba(0,0,0,0)`) {
                    rgba = `rgba(0,0,0,1)`;
                }
                playSfx(sfxJump);
                eyedropColor(rgba);
            }
            function getColor(event) {
                const { x, y } = canvas.getPointer(event.e);
                const { scaleRatio } = getCanvasDimensions(windowWidth, windowHeight);
                const scaledCanvas = scaleRatio * window.devicePixelRatio;
                const pointer = { x: Math.round(x * scaledCanvas), y: Math.round(y * scaledCanvas) };
                const imageData = canvas
                    .getContext()
                    .getImageData(0, 0, canvas.lowerCanvasEl.width, canvas.lowerCanvasEl.height);
                const getPointOffset = function (x, y) {
                    return 4 * (y * imageData.width + x);
                };
                const targetOffset = getPointOffset(pointer.x, pointer.y);
                const rgba = [...imageData.data.slice(targetOffset, targetOffset + 4)];
                // Opacity needs to be 0-1
                rgba[3] /= 255;
                return `rgba(${rgba})`;
            }

            function setCursor(hoveredColor) {
                if (hoveredColor === `rgba(0,0,0,0)`) {
                    hoveredColor = "";
                }
                const filter = `drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 1px black) drop-shadow(0px 0px 1px white)`;
                let svgString = renderToStaticMarkup(<Colorize style={{ filter }} />);
                svgString = svgString.replace(
                    "<svg ",
                    `<svg fill="${hoveredColor}" height="24" width="24" xmlns="http://www.w3.org/2000/svg" `
                );
                const cursor = `url('data:image/svg+xml;utf8, ${svgString}') 0 24, auto`;
                setDisplayCursors({ defaultCursor: cursor });
            }

            setCursor();
            canvas.on("mouse:down", mouseDown);
            canvas.on("mouse:move", mouseMove);
            return () => {
                canvas.off("mouse:down", mouseDown);
                canvas.off("mouse:move", mouseMove);
            };
        }
    }, [canvas, drawPen, windowWidth, windowHeight]);

    // Globals
    useEffect(() => {
        if (canvas) {
            const { width, height, scaleRatio } = getCanvasDimensions(windowWidth, windowHeight);
            canvas.setWidth(width);
            canvas.setHeight(height);
            canvas.setZoom(scaleRatio);
        }
    }, [canvas, windowWidth, windowHeight]);

    let style = {};
    if (!inDrawMode || !isYourId(id) || disableDrawing) {
        style = { ...style, ...disabledStyle };
    }
    let drawingLimitStyle = {};
    if (DRAWING_LIMIT_TO_STYLES[drawLimit]) {
        drawingLimitStyle = DRAWING_LIMIT_TO_STYLES[drawLimit];
    }
    let PrisonedEffect =
        drawLimit === "limitPrisoned" ? (
            <span>
                {range(5).map((index) => (
                    <div className="EffectPrisoned" key={index} />
                ))}
            </span>
        ) : null;

    return (
        <Container showBorders={inDrawMode && isYourId(id)} style={style}>
            <div style={drawingLimitStyle}>
                <DimContainer style={dim ? { opacity: 0 } : {}}>
                    <canvas id={`canvas-${id}`}></canvas>
                </DimContainer>
            </div>
            {PrisonedEffect}
        </Container>
    );
});

export default DisplayCanvas;
