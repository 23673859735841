export const STORAGE_NAME = "char-name";
export const STORAGE_SKIN_PART_PREFIX = "char-skin-";
export const STORAGE_HAS_SKIN = "timerId-";
export const STORAGE_COINS = "animationId";
export const STORAGE_POTATO2 = "potato2";
export const STORAGE_UNICORN = "unic0rn";
export const STORAGE_SAW_NOTIFICATION = "saw-notification-";
export const STORAGE_SETTING_STREAMER = "setting-streamer";
export const STORAGE_SETTING_PROFANITY = "setting-profanity";
export const STORAGE_SETTING_PRIVATE = "setting-private-drawing";
export const STORAGE_SETTING_LOCALE = "setting-locale";
export const STORAGE_SETTING_PUBLIC_ROOMS = "setting-public-rooms";
export const AUDIO_MUTED_STORAGE_KEY = "audio-muted";

export const DRAW_PEN_PENCIL = "pencil";
export const DRAW_PEN_ERASE = "erase";
export const DRAW_PEN_FILL = "fill";
export const DRAW_PEN_SQUARE = "square";
export const DRAW_PEN_CIRCLE = "circle";
export const DRAW_PEN_TRIANGLE = "triangle";
export const DRAW_PEN_EDIT = "edit";
export const DRAW_PEN_EYEDROP = "eyedrop";
