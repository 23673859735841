import React, { memo } from "react";
import styled from "styled-components";
import { getUnicorn } from "../../../services/storageHandlers";
import { useLocale } from "../../locale/LocaleProvider";
import SecretNavigation from "../SecretNavigation";
import SettingsAction from "./SettingsAction";

const Container = styled.div`
    position: fixed;
    top: 4px;
    ${({ isRtl }) => (isRtl ? "right" : "left")}: 4px;
    height: 0;
`;

const Item = styled.div`
    display: inline-block;
    margin-inline-end: 4px;
`;

const FloatingSettings = memo(function FloatingSettings() {
    const unicorn = getUnicorn();
    const { isRtl } = useLocale();

    return (
        <Container isRtl={isRtl}>
            <Item>
                <SettingsAction />
            </Item>
            {unicorn ? (
                <Item>
                    <SecretNavigation />
                </Item>
            ) : null}
        </Container>
    );
});

export default FloatingSettings;
