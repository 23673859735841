import React, { memo, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { setLeaveRoomSettingHandler } from "../components/actions/settings/SettingsAction";
import CharSelect from "../components/ui/CharSelect";
import { showNotification } from "../services/room";
import { addErrorHandler, removeErrorHandler } from "../services/socket";
import { STORAGE_SETTING_STREAMER } from "./../constants/storage";
import { useSetting } from "./../services/useSettings";
import Game from "./Game";

const Room = memo(function Room() {
    const [yourChar, setYourChar] = useState({});
    const [codeState, setCodeState] = useState("");
    const [initialRoomSettings, setInitialRoomSettings] = useState({});
    const [streamerMode] = useSetting(STORAGE_SETTING_STREAMER);

    const { code: codeFromUrl = "" } = useParams();
    const history = useHistory();

    useEffect(() => {
        if (codeFromUrl) {
            setCodeState(codeFromUrl);
        }
    }, [codeFromUrl]);

    useEffect(() => {
        if (streamerMode) {
            history.replace("/");
        }
    }, [history, streamerMode]);

    useEffect(() => {
        const cb = () => {
            showNotification({ messageKey: "noticeDisconnect", variant: "error" });
            setYourChar({});
        };
        addErrorHandler(cb);
        return () => removeErrorHandler(cb);
    }, []);

    const returnToLobby = useCallback(() => {
        setYourChar({});
    }, []);

    useEffect(() => {
        setLeaveRoomSettingHandler(yourChar.name ? returnToLobby : null);
        return () => setLeaveRoomSettingHandler(null);
    }, [returnToLobby, yourChar]);

    const appendRouteUrl = useCallback(
        (code) => {
            setCodeState(code);
            if (!streamerMode) {
                history.replace(`/room-${code}`);
            }
        },
        [history, streamerMode]
    );
    if (!yourChar.name) {
        return (
            <CharSelect
                onCharSelect={setYourChar}
                code={codeState}
                setCode={appendRouteUrl}
                setInitialRoomSettings={setInitialRoomSettings}
            />
        );
    }
    return (
        <Game
            roomCode={codeState}
            yourChar={yourChar}
            returnToLobby={returnToLobby}
            initialRoomSettings={initialRoomSettings}
        />
    );
});

export default Room;
