import { Tooltip, useTheme, withStyles, Zoom } from "@material-ui/core";
import { Brush } from "@material-ui/icons";
import React, { memo, useCallback, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { MESSAGE_BLOCK_DURATION, MESSAGE_DURATION } from "../../constants/durations";
import { CHAT_MAX_HEIGHT, CHAT_MAX_WIDTH } from "../../constants/sizes";
import { onDoneDrawing, onDoneTyping, onStartDrawing, onStartTyping } from "../../services/room";

const tooltipStyle = (theme) => ({
    boxShadow: theme.shadows[1],
    fontSize: 16,
    padding: theme.spacing(1),
    marginTop: 0,
});

const BlackTooltip = withStyles((theme) => ({
    tooltip: tooltipStyle(theme),
}))(Tooltip);
// const WhiteTooltip = withStyles((theme) => ({
//     tooltip: {
//         ...tooltipStyle(theme),
//         backgroundColor: theme.palette.common.white,
//         color: theme.palette.text.primary,
//     },
//     arrow: {
//         color: theme.palette.common.white,
//     },
// }))(Tooltip);

const ChatContent = styled.div`
    max-height: ${CHAT_MAX_HEIGHT}px;
    max-width: ${CHAT_MAX_WIDTH}px;
    overflow: hidden;
    white-space: pre-wrap;
    transition: all 0.3s;
`;

const TypingKeyframes = keyframes`
  10%, 62%, 0%, 100% {
    transform: translateY(0px);
  }
  45% {
    transform: translateY(-5px);
  }
`;

const TypingIndicator = styled.span`
    display: flex;
    justify-content: center;
`;
const TypingIcon = styled.span`
    margin-inline-start: 4px;
`;
const TypingDotWrapper = styled.span`
    padding: 0 4px;
`;
const TypingDot = styled.span`
    animation: ${TypingKeyframes} 1.2s infinite ease-in-out both;
    background-color: ${(props) => props.color};
    border-radius: 2px;
    display: inline-block;
    height: 4px;
    width: 4px;
    :nth-child(2) {
        animation-delay: 100ms;
    }
    :nth-child(3) {
        animation-delay: 200ms;
    }
    :not(:last-child) {
        margin-inline-end: 2px;
    }
`;

const ChatTooltip = memo(function ChatTooltip({ message, messageIndex, charId, containerRef }) {
    const [messageBlocks, setMessageBlocks] = useState(new Map());
    const [hasMessages, setHasMessages] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [isDrawing, setIsDrawing] = useState(false);
    const [isTypingAnimation, setIsTypingAnimation] = useState(false);
    const [isDrawingAnimation, setIsDrawingAnimation] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        onStartTyping(({ id }) => {
            if (id === charId) {
                setIsTyping(true);
                setIsTypingAnimation(true);
            }
        });
        onDoneTyping(({ id }) => {
            if (id === charId) {
                setIsTyping(false);
            }
        });
        onStartDrawing(({ id }) => {
            if (id === charId) {
                setIsDrawing(true);
                setIsDrawingAnimation(true);
            }
        });
        onDoneDrawing(({ id }) => {
            if (id === charId) {
                setIsDrawing(false);
            }
        });
    }, [charId]);

    useEffect(() => {
        if (!isDrawing && isTyping) {
            setIsDrawingAnimation(false);
        } else if (isDrawing && !isTyping) {
            setIsTypingAnimation(false);
        }
    }, [isDrawing, isTyping]);

    const hideAnimations = useCallback(() => {
        setIsTypingAnimation(false);
        setIsDrawingAnimation(false);
    }, []);

    useEffect(() => {
        if (messageIndex) {
            setHasMessages(true);
            setIsTyping(false);
            setIsTypingAnimation(false);
            setMessageBlocks((messageBlocks) =>
                // Add the message to the message blocks.
                new Map(messageBlocks).set(messageIndex, {
                    message,
                    messageIndex,
                    show: true,
                    timerId: setTimeout(
                        () =>
                            // Hide the message block.
                            setMessageBlocks((messageBlocks) => {
                                messageBlocks = new Map(messageBlocks);
                                const messageBlock = messageBlocks.get(messageIndex);
                                messageBlock.show = false;
                                if (messageBlocks.size === 1) {
                                    setHasMessages(false);
                                }
                                messageBlock.timerId = setTimeout(
                                    // Remove the message block altogether.
                                    () =>
                                        setMessageBlocks((messageBlocks) => {
                                            messageBlocks = new Map(messageBlocks);
                                            messageBlocks.delete(messageIndex);
                                            return messageBlocks;
                                        }),
                                    MESSAGE_BLOCK_DURATION
                                );
                                return messageBlocks;
                            }),
                        MESSAGE_DURATION
                    ),
                })
            );
        }
    }, [message, messageIndex]);

    const ColoredTooltip = BlackTooltip;
    const textColor = theme.palette.common.white;

    // const ColoredTooltip = WhiteTooltip;
    // const textColor = theme.palette.text.primary;

    return (
        <ColoredTooltip
            open={hasMessages || isTyping || isDrawing}
            title={
                <ChatContent>
                    {[...messageBlocks.values()].map(({ message, messageIndex, show }) => (
                        <Zoom in={show} key={messageIndex} timeout={MESSAGE_BLOCK_DURATION}>
                            <div>
                                <bdi>{message}</bdi>
                            </div>
                        </Zoom>
                    ))}
                    {isTypingAnimation || isDrawingAnimation ? (
                        <Zoom
                            in={isTyping || isDrawing}
                            key="typing"
                            timeout={MESSAGE_BLOCK_DURATION}
                            onExited={hideAnimations}>
                            <TypingIndicator>
                                <TypingDotWrapper>
                                    <TypingDot color={textColor} key="1" />
                                    <TypingDot color={textColor} key="2" />
                                    <TypingDot color={textColor} key="3" />
                                </TypingDotWrapper>
                                {isDrawingAnimation ? (
                                    <TypingIcon>
                                        <Brush />
                                    </TypingIcon>
                                ) : null}
                            </TypingIndicator>
                        </Zoom>
                    ) : null}
                </ChatContent>
            }
            arrow
            placement="top"
            TransitionComponent={Zoom}
            PopperProps={{
                container: containerRef.current,
                modifiers: {
                    preventOverflow: { boundariesElement: document.body },
                    flip: { behavior: [] },
                },
            }}>
            <span style={{ position: "absolute" }} />
        </ColoredTooltip>
    );
});

export default ChatTooltip;
