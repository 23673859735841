import { getSharedConstant } from "../constants/constants";

let actorsSize = 0;
export function getActorsSize() {
    return actorsSize;
}
export function setActorsSize(size) {
    actorsSize = size;
}

export function isBigRoom() {
    const bigRoomPeople = getSharedConstant("bigRoomPeople");
    return getActorsSize() >= bigRoomPeople;
}
