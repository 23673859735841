import { useCallback, useEffect } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { playSfx, sfxCoin } from "../audio";
import { getStorageCoins, setStorageCoins } from "../storageHandlers";

const initialState = {
    coins: getStorageCoins(),
};
const { useGlobalState } = createGlobalState(initialState);

export function useCoins() {
    const [coins, setCoins] = useGlobalState("coins");

    useEffect(() => setStorageCoins(coins), [coins]);

    const addCoins = useCallback(
        (amount) => {
            setCoins((coins) => coins + amount);
            playSfx(sfxCoin);
        },
        [setCoins],
    );
    const removeCoins = useCallback((amount) => setCoins((coins) => coins - amount), [setCoins]);

    return [coins, { addCoins, removeCoins }];
}
