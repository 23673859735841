import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Fab,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SnackbarContent,
    Switch,
    TextField,
    Tooltip,
} from "@material-ui/core";
import { EditLocationOutlined, ExpandMore } from "@material-ui/icons";
import { throttle } from "lodash";
import React, { memo, useCallback, useEffect, useState } from "react";

import { STORAGE_SETTING_STREAMER } from "../../../constants/storage";
import { playSfx, sfxClick } from "../../../services/audio";
import { sendRoomSetting } from "../../../services/room";
import { getYourId } from "../../../services/socket";
import { useSetting } from "../../../services/useSettings";
import { useLocale } from "../../locale/LocaleProvider";
import { setBackgroundSetting } from "../../ui/Background";
import DialogTitleWithAction from "../DialogTitleWithAction";
import ShareAction from "../ShareAction";
import ParticipantsSettings from "./ParticipantsSettings";
import SettingLocale from "./SettingLocales";

let openSpecificGameSettingsDialogHandler = null;
export function openSpecificGameSettingsDialog() {
    if (openSpecificGameSettingsDialogHandler) {
        openSpecificGameSettingsDialogHandler();
    }
}

const throttledSendRoomSettings = {};

const RoomSettingsAction = memo(function RoomSettingsAction({
    roomCode,
    returnToLobby,
    roomSettings,
    setRoomSettings,
    admins,
    actors,
    currentGameName,
}) {
    const [showSettings, setShowSettings] = useState(false);
    const [showRoomCode, setShowRoomCode] = useState(false);
    const [expandedCategory, setExpandedCategory] = React.useState("settingCategoryGeneral");
    const [streamerMode] = useSetting(STORAGE_SETTING_STREAMER);

    const toggleRoomCode = useCallback(() => {
        setShowRoomCode((showRoomCode) => !showRoomCode);
    }, []);

    useEffect(() => {
        if (roomSettings.background) {
            setBackgroundSetting(roomSettings.background.value);
        }
    }, [roomSettings.background]);
    useEffect(() => {
        //cleanup background
        return () => {
            setBackgroundSetting("");
        };
    }, []);

    const onToggleCategory = (category) => (event, isExpanded) => {
        setExpandedCategory(isExpanded ? category : false);
    };
    const { translate } = useLocale();

    const setSetting = useCallback(
        (key, value) => {
            if (!throttledSendRoomSettings.hasOwnProperty(key)) {
                throttledSendRoomSettings[key] = throttle(sendRoomSetting, 500);
            }
            throttledSendRoomSettings[key](key, value);
            setRoomSettings((roomSettings) => {
                const clonedSettings = { ...roomSettings };
                clonedSettings[key] = { ...clonedSettings[key] };
                clonedSettings[key].value = value;
                return clonedSettings;
            });
        },
        [setRoomSettings]
    );
    const openSettingsDialog = useCallback(() => {
        setShowSettings(true);
        playSfx(sfxClick);
    }, []);
    useEffect(() => {
        openSpecificGameSettingsDialogHandler = () => {
            setShowSettings(true);
            setExpandedCategory("settingCategorySpecificGames");
        };
        return () => {
            openSpecificGameSettingsDialogHandler = null;
        };
    }, []);
    const closeSettingsDialog = useCallback(() => {
        setShowSettings(false);
    }, []);

    const yourId = getYourId();
    const areYouAdmin = admins[yourId];

    const categoriesToSettings = {};
    for (let settingKey in roomSettings) {
        const categoryKey = roomSettings[settingKey].categoryKey;
        categoriesToSettings[categoryKey] = categoriesToSettings[categoryKey] || {};
        categoriesToSettings[categoryKey][settingKey] = roomSettings[settingKey];
    }

    return (
        <>
            <Tooltip title={translate("tooltipRoomSettings")} placement="bottom">
                <Fab color="primary" size="small" onClick={openSettingsDialog}>
                    <EditLocationOutlined />
                </Fab>
            </Tooltip>

            <Dialog open={showSettings} onClose={closeSettingsDialog} maxWidth="sm" fullWidth>
                <DialogTitleWithAction>
                    <div>
                        {translate("titleRoomSettings", {
                            code: streamerMode && !showRoomCode ? roomCode.replace(/./g, "*") : roomCode,
                        })}
                        {streamerMode ? (
                            <Button size="small" onClick={toggleRoomCode} color="primary" variant="text">
                                {showRoomCode ? translate("buttonHide") : translate("buttonShow")}
                            </Button>
                        ) : null}
                    </div>
                    <ShareAction roomCode={roomCode} />
                </DialogTitleWithAction>

                {!areYouAdmin && roomSettings["creatorOnly"]?.value ? (
                    <DialogContent style={{ flexShrink: 0, overflow: "visible" }}>
                        <SnackbarContent message={translate("warningNotAdmin", {}, { mode: "labelRoomSettingSafe" })} />
                    </DialogContent>
                ) : null}

                <DialogContent style={{ flexShrink: 0 }}>
                    {Object.keys(categoriesToSettings).map((categoryKey) => (
                        <Accordion
                            expanded={expandedCategory === categoryKey}
                            onChange={onToggleCategory(categoryKey)}
                            key={categoryKey}>
                            <AccordionSummary expandIcon={<ExpandMore />}>{translate(categoryKey)}</AccordionSummary>
                            <AccordionDetails
                                style={{
                                    flexDirection: "column",
                                    gap: 16,
                                    alignItems: "baseline",
                                }}>
                                {Object.keys(categoriesToSettings[categoryKey]).map((settingKey) => {
                                    const setting = roomSettings[settingKey];
                                    const settingType = typeof setting.value;
                                    const renderNormalInput =
                                        !setting.isPlayers && !setting.isLocale && !setting.options;
                                    const disableInput =
                                        !areYouAdmin && (roomSettings["creatorOnly"]?.value || setting.roomCreatorOnly);

                                    const settingTitle =
                                        typeof setting.displayText === "object"
                                            ? translate(
                                                  setting.displayText.key,
                                                  setting.displayText.params,
                                                  setting.displayText.translatedParams
                                              )
                                            : setting.displayText
                                            ? translate(setting.displayText)
                                            : "";
                                    return (
                                        <FormControl key={settingKey} disabled={disableInput}>
                                            {settingType === "boolean" && renderNormalInput && (
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={setting.value}
                                                            onChange={(event) =>
                                                                setSetting(settingKey, event.target.checked)
                                                            }
                                                            disabled={disableInput}
                                                        />
                                                    }
                                                    label={settingTitle}
                                                />
                                            )}
                                            {(settingType === "number" || settingType === "string") &&
                                                renderNormalInput && (
                                                    <TextField
                                                        label={settingTitle}
                                                        variant="outlined"
                                                        size="small"
                                                        inputProps={{
                                                            min: setting.min,
                                                            max: setting.max,
                                                            size: setting.size,
                                                            maxLength: setting.maxLength,
                                                        }}
                                                        style={{
                                                            marginTop: 8,
                                                            whiteSpace: "nowrap",
                                                        }}
                                                        multiline={setting.multiline}
                                                        type={settingType === "number" ? "number" : "text"}
                                                        disabled={disableInput}
                                                        value={setting.value}
                                                        onChange={(event) => {
                                                            if (settingType === "number") {
                                                                if (+event.target.value < setting.min) {
                                                                    return (event.target.value = setting.min);
                                                                }
                                                                if (+event.target.value > setting.max) {
                                                                    return (event.target.value = setting.max);
                                                                }
                                                            }
                                                            setSetting(
                                                                settingKey,
                                                                settingType === "number"
                                                                    ? +event.target.value
                                                                    : event.target.value
                                                            );
                                                        }}
                                                    />
                                                )}
                                            {setting.options && (
                                                <>
                                                    <InputLabel>{settingTitle}</InputLabel>
                                                    <Select
                                                        label={settingTitle}
                                                        onChange={(event) => setSetting(settingKey, event.target.value)}
                                                        value={setting.value}>
                                                        {setting.options.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                {translate(option)}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </>
                                            )}
                                            {setting.isLocale && (
                                                <SettingLocale
                                                    text={settingTitle}
                                                    selectLocale={(event) => setSetting(settingKey, event.target.value)}
                                                    locale={setting.value}
                                                    disabled={disableInput}
                                                />
                                            )}
                                            {setting.isPlayers && (
                                                <ParticipantsSettings
                                                    actors={actors}
                                                    admins={admins}
                                                    disabled={disableInput}
                                                    currentGameName={currentGameName}
                                                />
                                            )}
                                            {setting.helperText && (
                                                <FormHelperText>
                                                    {typeof setting.helperText === "string"
                                                        ? translate(setting.helperText)
                                                        : translate(setting.helperText.key, setting.helperText.params)}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </DialogContent>
                <DialogActions
                    style={{
                        justifyContent: "space-between",
                        flexDirection: "row-reverse",
                    }}>
                    <Button onClick={closeSettingsDialog} color="primary">
                        {translate("dialogButtonDone")}
                    </Button>
                    <Button onClick={returnToLobby} color="secondary" variant="text">
                        {translate("dialogButtonLeave")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default RoomSettingsAction;
