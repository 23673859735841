import { IconButton, Tooltip } from "@material-ui/core";
import { EmojiEmotionsOutlined, ForumOutlined } from "@material-ui/icons";
import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { playSfx, sfxClick } from "../../services/audio";
import { sendDoneTyping, sendMessage, sendStartTyping } from "../../services/room";
import LazyEmotesSelector from "../emotes/LazyEmotesSelector";
import { useLocale } from "../locale/LocaleProvider";
import ChatHistory from "./ChatHistory";
import TextInput from "./TextInput";

const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    min-width: 300px;
    padding: 8px;
`;

const ChatInput = memo(function ChatInput({ actors }) {
    const [showEmotes, setShowEmotes] = useState(false);
    const [showHistory, setShowHistory] = useState(false);

    const { translate } = useLocale();

    const toggleEmotes = useCallback(() => {
        setShowEmotes((showEmotes) => !showEmotes);
        playSfx(sfxClick);
    }, []);
    const toggleShowHistory = useCallback(() => {
        setShowHistory((show) => !show);
        playSfx(sfxClick);
    }, []);

    return (
        <Container>
            <TextInput
                placeholder={translate("chatPlaceholder")}
                sendDoneTyping={sendDoneTyping}
                sendMessage={sendMessage}
                sendStartTyping={sendStartTyping}
                endAdornment={
                    <>
                        <Tooltip title={translate("tooltipEmotes")}>
                            <IconButton onClick={toggleEmotes} edge="start">
                                <EmojiEmotionsOutlined />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={translate("tooltipChatHistory")}>
                            <IconButton onClick={toggleShowHistory} edge="start">
                                <ForumOutlined />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            />
            <ChatHistory showHistory={showHistory} toggleShowHistory={toggleShowHistory} actors={actors} />
            <LazyEmotesSelector showEmotes={showEmotes} toggleEmotes={toggleEmotes} />
        </Container>
    );
});

export default ChatInput;
