export const KEYBIND_UNDO = { key: "z", which: 90 };
export const KEYBIND_COLOR = { key: "c", which: 67 };
export const KEYBIND_PENCIL_UP = { key: "=", which: 187 };
export const KEYBIND_PENCIL_DOWN = { key: "-", which: 189 };
export const KEYBIND_TOOLS = { key: "v", which: 86 };

export const KEYBIND_PENCIL = { key: "p", which: 80 };
export const KEYBIND_ERASE = { key: "e", which: 69 };
export const KEYBIND_RECTANGLE = { key: "r", which: 82 };
export const KEYBIND_TRIANGLE = { key: "t", which: 84 };
export const KEYBIND_CIRCLE = { key: "o", which: 79 };
export const KEYBIND_FILL = { key: "f", which: 70 };
export const KEYBIND_MOVE = { key: "m", which: 77 };
export const KEYBIND_EYEDROP = { key: "i", which: 73 };
