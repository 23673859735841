import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import React, { memo, useMemo } from "react";
import { useLocale } from "../locale/LocaleProvider";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";

const ltrTheme = createMuiTheme({
    direction: "ltr",
});
const rtlTheme = createMuiTheme({
    direction: "rtl",
});

const ThemeProvider = memo(function ThemeProvider({ children }) {
    const { isRtl } = useLocale();

    return (
        <MuiThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
            <RtlSupport>{children}</RtlSupport>
        </MuiThemeProvider>
    );
});

function RtlSupport({ children }) {
    const jss = useMemo(() => create({ plugins: [...jssPreset().plugins, rtl()] }), []);

    return <StylesProvider jss={jss}>{children}</StylesProvider>;
}

export default ThemeProvider;
