import { Button } from "@material-ui/core";
import React, { memo, useCallback } from "react";
import { useLocale } from "../locale/LocaleProvider";

const ErrorMessage = memo(function ErrorMessage({
    titleKey = "errorTitleGeneral",
    descriptionKey = "errorDescRefresh",
}) {
    const { textsReady, translate } = useLocale();

    const reloadPage = useCallback(() => {
        window.location.reload();
    }, []);

    // Hardcoded english on purpose, because we failed to get the localization texts.
    return (
        <h1 style={{ padding: 16, textAlign: "center" }}>
            {textsReady ? translate(titleKey) : "An error occurred."}
            <br />
            {textsReady ? translate(descriptionKey) : "Please refresh and try again."}
            <br />
            <Button variant="contained" color="primary" onClick={reloadPage}>
                {textsReady ? translate("errorButtonRefresh") : "Refresh"}
            </Button>
        </h1>
    );
});

export default ErrorMessage;
