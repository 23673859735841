import { size } from 'lodash';
import React, { Fragment, memo, useEffect, useState } from 'react';
import styled from 'styled-components';

import FloatingActions from '../components/actions/FloatingActions';
import ChatInput from '../components/chat/ChatInput';
import DisplayCanvas, { clearDraw, undoPaths } from '../components/draw/DisplayCanvas';
import EmotesInput from '../components/emotes/EmotesInput';
import AppSpinner from '../components/loading/AppSpinner';
import ErrorMessage from '../components/loading/ErrorMessage';
import FirstTimeMessage from '../components/notifications/FirstTimeMessage';
import ActorInput from '../components/player/ActorInput';
import MoveableActor, { setActorX } from '../components/player/MoveableActor';
import GamesToy from '../components/toys/GamesToy';
import { SPOTLIGHT_TRANSITION_DURATION } from '../constants/durations';
import { DIM_OPACITY } from '../constants/modifiers';
import { getActorsSize, isBigRoom, setActorsSize } from '../services/actors';
import { playSfx, sfxChatMessage, sfxJump } from '../services/audio';
import {
    joinRoom,
    leaveRoom,
    onActorUpdate,
    onDoneDrawing,
    onDrawClear,
    onDrawEdit,
    onDrawLimit,
    onDrawPath,
    onDrawUndo,
    onEmote,
    onGameFinish,
    onGameStart,
    onJoinRoom,
    onJumpActor,
    onLeaveRoom,
    onManualError,
    onMessage,
    onMoveActors,
    onRoomError,
    onRoomState,
    onSpotlight,
    onStartDrawing,
    onStartTyping,
    onToggleDisableDrawing,
    showNotification,
} from '../services/room';
import { getSocket, getYourId, isYourId } from '../services/socket';
import { useConnection } from '../services/useConnection';

const Container = styled.div({
    textAlign: "center",
});

const DimContainer = styled.div`
    transition: opacity ${SPOTLIGHT_TRANSITION_DURATION}ms;
`;

const Game = memo(function Game({ roomCode, yourChar, returnToLobby, initialRoomSettings }) {
    const [actors, setActors] = useState({});
    const [orderedActors, setOrderedActors] = useState(new Set());
    const [drawSettings, setDrawSettings] = useState({});
    const [admins, setAdmins] = useState({});
    const [drawLimit, setDrawLimit] = useState("");
    const [disableDrawing, setDisableDrawing] = useState(false);
    const [availableGames, setAvailableGames] = useState([]);
    const [boxImage, setBoxImage] = useState("");
    const [roomSettings, setRoomSettings] = useState({});
    const [currentGameName, setCurrentGameName] = useState("");
    const [errorTitleKey, setErrorTitleKey] = useState("");
    const [spotlights, setSpotlights] = useState(new Set());
    // const [serverName, setServerName] = useState("");
    const [connected] = useConnection();

    useEffect(() => {
        // if (!serverName) {
        //     getRoomServer(roomCode)
        //         .then(({ server, error }) => {
        //             if (error) {
        //                 return Promise.reject(error);
        //             }
        //             setServerName(server || apiDomain);
        //         })
        //         .catch((error) => {
        //             console.error("Error while fetching server name", error);
        //             setServerName(apiDomain);
        //         });
        // } else
        if (!connected) {
            getSocket({ roomCode });
        } else {
            onManualError(({ messageKey, params, translatedParams }) => {
                showNotification({ messageKey, params, translatedParams, variant: "error" });
                returnToLobby();
            });

            joinRoom(roomCode, yourChar, initialRoomSettings);
            setActorsSize(0);

            return () => {
                leaveRoom();
                setActorsSize(0);
            };
        }
    }, [roomCode, yourChar, returnToLobby, connected, initialRoomSettings]);

    useEffect(() => {
        if (!connected) {
            return;
        }
        onRoomState((roomState) => updateRoomState(roomState));
        onActorUpdate(({ id, updates }) => actorUpdate(id, updates));
        onRoomError(({ titleKey }) => setErrorTitleKey(titleKey));
        onJoinRoom(({ id, actor }) => actorJoinRoom(id, actor));
        onLeaveRoom(({ id, actor }) => actorLeaveRoom(id, actor));
        onMoveActors(({ movers }) => actorsMove(movers));
        onJumpActor(({ id }) => actorJump(id));
        onMessage(({ id, message }) => addMessage(id, message));
        onEmote(({ id, emote }) => actorEmote(id, emote));
        onDrawPath(({ id, paths }) => addDrawPath(id, paths));
        onDrawEdit(({ id, changes }) => setDrawEdit(id, { changes }));
        onDrawClear(({ ids }) => clearDraw(ids));
        onDrawUndo(({ id, amount }) => undoPaths(id, amount));
        onDrawLimit(({ limit }) => setDrawLimit(limit));
        onToggleDisableDrawing(({ disableDrawing }) => setDisableDrawing(disableDrawing));
        onGameStart(({ gameName }) => startGame(gameName));
        onGameFinish(() => finishGame());
        onSpotlight(({ ids }) => setSpotlights(new Set(ids)));
        onStartTyping(({ id }) => updateOrderedActors(id));
        onStartDrawing(({ id }) => updateOrderedActors(id));
        onDoneDrawing(({ id }) => updateOrderedActors(id));

        function updateRoomState({ actors, availableGames, boxImage, game, roomSettings, admins }) {
            if (actors) {
                setActors(actors);
                setActorsSize(size(actors));
                showNotification({ messageKey: "noticeConnect" });
            }
            if (game) {
                startGame(game.gameName);
            }
            if (availableGames) {
                setAvailableGames(availableGames);
            }
            if (boxImage) {
                setBoxImage(boxImage);
            }
            if (roomSettings) {
                setRoomSettings(roomSettings);
            }
            if (admins) {
                setAdmins(admins);
            }
        }

        function actorJoinRoom(id, actor) {
            setActorsSize(getActorsSize() + 1);
            if (!isBigRoom()) {
                showNotification({ messageKey: "noticeLogin", params: { name: actor.name } });
            }
            setActors((actors) => ({ ...actors, [id]: actor }));
        }

        function actorLeaveRoom(id, actor) {
            setActorsSize(getActorsSize() - 1);
            if (!isBigRoom()) {
                showNotification({ messageKey: "noticeLogout", params: { name: actor.name } });
            }
            setOrderedActors((orderedActors) => {
                const updatedOrderedActors = new Set(orderedActors);
                updatedOrderedActors.delete(id);
                return updatedOrderedActors;
            });
            setActors((actors) => {
                const updatedActors = { ...actors };
                delete updatedActors[id];
                return updatedActors;
            });
        }

        function actorUpdate(id, updates) {
            setActors((actors) => ({
                ...actors,
                [id]: { ...actors[id], ...updates },
            }));
        }

        function actorsMove(movers) {
            setActors((actors) => {
                const clonedActors = { ...actors };
                let changed = false;
                for (const { socketId, x, isMoving, turningRight, force } of movers) {
                    if (isYourId(socketId) && !force) {
                        // if force is true, this is from the client. otherwise ignore
                        continue;
                    }
                    if (!clonedActors[socketId]) {
                        // must have disconnected.
                        continue;
                    }
                    setActorX(socketId, x);
                    const turningRightOverride = turningRight === undefined ? {} : { turningRight };
                    clonedActors[socketId] = {
                        ...actors[socketId],
                        isMoving,
                        ...turningRightOverride,
                    };
                    if (
                        actors[socketId].isMoving !== isMoving ||
                        (turningRight !== undefined && actors[socketId].turningRight !== turningRight)
                    ) {
                        changed = true;
                    }
                }
                return changed ? clonedActors : actors;
            });
        }

        function actorJump(id) {
            setActors((actors) => ({
                ...actors,
                [id]: { ...actors[id], jumpIndex: (actors[id].jumpIndex || 0) + 1 },
            }));
            if (!isBigRoom() || isYourId(id)) {
                playSfx(sfxJump);
            }
        }

        function actorEmote(id, emote) {
            setActors((actors) => ({
                ...actors,
                [id]: { ...actors[id], emote },
            }));
        }
        function updateOrderedActors(id) {
            setOrderedActors((orderedActors) => {
                const updatedOrderedActors = new Set(orderedActors);
                // Deleting and adding the id moves it to be the last.
                updatedOrderedActors.delete(id);
                updatedOrderedActors.add(id);
                return updatedOrderedActors;
            });
        }

        function addMessage(id, message) {
            updateOrderedActors(id);
            setActors((actors) => ({
                ...actors,
                [id]: {
                    ...actors[id],
                    message,
                    messageIndex: (actors[id].messageIndex || 0) + 1,
                },
            }));
            if (!isBigRoom() || isYourId(id)) {
                playSfx(sfxChatMessage);
            }
        }
        function addDrawPath(id, paths) {
            setActors((actors) => ({ ...actors, [id]: { ...actors[id], drawPaths: paths } }));
        }
        function setDrawEdit(id, drawEditData) {
            setActors((actors) => ({
                ...actors,
                [id]: { ...actors[id], drawEditData },
            }));
        }
        function startGame(gameName) {
            setCurrentGameName(gameName);
        }
        function finishGame() {
            setCurrentGameName("");
        }
    }, [connected]);

    if (errorTitleKey) {
        return <ErrorMessage titleKey={errorTitleKey} />;
    }

    const actorsList = Object.keys(actors);
    if (!actorsList.length) {
        // just wait. maybe show a spinner.
        return <AppSpinner />;
    }
    const actorsByOrder = actorsList.filter((actorId) => !orderedActors.has(actorId)).concat([...orderedActors]);

    let isDrawingDisabled = disableDrawing;
    // Admins can freedraw basically.
    if (admins.hasOwnProperty(getYourId()) && !currentGameName) {
        isDrawingDisabled = false;
    }
    return (
        <Container>
            <ActorInput />
            <EmotesInput />
            {actorsByOrder.map((id) => (
                <DisplayCanvas
                    id={id}
                    key={id}
                    drawSettings={drawSettings}
                    drawPaths={actors[id].drawPaths}
                    drawEditData={actors[id].drawEditData}
                    drawLimit={drawLimit}
                    disableDrawing={isDrawingDisabled}
                    dim={spotlights.size && !spotlights.has(id)}
                />
            ))}
            <GamesToy currentGameName={currentGameName} availableGames={availableGames} boxImage={boxImage} />
            {actorsByOrder.map((id) => (
                <Fragment key={id}>
                    <DimContainer style={spotlights.size && !spotlights.has(id) ? { opacity: DIM_OPACITY } : {}}>
                        <MoveableActor {...actors[id]} dim={spotlights.size && !spotlights.has(id)} id={id} />
                    </DimContainer>
                </Fragment>
            ))}
            <FloatingActions
                roomCode={roomCode}
                drawLimit={drawLimit}
                setDrawSettings={setDrawSettings}
                returnToLobby={returnToLobby}
                roomSettings={roomSettings}
                setRoomSettings={setRoomSettings}
                admins={admins}
                actors={actors}
                currentGameName={currentGameName}
            />
            <ChatInput actors={actors} />
            <FirstTimeMessage />
        </Container>
    );
});

export default Game;
