import React, { lazy, memo, Suspense } from "react";

const Lazied = lazy(() => import("./EmotesSelector"));

const LazyEmotesSelector = memo(function LazyEmotesSelector(props) {
    return (
        <Suspense fallback={<span />}>
            <Lazied {...props} />
        </Suspense>
    );
});

export default LazyEmotesSelector;
