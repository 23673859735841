import { getUnicorn } from './storageHandlers';

export const isLocalHost =
    window.location.hostname === "localhost" || window.location.hostname.match(/\d+\.\d+\.0\.\d+/);

export const apiDomain = `${window.location.protocol}//${window.location.hostname}` + (isLocalHost ? `:5000` : ``);

/** @type {!AbortController} */
let currentNextCanvasAbortController = null;

export function getNextCanvas(status) {
    currentNextCanvasAbortController = new AbortController();
    const { signal } = currentNextCanvasAbortController;
    const unicorn = getUnicorn();
    return fetch(`${apiDomain}/api/canvas/get?status=${status}&unic0rn=${unicorn}`, {
        signal,
    }).then((response) => {
        currentNextCanvasAbortController = null;
        return response.json();
    });
}

export function setCanvasStatus(_id, status) {
    const unicorn = getUnicorn();
    return fetch(`${apiDomain}/api/canvas/set/status?status=${status}&_id=${_id}&unic0rn=${unicorn}`, {
        method: "POST",
    }).then((response) => {
        return response.json();
    });
}

export function abortNextCanvas() {
    if (currentNextCanvasAbortController) {
        currentNextCanvasAbortController.abort();
    }
}

export function sendFeedback(feedback) {
    return fetch(`${apiDomain}/api/feedback/create`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ feedback }),
    }).then((response) => {
        return response.json();
    });
}

export function getNextFeedback() {
    const unicorn = getUnicorn();
    return fetch(`${apiDomain}/api/feedback/get?unic0rn=${unicorn}`, {}).then((response) => {
        return response.json();
    });
}

export function getAllFeedbacks() {
    const unicorn = getUnicorn();
    return fetch(`${apiDomain}/api/feedback/getall?unic0rn=${unicorn}`, {}).then((response) => {
        return response.json();
    });
}

export function setFeedbackRead(_id) {
    const unicorn = getUnicorn();
    return fetch(`${apiDomain}/api/feedback/set/read?_id=${_id}&unic0rn=${unicorn}`, {
        method: "POST",
    }).then((response) => {
        return response.json();
    });
}

export function getTexts(locale) {
    return fetch(`${apiDomain}/api/texts/get?locale=${locale}`, {}).then((response) => {
        return response.json();
    });
}

export function loadRooms(selectedLocale, maxRooms) {
    const unicorn = getUnicorn();
    return maxRooms
        ? fetch(`${apiDomain}/api/rooms/getall?unic0rn=${unicorn}`, {}).then((response) => response.json())
        : fetch(`${apiDomain}/api/rooms/get?locale=${selectedLocale}`, {}).then((response) => response.json());
}

export function reportError(errorData) {
    return fetch(`${apiDomain}/api/errors/create`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ error: errorData }),
    }).then((response) => {
        return response.json();
    });
}

export function getRoomServer(roomCode) {
    return fetch(`${apiDomain}/api/servername/get?roomCode=${roomCode}`, {}).then((response) => {
        return response.json();
    });
}

export function getAllSocketServers() {
    const unicorn = getUnicorn();
    return fetch(`${apiDomain}/api/servername/getall?unic0rn=${unicorn}`, {}).then((response) => {
        return response.json();
    });
}

export function createSocketServer(server) {
    const unicorn = getUnicorn();
    return fetch(`${apiDomain}/api/servername/create?unic0rn=${unicorn}&server=${server}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
    }).then((response) => {
        return response.json();
    });
}

export function deleteSocketServer(server) {
    const unicorn = getUnicorn();
    return fetch(`${apiDomain}/api/servername/delete?unic0rn=${unicorn}&server=${server}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
    }).then((response) => {
        return response.json();
    });
}
