import { Fab, Fade, FormControl, IconButton, MenuItem, Select, Tooltip } from "@material-ui/core";
import {
    ArrowDownward,
    ArrowUpward,
    DeleteOutlined,
    Save,
    SaveAlt,
    SaveOutlined,
    UnarchiveOutlined,
} from "@material-ui/icons";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { playSfx, sfxClick } from "../../services/audio";
import { useSavedDrawings } from "../../services/drawings/savedDrawings";
import { onSaveDrawing, showNotification } from "../../services/room";
import { useLocale } from "../locale/LocaleProvider";

const Image = styled.img`
    height: 90px;
    width: 160px;
`;

const SAVE_VALUE = "save";
const PREVIOUS_PAGE_VALUE = "prev";
const NEXT_PAGE_VALUE = "next";
const PAGE_DRAWINGS = 10;

const SavedDrawingsAction = memo(function SavedDrawingsAction() {
    const [showDrawings, setShowDrawings] = useState(false);
    const [page, setPage] = useState(0);
    const [savedDrawings, { saveCurrentDrawing, deleteDrawing, loadDrawing }] = useSavedDrawings();
    const saveRef = useRef(null);
    const { translate } = useLocale();

    useEffect(() => onSaveDrawing(saveCurrentDrawing), [saveCurrentDrawing]);
    useEffect(() => {
        if (showDrawings) {
            setPage(0);
        }
    }, [showDrawings]);

    const toggleDrawings = useCallback(() => {
        setShowDrawings((showDrawings) => !showDrawings);
        playSfx(sfxClick);
    }, []);

    const deleteByIndex = useCallback(
        (event, index) => {
            event.stopPropagation();
            deleteDrawing(index);
        },
        [deleteDrawing]
    );
    const downloadByIndex = useCallback((event) => {
        // The download itself is done with href and download attribute.
        event.stopPropagation();
    }, []);

    const selectDrawing = useCallback(
        (event) => {
            const value = event.target.value;
            if (value === SAVE_VALUE) {
                const result = saveCurrentDrawing();
                if (result === false) {
                    showNotification({
                        messageKey: "noticeSaveWarning",
                        variant: "warning",
                    });
                }
                setShowDrawings(true);
            } else if (value === PREVIOUS_PAGE_VALUE) {
                setPage((page) => page - 1);
                setShowDrawings(true);
            } else if (value === NEXT_PAGE_VALUE) {
                setPage((page) => page + 1);
                saveRef.current.scrollIntoView();
                setShowDrawings(true);
            } else {
                loadDrawing(value);
            }
        },
        [saveRef, saveCurrentDrawing, loadDrawing]
    );

    return (
        <>
            <Tooltip title={translate("tooltipSavedDrawings")} placement="left">
                <Fab color="primary" size="small" onClick={toggleDrawings}>
                    <Save />
                </Fab>
            </Tooltip>
            {savedDrawings ? (
                <Fade in={showDrawings}>
                    <FormControl>
                        <Select
                            open={showDrawings}
                            onClose={toggleDrawings}
                            onChange={selectDrawing}
                            value=""
                            style={{ position: "absolute", visibility: "hidden" }}>
                            <MenuItem key="save" value={SAVE_VALUE} ref={saveRef}>
                                <IconButton color="primary">
                                    <SaveOutlined />
                                </IconButton>
                                {translate("buttonSaveDrawing")}
                            </MenuItem>
                            {page > 0 ? (
                                <MenuItem key="prev" value={PREVIOUS_PAGE_VALUE}>
                                    <IconButton color="primary">
                                        <ArrowUpward />
                                    </IconButton>
                                    {translate("buttonMoreDrawings")}
                                </MenuItem>
                            ) : null}
                            {savedDrawings.map(({ drawingKey, dataUrl, drawingTitle }, index) =>
                                index >= page * PAGE_DRAWINGS && index < (page + 1) * PAGE_DRAWINGS ? (
                                    <MenuItem key={drawingKey} value={index}>
                                        <Tooltip title={drawingTitle}>
                                            <Image src={dataUrl} alt={drawingTitle} />
                                        </Tooltip>
                                        <Tooltip title={translate("tooltipDrawingLoad")}>
                                            <IconButton color="primary" style={{ marginInlineStart: "auto" }}>
                                                <UnarchiveOutlined />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={translate("tooltipDrawingDownload")}>
                                            <IconButton
                                                href={dataUrl}
                                                download={`Haha Doodle - ${drawingTitle}`}
                                                color="primary"
                                                onClick={downloadByIndex}>
                                                <SaveAlt />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={translate("tooltipDrawingDelete")}>
                                            <IconButton
                                                color="secondary"
                                                onClick={(event) => deleteByIndex(event, index)}>
                                                <DeleteOutlined />
                                            </IconButton>
                                        </Tooltip>
                                    </MenuItem>
                                ) : null
                            )}

                            {(page + 1) * PAGE_DRAWINGS < savedDrawings.length ? (
                                <MenuItem key="next" value={NEXT_PAGE_VALUE}>
                                    <IconButton color="primary">
                                        <ArrowDownward />
                                    </IconButton>
                                    {translate("buttonLessDrawings")}
                                </MenuItem>
                            ) : null}
                        </Select>
                    </FormControl>
                </Fade>
            ) : null}
        </>
    );
});

export default SavedDrawingsAction;
