function postMessage(event, message = "*") {
    window.top.postMessage(event, message);
}

export function desktopToggleFullScreen() {
    postMessage("toggleFullScreen");
}

export function desktopQuit() {
    postMessage("quitGame");
}
