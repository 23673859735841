import { IconButton, InputAdornment, TextField, Tooltip } from "@material-ui/core";
import { Send, SentimentSatisfied } from "@material-ui/icons";
import React, { memo, useCallback, useState } from "react";
import { sendFeedback } from "../../services/api";
import { showNotification } from "../../services/room";
import { useLocale } from "../locale/LocaleProvider";

const Feedback = memo(function Feedback() {
    const [value, setValue] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const { translate, isRtl } = useLocale();

    const setValueHandler = useCallback((event) => setValue(event.target.value), []);
    const submitFeedbackHandler = useCallback(() => {
        if (!submitted) {
            setSubmitted(true);
            sendFeedback(value);
            showNotification({ messageKey: "noticeFeedbackThanks", variant: "default" });
        }
    }, [value, submitted]);

    return (
        <TextField
            size="medium"
            variant="outlined"
            label={translate("labelFeedback")}
            helperText={submitted ? translate("noticeFeedbackThanks") : translate("feedbackPrompt")}
            placeholder={translate("feedbackPlaceholder")}
            multiline
            fullWidth
            disabled={submitted}
            value={value}
            onChange={setValueHandler}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {submitted ? (
                            <Tooltip title={translate("feedbackIcon")}>
                                <IconButton color="primary">
                                    <SentimentSatisfied />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={translate("submitButton")}>
                                <span>
                                    <IconButton color="primary" onClick={submitFeedbackHandler} disabled={!value}>
                                        <Send style={isRtl ? { transform: "rotate(180deg)" } : {}} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                    </InputAdornment>
                ),
            }}
        />
    );
});

export default Feedback;
