import { keyBy } from "lodash";

export const TPOSE_SRCS_INDEXES = [0];

export const IDLE_SRCS_INDEXES = [1, 2, 3];

export const MOVE_SRCS_INDEXES = [4, 5, 6, 7];

export const IDLE_JUMPING_SRCS_INDEXES = [8];

export const MOVE_JUMPING_SRCS_INDEXES = [9];

export const HEAD_TRANSFORMS = [
    "",
    "none",
    "rotate(3deg)",
    "translate(0px, -1px) rotate(5deg)",
    "translate(-3px, -2px) rotate(0deg)",
    "translate(-3px, -2px) rotate(2deg)",
    "translate(-3px, -2px) rotate(0deg)",
    "translate(-3px, -2px) rotate(0deg)",
    "",
    "translate(-4px, -3px) rotate(-13deg)",
];

export const ALL_POSITIONING_INDEXES = IDLE_SRCS_INDEXES.concat(
    MOVE_SRCS_INDEXES,
    IDLE_JUMPING_SRCS_INDEXES,
    MOVE_JUMPING_SRCS_INDEXES
);

export const SKINS = [
    "man_white",
    "female_white",
    "man_black",
    "female_black",
    "man_ginger",
    "female_blonde",
    "man_brown",
    "female_brown",
];

const HAIR_COLORS = [
    `brightness(0) saturate(100%)`, // #000000
    `brightness(0) saturate(100%) invert(21%) sepia(1%) saturate(6%) hue-rotate(27deg) brightness(99%) contrast(91%)`, // #3c3c3c
    `brightness(0) saturate(100%) invert(99%) sepia(92%) saturate(791%) hue-rotate(312deg) brightness(89%) contrast(87%)`, // #d7bf94
    `brightness(0) saturate(100%) invert(49%) sepia(13%) saturate(5003%) hue-rotate(334deg) brightness(75%) contrast(84%)`, // #b6523a
];

export const TOTAL_SKINS = 8;
export const TOTAL_EYEBROWS = 8;
export const TOTAL_HATS = 8;
export const TOTAL_MOUTHS = 8;
export const TOTAL_EYES = 8;
export const TOTAL_HAIRS = 8;
export const TOTAL_HAIR_COLORS = HAIR_COLORS.length;
export const TOTAL_EMOTES = 8;

const SKIN_PART_KEY_BODY = "skin";
const SKIN_PART_KEY_HAIR = "hair";
const SKIN_PART_KEY_HAIR_COLOR = "hairColor";
const SKIN_PART_KEY_HAT = "hat";
const SKIN_PART_KEY_EYEBROWS = "eyebrows";
const SKIN_PART_KEY_EYES = "eyes";
const SKIN_PART_KEY_MOUTH = "mouth";
export const SKIN_PART_KEY_EMOTE = "emote";

const SKIN_PART_BODY = {
    key: SKIN_PART_KEY_BODY,
    titleKey: "bodyPartBody",
    total: TOTAL_SKINS,
    layers: true,
};
const SKIN_PART_HAIR = {
    key: SKIN_PART_KEY_HAIR,
    titleKey: "bodyPartHair",
    total: TOTAL_HAIRS,
    color: {
        partKey: SKIN_PART_KEY_HAIR_COLOR,
        list: HAIR_COLORS,
    },
    hideIf: {
        key: SKIN_PART_KEY_HAT,
        indexSet: new Set([5, 6]),
    },
};
const SKIN_PART_HAIR_COLOR = {
    key: SKIN_PART_KEY_HAIR_COLOR,
    titleKey: "bodyPartHairColor",
    total: TOTAL_HAIR_COLORS,
};
const SKIN_PART_HAT = {
    key: SKIN_PART_KEY_HAT,
    titleKey: "bodyPartHat",
    total: TOTAL_HATS,
    style: { marginTop: -10 },
    optional: true,
};
const SKIN_PART_EYEBROWS = {
    key: SKIN_PART_KEY_EYEBROWS,
    titleKey: "bodyPartEyebrows",
    total: TOTAL_EYEBROWS,
    color: {
        partKey: SKIN_PART_KEY_HAIR_COLOR,
        list: HAIR_COLORS,
    },
    hideIf: { key: SKIN_PART_KEY_EMOTE },
};
const SKIN_PART_EYES = {
    key: SKIN_PART_KEY_EYES,
    titleKey: "bodyPartEyes",
    total: TOTAL_EYES,
    blinking: true,
};
const SKIN_PART_MOUTH = {
    key: SKIN_PART_KEY_MOUTH,
    titleKey: "bodyPartMouth",
    total: TOTAL_MOUTHS,
    hideIf: { key: SKIN_PART_KEY_EMOTE },
};
const SKIN_PART_EMOTE = {
    key: SKIN_PART_KEY_EMOTE,
    titleKey: "bodyPartEmotes",
    total: TOTAL_EMOTES,
    usage: true,
};

export const SKIN_PARTS_STORE_ORDER = [
    SKIN_PART_BODY,
    SKIN_PART_HAIR,
    SKIN_PART_HAIR_COLOR,
    SKIN_PART_HAT,
    SKIN_PART_EYEBROWS,
    SKIN_PART_EYES,
    SKIN_PART_MOUTH,
    SKIN_PART_EMOTE,
];
export const SKIN_PARTS_WEARABLE = SKIN_PARTS_STORE_ORDER.filter((part) => !part.usage);
export const SKIN_PARTS_CHAR_ORDER = [
    SKIN_PART_BODY,
    SKIN_PART_EYES,
    SKIN_PART_EYEBROWS,
    SKIN_PART_MOUTH,
    SKIN_PART_HAIR,
    SKIN_PART_HAT,
    SKIN_PART_EMOTE,
];
export const SKIN_PARTS_BY_KEY = keyBy(SKIN_PARTS_STORE_ORDER, "key");

export const REWARDS_BY_PART = {
    [SKIN_PART_KEY_BODY]: {
        4: { cost: 20 },
        5: { cost: 20 },
    },
    [SKIN_PART_KEY_HAIR]: {
        5: { cost: 10 },
        7: { cost: 10 },
    },
    [SKIN_PART_KEY_HAT]: {
        0: { cost: 20 },
        1: { cost: 30 },
        2: { cost: 20 },
        3: { cost: 30 },
        4: { cost: 20 },
        5: { cost: 30 },
        6: { cost: 30 },
        7: { cost: 30 },
    },
    [SKIN_PART_KEY_MOUTH]: {
        1: { cost: 4 },
        2: { cost: 8 },
        3: { cost: 8 },
        4: { cost: 8 },
        5: { cost: 8 },
        6: { cost: 8 },
        7: { cost: 8 },
    },
    [SKIN_PART_KEY_EYES]: {
        1: { cost: 12 },
        2: { cost: 6 },
        3: { cost: 12 },
        5: { cost: 12 },
        6: { cost: 6 },
    },
    [SKIN_PART_KEY_EYEBROWS]: {
        2: { cost: 8 },
        3: { cost: 8 },
        4: { cost: 8 },
        6: { cost: 8 },
    },
    [SKIN_PART_KEY_EMOTE]: {
        3: { cost: 5 },
        4: { cost: 5 },
        5: { cost: 5 },
        6: { cost: 5 },
        7: { cost: 5 },
    },
};
