import { SnackbarProvider } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import FloatingSettings from "./components/actions/settings/FloatingSettings";
import LocaleProvider from "./components/locale/LocaleProvider";
import Notifications from "./components/notifications/Notifications";
import ThemeProvider from "./components/theme/ThemeProvider";
import Background from "./components/ui/Background";
import {
    NOTIFICATION_DURATION,
    NOTIFICATION_ENTER_DURATION,
    NOTIFICATION_EXIT_DURATION,
    NOTIFICATION_RESUME_DURATION,
} from "./constants/durations";
import FeedbackExhibit from "./containers/FeedbackExhibit";
import ReviewExhibits from "./containers/ReviewExhibits";
import Room from "./containers/Room";
import ServerConfig from "./containers/ServerConfig";
import { BGM_BIRDS, BGM_SONG, playBgm } from "./services/audio";
import { inDesktop } from "./services/desktop/inDesktop";

const TouchZone = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
`;

const snackbarAnchorOrigin = { horizontal: "center", vertical: "top" };
const snackbarTransitionDuration = {
    enter: NOTIFICATION_ENTER_DURATION,
    exit: NOTIFICATION_EXIT_DURATION,
};

function App() {
    const [snackbarContainer, setSnackbarContainer] = useState(null);

    const snackbarContainerRef = useCallback((node) => {
        setSnackbarContainer(node);
    }, []);

    useEffect(() => playBgm(BGM_BIRDS), []);
    useEffect(() => (inDesktop ? playBgm(BGM_SONG) : undefined), []);

    return (
        <>
            <Background />
            <LocaleProvider>
                <ThemeProvider>
                    <SnackbarProvider
                        maxSnack={4}
                        autoHideDuration={NOTIFICATION_DURATION}
                        resumeHideDuration={NOTIFICATION_RESUME_DURATION}
                        disableWindowBlurListener={true}
                        anchorOrigin={snackbarAnchorOrigin}
                        transitionDuration={snackbarTransitionDuration}
                        domRoot={snackbarContainer}>
                        <TouchZone id="touch-zone" />
                        <BrowserRouter>
                            <Switch>
                                <Route path="/review-drawings" component={ReviewExhibits} />
                                <Route path="/review-feedback" component={FeedbackExhibit} />
                                <Route path="/config" component={ServerConfig} />
                                <Route path="/room-:code" component={Room} />
                                <Route path="/" component={Room} />
                            </Switch>
                            <Notifications />
                            <FloatingSettings />
                        </BrowserRouter>
                    </SnackbarProvider>
                    <div id="snackbarContainer" ref={snackbarContainerRef} />
                </ThemeProvider>
            </LocaleProvider>
        </>
    );
}

export default App;
