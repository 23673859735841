export const CHAR_HEIGHT = 128;
export const CHAR_WIDTH = 128;
export const CHAR_BOTTOM_DISTANCE = 86;

export const MAX_MOVE_X = 1000;

export const CHAT_MAX_HEIGHT = 280;
export const CHAT_MAX_WIDTH = 180;

export const SCREEN_SMALL = 800;

export const TOY_GAMES_OFFSET = 150;
export const TOY_GAMES_HEIGHT = 100;
