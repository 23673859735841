import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    Tooltip,
} from "@material-ui/core";
import { Favorite } from "@material-ui/icons";
import React, { memo, useCallback, useState } from "react";
import { ReactComponent as DiscordIcon } from "../../icons/discord.svg";
import { useLocale } from "../locale/LocaleProvider";
import Feedback from "./Feedback";

const ABOUT_TEXT_KEYS = ["about1", "about2", "about3"];

const AboutAction = memo(function AboutAction() {
    const [showAboutDialog, setShowAboutDialog] = useState(false);
    const { translate } = useLocale();

    const openAboutDialog = useCallback(() => {
        setShowAboutDialog(true);
    }, []);
    const closeAboutDialog = useCallback(() => {
        setShowAboutDialog(false);
    }, []);

    return (
        <>
            <div>
                <Tooltip title={"Discord"} placement="bottom">
                    <Fab
                        color="default"
                        size="small"
                        href="https://discord.gg/stJQCW8hj5"
                        target="_blank"
                        style={{ padding: 8, marginInlineEnd: 8 }}>
                        <DiscordIcon />
                    </Fab>
                </Tooltip>
                <Tooltip title={translate("tooltipAbout")} placement="bottom">
                    <Fab color="primary" size="small" onClick={openAboutDialog}>
                        <Favorite />
                    </Fab>
                </Tooltip>
            </div>
            <Dialog open={showAboutDialog} onClose={closeAboutDialog} maxWidth="xs" fullWidth>
                <DialogTitle>{translate("tooltipAbout")}</DialogTitle>
                <DialogContent style={{ flexShrink: 0 }}>
                    {ABOUT_TEXT_KEYS.map((textKey, index) => (
                        <DialogContentText key={index}>{translate(textKey)}</DialogContentText>
                    ))}
                </DialogContent>
                <DialogContent style={{ flexShrink: 0 }}>
                    <Feedback />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAboutDialog} color="primary">
                        {translate("dialogButtonClose")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default AboutAction;
