import { throttle } from "lodash";
import { RESIZE_THROTTLE } from "../constants/durations";
import { SCREEN_SMALL } from "../constants/sizes";

/** @type {{width: number, height: number}} */
let windowSize = null;
const resizeCallbacks = new Set();

export function getWindowSize() {
    if (!windowSize) {
        recalculateWindowSize();
        window.addEventListener("resize", throttle(recalculateWindowSize, RESIZE_THROTTLE));
    }
    return windowSize;
}

export function isSmallScreen() {
    return getWindowSize().width < SCREEN_SMALL;
}

export function addResizeCallback(cb) {
    resizeCallbacks.add(cb);
}
export function removeResizeCallback(cb) {
    resizeCallbacks.delete(cb);
}

function recalculateWindowSize() {
    const newWindowSize = {
        width: window.innerWidth,
        height: window.innerHeight,
    };
    if (
        windowSize &&
        (windowSize.width !== newWindowSize.width || windowSize.height !== newWindowSize.height)
    ) {
        for (const callback of resizeCallbacks) {
            callback(newWindowSize);
        }
    }
    windowSize = newWindowSize;
}
