// Copied from https://stackoverflow.com/a/27747377

import { range, sample } from "lodash";
import { REWARDS_BY_PART } from "../constants/skins";
import { isSkinPartLocked } from "./profile/unlocks";

export function generateId(length) {
    const numbers = new Uint8Array(Math.ceil(length / 2));
    window.crypto.getRandomValues(numbers);
    return Array.from(numbers, (number) => number.toString(16).padStart(2, "0"))
        .join("")
        .substring(0, length);
}

export function getRandomSkin(unlocks, part, length, { optional = false } = {}) {
    const start = optional ? -1 : 0;
    const options = new Set(range(start, length - 1));
    if (REWARDS_BY_PART[part]) {
        for (let partIndex in REWARDS_BY_PART[part]) {
            if (isSkinPartLocked(unlocks, part, partIndex)) {
                options.delete(+partIndex);
            }
        }
    }
    return sample(Array.from(options));
}
