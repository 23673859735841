import "@simonwep/pickr/dist/themes/nano.min.css";

import { Fab, Fade, Slider, SvgIcon, Tooltip, Zoom } from "@material-ui/core";
import {
    ArrowUpward,
    Brush,
    ChangeHistory,
    CheckBoxOutlineBlank,
    Colorize,
    Delete,
    Edit,
    FormatPaint,
    Height,
    OpenWith,
    Palette,
    RadioButtonUnchecked,
    Undo,
} from "@material-ui/icons";
import Pickr from "@simonwep/pickr";
import { keyBy, random, range, throttle, zipObject } from "lodash";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { LIMIT_DRAW_RANDOM_INTERVAL } from "../../constants/durations";
import {
    KEYBIND_CIRCLE,
    KEYBIND_COLOR,
    KEYBIND_ERASE,
    KEYBIND_EYEDROP,
    KEYBIND_FILL,
    KEYBIND_MOVE,
    KEYBIND_PENCIL,
    KEYBIND_PENCIL_DOWN,
    KEYBIND_PENCIL_UP,
    KEYBIND_RECTANGLE,
    KEYBIND_TOOLS,
    KEYBIND_TRIANGLE,
    KEYBIND_UNDO,
} from "../../constants/keybinds";
import {
    INITIAL_PENCIL_COLOR,
    INITIAL_PENCIL_WIDTH,
    MAX_PENCIL_WIDTH,
    MIN_PENCIL_WIDTH,
} from "../../constants/modifiers";
import {
    DRAW_PEN_CIRCLE,
    DRAW_PEN_EDIT,
    DRAW_PEN_ERASE,
    DRAW_PEN_EYEDROP,
    DRAW_PEN_FILL,
    DRAW_PEN_PENCIL,
    DRAW_PEN_SQUARE,
    DRAW_PEN_TRIANGLE,
} from "../../constants/storage";
import { ReactComponent as EraserIconInner } from "../../icons/eraser.svg";
import { playSfx, sfxClick } from "../../services/audio";
import { isInputActive } from "../../services/input/active";
import { sendDrawClear, sendDrawUndo } from "../../services/room";
import { isSmallScreen } from "../../services/windowSize";
import { useLocale } from "../locale/LocaleProvider";
import ProfileAction from "./ProfileAction";
import SavedDrawingsAction from "./SavedDrawingsAction";
import RoomSettingsAction from "./settings/RoomSettingsAction";

const LIMITED_PENS = new Set([DRAW_PEN_PENCIL, DRAW_PEN_SQUARE, DRAW_PEN_CIRCLE, DRAW_PEN_TRIANGLE]);
const LIMIT_TO_PEN = {
    limitSquares: DRAW_PEN_SQUARE,
    limitTriangles: DRAW_PEN_TRIANGLE,
    limitCircles: DRAW_PEN_CIRCLE,
};
const EraserIcon = () => (
    <SvgIcon>
        <EraserIconInner />
    </SvgIcon>
);

const DRAW_PENS = [
    { textKey: "tooltipPencil", keyboardKey: KEYBIND_PENCIL, pen: DRAW_PEN_PENCIL, Icon: Edit },
    {
        textKey: "tooltipEraser",
        keyboardKey: KEYBIND_ERASE,
        pen: DRAW_PEN_ERASE,
        Icon: EraserIcon,
        rotate: 180,
    },
    {
        textKey: "tooltipRectangle",
        keyboardKey: KEYBIND_RECTANGLE,
        pen: DRAW_PEN_SQUARE,
        Icon: CheckBoxOutlineBlank,
    },
    {
        textKey: "tooltipTriangle",
        keyboardKey: KEYBIND_TRIANGLE,
        pen: DRAW_PEN_TRIANGLE,
        Icon: ChangeHistory,
    },
    {
        textKey: "tooltipCircle",
        keyboardKey: KEYBIND_CIRCLE,
        pen: DRAW_PEN_CIRCLE,
        Icon: RadioButtonUnchecked,
    },
    { textKey: "tooltipFill", keyboardKey: KEYBIND_FILL, pen: DRAW_PEN_FILL, Icon: FormatPaint },
    { textKey: "tooltipMove", keyboardKey: KEYBIND_MOVE, pen: DRAW_PEN_EDIT, Icon: OpenWith },
    { textKey: "tooltipEyedrop", keyboardKey: KEYBIND_EYEDROP, pen: DRAW_PEN_EYEDROP, Icon: Colorize, hidden: true },
];

const PEN_TO_INDEX = zipObject(
    DRAW_PENS.map((penObj) => penObj.pen),
    range(DRAW_PENS.length)
);
const PEN_BY_KEY = keyBy(DRAW_PENS, "keyboardKey.which");

const LIMITS_OF_RANDOMS = {
    limitColors: true,
    limitSizes: true,
};

const Container = styled.div`
    position: fixed;
    top: 12px;
    ${({ isRtl }) => (isRtl ? "left" : "right")}: 12px;
    height: 0;
`;

const Group = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-inline-start: 8px;
    height: 0;
`;

const Item = styled.div`
    margin-bottom: 8px;
    position: relative;
`;
const AdditionalItems = styled.div`
    position: absolute;
    ${({ isRtl }) => (isRtl ? "left" : "right")}: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-inline-end: 8px;
    display: flex;
    flex-direction: row-reverse;
`;
const AdditionalItem = styled.div`
    margin-inline-start: 4px;
`;

const IconValue = styled.div`
    line-height: 0.1;
    margin-inline-end: 4px;
`;

const PickerContainer = styled.div`
    position: absolute;
    visibility: hidden;
`;

const disabledItemStyles = {
    pointerEvents: "none",
};

const iconStyles = {
    position: "absolute",
};

const DEFAULT_SWATCHES = [
    "#2196F3", // blue 500
    "#F44336", // red 500
    "#795548", // brown 500
    "#673AB7", // deep purple 500
    "#4CAF50", // green 500
    "#FFEB3B", // yellow 500
    "#000000",
];

const MAX_SWATCHES = DEFAULT_SWATCHES.length * 2;

const activeSwatches = [...DEFAULT_SWATCHES];

let eyedropColorHandler = () => {};
export function eyedropColor(color) {
    eyedropColorHandler(color);
}

/** @type {Pickr} */
let pickerInstance = null;
function getPicker() {
    return pickerInstance;
}

let prevDrawPen = DRAW_PEN_PENCIL;

const FloatingActions = memo(function FloatingActions({
    roomCode,
    drawLimit,
    setDrawSettings,
    returnToLobby,
    roomSettings,
    setRoomSettings,
    admins,
    actors,
    currentGameName,
}) {
    const pickerContainer = useRef(null);
    const [, rerender] = useState(0);
    const [inDrawMode, setInDrawMode] = useState(true);
    const [pencilWidth, setPencilWidth] = useState(INITIAL_PENCIL_WIDTH);
    const [showWidthRange, setShowWidthRange] = useState(false);
    const [color, setColor] = useState(INITIAL_PENCIL_COLOR);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [hiddenPicker, setHiddenPicker] = useState(false);
    const [drawPen, setDrawPen] = useState(DRAW_PEN_PENCIL);
    const [showDrawPens, setShowDrawPens] = useState(false);

    const { isRtl, translate } = useLocale();

    const firstIconsSize = isSmallScreen() ? "medium" : "large";
    const secondIconsSize = isSmallScreen() ? "small" : "medium";
    const disableUndo = drawLimit === "limitNoUndo";

    function toggleDrawMode() {
        playSfx(sfxClick);
        setInDrawMode(!inDrawMode);
        closeAllAdditionalItems();
    }

    function toggleWidthRange() {
        playSfx(sfxClick);
        setShowWidthRange(!showWidthRange);
        setShowDrawPens(false);
    }

    function handleWidthRangeChange(event, newValue) {
        setPencilWidth(newValue);
    }

    const toggleDrawPens = useCallback(() => {
        playSfx(sfxClick);
        setShowDrawPens((showDrawPens) => !showDrawPens);
        setShowWidthRange(false);
    }, []);

    function selectDrawPens(pen) {
        setDrawPen(pen);
        playSfx(sfxClick);
    }

    function closeAllAdditionalItems() {
        setShowWidthRange(false);
        setShowDrawPens(false);
    }

    function clearDrawing() {
        sendDrawClear();
    }

    function undoDraw() {
        sendDrawUndo();
    }

    const toggleColorPicker = useCallback(() => {
        const picker = getPicker();
        if (picker.isOpen()) {
            picker.hide();
        } else if (!hiddenPicker) {
            playSfx(sfxClick);
            picker.show();
            setShowColorPicker(true);
            closeAllAdditionalItems();
        }
    }, [hiddenPicker]);

    const setEyedrop = useCallback(() => {
        playSfx(sfxClick);
        setShowColorPicker(false);
        setDrawPen(DRAW_PEN_EYEDROP);
    }, []);

    const saveColor = useCallback(() => {
        const picker = getPicker();
        const color = picker.getColor().toHEXA().toString();
        setColor(color);

        // Add the recently used colors.
        const existingSwatchIndex = activeSwatches.indexOf(color);
        const isDefaultSwatch = existingSwatchIndex >= 0 && existingSwatchIndex < DEFAULT_SWATCHES.length;
        const isExistingSwatch = existingSwatchIndex >= DEFAULT_SWATCHES.length;
        if (isDefaultSwatch) {
            // Do nothing, it's a default color to always stay.
        } else if (isExistingSwatch) {
            // Remove it and set it to the end index.
            activeSwatches.splice(existingSwatchIndex, 1);
            picker.removeSwatch(existingSwatchIndex);
            activeSwatches.push(color);
            picker.addSwatch(color);
        } else {
            // Add it to the end. If reached max, remove one after the defaults.
            activeSwatches.push(color);
            picker.addSwatch(color);
            if (activeSwatches.length > MAX_SWATCHES) {
                activeSwatches.splice(DEFAULT_SWATCHES.length, 1);
                picker.removeSwatch(DEFAULT_SWATCHES.length);
            }
        }
    }, []);

    useEffect(() => {
        if (drawPen === DRAW_PEN_EYEDROP) {
            const lastDrawPen = prevDrawPen;
            eyedropColorHandler = (color) => {
                getPicker().setColor(color);
                saveColor();
                setDrawPen(lastDrawPen);
            };
        }
    }, [drawPen, saveColor]);

    useEffect(() => {
        setDrawSettings({ inDrawMode, color, pencilWidth, drawPen });
    }, [setDrawSettings, inDrawMode, color, pencilWidth, drawPen]);

    useEffect(() => {
        if (inDrawMode) {
            function onKeyDown(event) {
                if (isInputActive()) {
                    return;
                }
                const ctrl = event.ctrlKey || event.metaKey;
                if (event.which === KEYBIND_UNDO.which && ctrl && !disableUndo) {
                    undoDraw();
                } else if (event.which === KEYBIND_COLOR.which) {
                    toggleColorPicker();
                } else if (event.which === KEYBIND_PENCIL_UP.which && !ctrl) {
                    updatePencil(-1);
                } else if (event.which === KEYBIND_PENCIL_DOWN.which && !ctrl) {
                    updatePencil(1);
                } else if (event.which === KEYBIND_TOOLS.which) {
                    toggleDrawPens();
                } else if (PEN_BY_KEY[event.which] && !ctrl && !PEN_BY_KEY[event.which].disabled) {
                    selectDrawPens(PEN_BY_KEY[event.which].pen);
                }
            }
            // function onMouseWheel(event) {
            //     if (!event.ctrlKey) {
            //         updatePencil(event.deltaY);
            //     }
            // }

            function updatePencil(delta) {
                setPencilWidth((pencilWidth) => {
                    if (delta < 0) {
                        return Math.min(pencilWidth + 1, MAX_PENCIL_WIDTH);
                    } else {
                        return Math.max(pencilWidth - 1, MIN_PENCIL_WIDTH);
                    }
                });
            }

            const throttledOnKeyDown = throttle(onKeyDown, 200, { leading: true, trailing: true });

            window.addEventListener("keydown", throttledOnKeyDown);
            // window.addEventListener("wheel", onMouseWheel);
            return () => {
                window.removeEventListener("keydown", throttledOnKeyDown);
                // window.removeEventListener("wheel", onMouseWheel);
            };
        }
    }, [inDrawMode, toggleColorPicker, toggleDrawPens, disableUndo]);

    useEffect(() => {
        if (pickerContainer) {
            const picker = Pickr.create({
                theme: "nano",
                el: pickerContainer.current,
                position: "left",
                padding: 64,
                default: INITIAL_PENCIL_COLOR,
                components: {
                    // Defines if the palette itself should be visible.
                    // Will be overwritten with true if preview, opacity or hue are true
                    palette: true,
                    preview: true, // Display comparison between previous state and new color
                    opacity: true, // Display opacity slider
                    hue: true, // Display hue slider
                },
                swatches: DEFAULT_SWATCHES,
            });
            picker.on("changestop", saveColor);
            picker.on("swatchselect", saveColor);
            picker.on("hide", () => {
                setHiddenPicker(true);
            });
            pickerInstance = picker;
            return () => {
                picker.destroyAndRemove();
                pickerInstance = null;
            };
        }
    }, [pickerContainer, saveColor]);

    useEffect(() => {
        if (hiddenPicker) {
            setShowColorPicker(false);
            const timerId = setTimeout(() => setHiddenPicker(false), 200);
            return () => clearTimeout(timerId);
        }
    }, [hiddenPicker]);

    useEffect(() => {
        if (LIMITS_OF_RANDOMS[drawLimit]) {
            let resetFunction = () => {};
            const intervalId = setInterval(() => {
                if (drawLimit === "limitColors") {
                    setColor(
                        "#" +
                            random(256 * 256 * 256 - 1)
                                .toString(16)
                                .padStart(6, 0)
                    );
                    resetFunction = () => setColor(INITIAL_PENCIL_COLOR);
                } else if (drawLimit === "limitSizes") {
                    setPencilWidth(random(MIN_PENCIL_WIDTH, MAX_PENCIL_WIDTH));
                    resetFunction = () => setPencilWidth(INITIAL_PENCIL_WIDTH);
                }
            }, LIMIT_DRAW_RANDOM_INTERVAL);
            return () => {
                clearInterval(intervalId);
                resetFunction();
            };
        }
        if (LIMIT_TO_PEN[drawLimit]) {
            for (const pen of DRAW_PENS) {
                if (LIMITED_PENS.has(pen.pen) && LIMIT_TO_PEN[drawLimit] !== pen.pen) {
                    pen.disabled = true;
                }
            }
            setDrawPen(LIMIT_TO_PEN[drawLimit]);
            setShowDrawPens(true);
            rerender((render) => render + 1);
            return () => {
                for (const pen of DRAW_PENS) {
                    delete pen.disabled;
                }
                setDrawPen(DRAW_PEN_PENCIL);
                rerender((render) => render + 1);
            };
        }
        if (drawLimit === "limitNoUndo") {
            const pens = [DRAW_PENS[PEN_TO_INDEX[DRAW_PEN_ERASE]], DRAW_PENS[PEN_TO_INDEX[DRAW_PEN_EDIT]]];
            pens.map((pen) => (pen.disabled = true));
            rerender((render) => render + 1);
            setDrawPen(DRAW_PEN_PENCIL);
            return () => {
                pens.map((pen) => (pen.disabled = false));
                rerender((render) => render + 1);
            };
        }
    }, [drawLimit]);

    useEffect(() => {
        prevDrawPen = drawPen;
    }, [drawPen]);

    let selectedPenObject = DRAW_PENS[PEN_TO_INDEX[drawPen]];

    return (
        <Container isRtl={isRtl}>
            <Group>
                <Item style={{ position: "static" }}>
                    <ProfileAction />
                </Item>
            </Group>
            <Group>
                <Item>
                    <RoomSettingsAction
                        roomCode={roomCode}
                        returnToLobby={returnToLobby}
                        roomSettings={roomSettings}
                        setRoomSettings={setRoomSettings}
                        admins={admins}
                        actors={actors}
                        currentGameName={currentGameName}
                    />
                </Item>
            </Group>
            <Group>
                <Item>
                    <Tooltip
                        title={inDrawMode ? translate("tooltipHideMenu") : translate("tooltipDraw")}
                        placement="bottom">
                        <Fab color="primary" onClick={toggleDrawMode} size={firstIconsSize}>
                            <Zoom in={!inDrawMode}>
                                <Brush style={iconStyles} />
                            </Zoom>
                            <Zoom in={inDrawMode}>
                                <ArrowUpward style={iconStyles} />
                            </Zoom>
                        </Fab>
                    </Tooltip>
                </Item>
                <Zoom in={inDrawMode} style={{ transitionDelay: inDrawMode ? ".0s" : "" }}>
                    <Item>
                        <Tooltip title={translate("tooltipTools") + " (v)"} placement="left">
                            <Fab color="primary" onClick={toggleDrawPens} size={secondIconsSize}>
                                <selectedPenObject.Icon
                                    style={
                                        selectedPenObject.rotate
                                            ? {
                                                  transform: `rotate(${selectedPenObject.rotate}deg)`,
                                              }
                                            : {}
                                    }
                                />
                            </Fab>
                        </Tooltip>
                        <AdditionalItems isRtl={isRtl} style={showDrawPens ? {} : disabledItemStyles}>
                            {DRAW_PENS.map(({ textKey, keyboardKey, pen, Icon, rotate, disabled, hidden }, index) =>
                                hidden ? null : (
                                    <AdditionalItem key={pen}>
                                        <Zoom
                                            in={showDrawPens}
                                            style={{
                                                transitionDelay: showDrawPens ? `${index * 0.04 - 0.04}s` : "",
                                            }}>
                                            <div>
                                                <Tooltip
                                                    title={`${translate(textKey)} (${keyboardKey.key})`}
                                                    placement="bottom">
                                                    <Fab
                                                        color={pen === drawPen ? "primary" : "default"}
                                                        onClick={() => selectDrawPens(pen)}
                                                        size="small"
                                                        disabled={disabled}>
                                                        <Icon
                                                            style={
                                                                rotate
                                                                    ? {
                                                                          transform: `rotate(${rotate}deg)`,
                                                                      }
                                                                    : {}
                                                            }
                                                        />
                                                    </Fab>
                                                </Tooltip>
                                            </div>
                                        </Zoom>
                                    </AdditionalItem>
                                )
                            )}
                        </AdditionalItems>
                    </Item>
                </Zoom>
                <Zoom in={inDrawMode} style={{ transitionDelay: inDrawMode ? ".05s" : "" }}>
                    <Item>
                        <Tooltip title={translate("tooltipColor") + " (c)"} placement="left">
                            <Fab color="primary" onClick={toggleColorPicker} size={secondIconsSize}>
                                <Palette htmlColor={color} />
                                <PickerContainer>
                                    <div ref={pickerContainer} />
                                </PickerContainer>
                            </Fab>
                        </Tooltip>
                        <AdditionalItems
                            isRtl={isRtl}
                            style={showColorPicker || hiddenPicker ? {} : disabledItemStyles}>
                            <Fade in={showColorPicker}>
                                <Tooltip title={translate("tooltipEyedrop") + " (i)"} placement="bottom">
                                    <Fab color="primary" onClick={setEyedrop} size="small">
                                        <Colorize />
                                    </Fab>
                                </Tooltip>
                            </Fade>
                        </AdditionalItems>
                    </Item>
                </Zoom>
                <Zoom in={inDrawMode} style={{ transitionDelay: inDrawMode ? ".1s" : "" }}>
                    <Item>
                        <Tooltip title={translate("tooltipSize") + " (+ / -)"} placement="left">
                            <Fab color="primary" onClick={toggleWidthRange} size={secondIconsSize}>
                                <Height />
                                <IconValue>{pencilWidth}</IconValue>
                            </Fab>
                        </Tooltip>
                        <AdditionalItems isRtl={isRtl} style={showWidthRange ? {} : disabledItemStyles}>
                            <Fade in={showWidthRange}>
                                <Slider
                                    style={{ width: "200px" }}
                                    onChange={handleWidthRangeChange}
                                    value={pencilWidth}
                                    valueLabelDisplay="on"
                                    step={1}
                                    marks
                                    min={MIN_PENCIL_WIDTH}
                                    max={MAX_PENCIL_WIDTH}
                                />
                            </Fade>
                        </AdditionalItems>
                    </Item>
                </Zoom>
                <Zoom in={inDrawMode} style={{ transitionDelay: inDrawMode ? ".15s" : "" }}>
                    <Item>
                        <Tooltip title={translate("tooltipUndo") + " (ctrl + z)"} placement="left">
                            <Fab color="secondary" onClick={undoDraw} size={secondIconsSize} disabled={disableUndo}>
                                <Undo />
                            </Fab>
                        </Tooltip>
                    </Item>
                </Zoom>
                <Zoom in={inDrawMode} style={{ transitionDelay: inDrawMode ? ".2s" : "" }}>
                    <Item>
                        <Tooltip title={translate("tooltipClear")} placement="left">
                            <Fab color="secondary" onClick={clearDrawing} size={secondIconsSize} disabled={disableUndo}>
                                <Delete />
                            </Fab>
                        </Tooltip>
                    </Item>
                </Zoom>
                <Zoom in={inDrawMode} style={{ transitionDelay: inDrawMode ? ".25s" : "" }}>
                    <Item>
                        <SavedDrawingsAction />
                    </Item>
                </Zoom>
            </Group>
        </Container>
    );
});

export default FloatingActions;
