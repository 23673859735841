import { Button, Card, CardActions, CardContent, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import AppSpinner from "../components/loading/AppSpinner";
import { createSocketServer, deleteSocketServer, getAllSocketServers } from "../services/api";

const Container = styled.div({
    textAlign: "center",
});

const ServerConfig = memo(function ServerConfig() {
    const [loading, setLoading] = useState(false);
    const [socketServers, setSocketServers] = useState([]);
    const [newServerNameValue, setNewServerNameValue] = useState("");
    const { enqueueSnackbar } = useSnackbar();

    const updateNewServerNameValue = useCallback((event) => {
        setNewServerNameValue(event.target.value);
    }, []);

    useEffect(() => {
        getAllSocketServers()
            .then(({ servers, error }) => {
                if (error) {
                    return Promise.reject(error);
                }
                setSocketServers(servers);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error while fetching servers", error);
                enqueueSnackbar(`Could not fetch servers: ${error}`, {
                    variant: "warning",
                    anchorOrigin: { horizontal: "right", vertical: "bottom" },
                });
            });
    }, [enqueueSnackbar]);

    const removeServer = useCallback(
        (serverName) => {
            setLoading(true);
            deleteSocketServer(serverName)
                .then(({ servers, error }) => {
                    if (error) {
                        return Promise.reject(error);
                    }
                    setSocketServers(servers);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error("Error while removing server", error);
                    enqueueSnackbar(`Could not remove server: ${error}`, {
                        variant: "warning",
                        anchorOrigin: { horizontal: "right", vertical: "bottom" },
                    });
                });
        },
        [enqueueSnackbar]
    );

    const createServer = useCallback(() => {
        setLoading(true);
        createSocketServer(newServerNameValue)
            .then(({ servers, error }) => {
                if (error) {
                    return Promise.reject(error);
                }
                setNewServerNameValue("");
                setSocketServers(servers);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error while creating server", error);
                enqueueSnackbar(`Could not create server: ${error}`, {
                    variant: "warning",
                    anchorOrigin: { horizontal: "right", vertical: "bottom" },
                });
            });
    }, [newServerNameValue, enqueueSnackbar]);

    return (
        <Container>
            <h1>Socket Servers: {socketServers.length}</h1>
            {loading ? (
                <AppSpinner />
            ) : (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: 12,
                    }}>
                    {socketServers.map((socketServer, index) => (
                        <Card key={index} style={{ width: 300 }}>
                            <CardContent>{socketServer}</CardContent>
                            <CardActions>
                                <Button color="secondary" onClick={() => removeServer(socketServer)}>
                                    Remove
                                </Button>
                            </CardActions>
                        </Card>
                    ))}
                    <Card style={{ width: 300 }}>
                        <CardContent>
                            <TextField
                                label="New Server Name"
                                variant="filled"
                                value={newServerNameValue}
                                onChange={updateNewServerNameValue}
                            />
                        </CardContent>
                        <CardActions>
                            <Button color="primary" onClick={createServer}>
                                Create
                            </Button>
                        </CardActions>
                    </Card>
                </div>
            )}
        </Container>
    );
});

export default ServerConfig;
