import localforage from "localforage";
import { filter } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { appendCanvasObjectsAndSend, getCanvas } from "../../components/draw/DisplayCanvas";
import { useLocale } from "../../components/locale/LocaleProvider";
import { getYourId } from "../socket";

const SAVED_DRAWINGS_INDEXES_KEY = "saved-drawings-indexes";
const SAVED_DRAWING_PREFIX_KEY = "saved-drawing-";
const STORAGE_KEY_VALIDATED_IMAGES = "validated-images";

const initialState = {
    savedDrawings: null,
    drawingIndexes: {},
};
const { useGlobalState } = createGlobalState(initialState);

async function setStorageIndexes(indexes) {
    try {
        await localforage.setItem(SAVED_DRAWINGS_INDEXES_KEY, indexes);
    } catch (error) {
        console.error("Had an error saving drawing indexes", error);
    }
}

export function useSavedDrawings() {
    const [savedDrawings, setSavedDrawings] = useGlobalState("savedDrawings");
    const [, setDrawingIndexes] = useGlobalState("drawingIndexes");
    const { translate } = useLocale();
    const [master, setMaster] = useState(false);

    useEffect(() => {
        if (!savedDrawings) {
            setSavedDrawings([]);
            setMaster(true);
        }
    }, [savedDrawings, setSavedDrawings]);

    useEffect(() => {
        if (master) {
            (async () => {
                try {
                    const drawingIndexes = (await localforage.getItem(SAVED_DRAWINGS_INDEXES_KEY)) || {};

                    // remove any old 'image' fills from saved images
                    const validatedImages = localStorage.getItem(STORAGE_KEY_VALIDATED_IMAGES);
                    if (!validatedImages) {
                        localStorage.setItem(STORAGE_KEY_VALIDATED_IMAGES, "1");
                        for (let drawingKey in drawingIndexes) {
                            const storageKey = SAVED_DRAWING_PREFIX_KEY + drawingKey;
                            const drawing = await localforage.getItem(storageKey);
                            if (drawing) {
                                const updatedObjects = filter(
                                    drawing.canvasJson.objects,
                                    ({ type }) => type !== "image"
                                );
                                if (updatedObjects.length !== drawing.canvasJson.objects.length) {
                                    drawing.canvasJson.objects = updatedObjects;
                                    await localforage.setItem(storageKey, drawing);
                                }
                            }
                        }
                    }

                    setDrawingIndexes((existingDrawingIndexes) => ({
                        ...existingDrawingIndexes,
                        ...drawingIndexes,
                    }));
                    for (let drawingKey in drawingIndexes) {
                        const storageKey = SAVED_DRAWING_PREFIX_KEY + drawingKey;
                        const drawing = await localforage.getItem(storageKey);
                        if (drawing) {
                            setSavedDrawings((savedDrawings) => [drawing, ...savedDrawings]);
                        }
                    }
                } catch (error) {
                    console.error("Had an error loading drawings.", error);
                }
            })();
        }
    }, [master, setSavedDrawings, setDrawingIndexes]);

    const saveCurrentDrawing = useCallback(
        ({ title = "", messageKey = "", params = {}, translatedParams = {} } = {}) => {
            const canvas = getCanvas(getYourId());
            const objects = canvas.getObjects();
            if (!objects.length) {
                return false;
            }
            const canvasJson = canvas.toJSON();
            const dataUrl = canvas.toDataURL();
            const nowDate = new Date();
            const drawingKey = +nowDate;

            let caption = title;
            if (!caption && messageKey) {
                caption = translate(messageKey, params, translatedParams);
            }

            const drawingTitle = (caption ? `${caption} - ` : "") + nowDate.toLocaleString();
            const drawing = { canvasJson, dataUrl, drawingKey, drawingTitle };
            setSavedDrawings((savedDrawings) => [drawing, ...savedDrawings]);
            setDrawingIndexes((drawingIndexes) => {
                const combinedIndexes = { ...drawingIndexes, [drawingKey]: true };
                setStorageIndexes(combinedIndexes);
                return combinedIndexes;
            });
            const storageKey = SAVED_DRAWING_PREFIX_KEY + drawingKey;
            (async () => {
                try {
                    await localforage.setItem(storageKey, drawing);
                } catch (error) {
                    console.error("Had an error saving drawing", error);
                }
            })();
        },
        [setSavedDrawings, setDrawingIndexes, translate]
    );

    const deleteDrawing = useCallback(
        (index) => {
            const drawing = savedDrawings[index];
            setDrawingIndexes((drawingIndexes) => {
                const combinedIndexes = { ...drawingIndexes };
                delete combinedIndexes[drawing.drawingKey];
                setStorageIndexes(combinedIndexes);
                return combinedIndexes;
            });
            setSavedDrawings((savedDrawings) => [...savedDrawings.slice(0, index), ...savedDrawings.slice(index + 1)]);

            const storagekey = SAVED_DRAWING_PREFIX_KEY + drawing.drawingKey;
            (async () => {
                try {
                    await localforage.removeItem(storagekey);
                } catch (error) {
                    console.error("Had an error removing drawing", error);
                }
            })();
        },
        [savedDrawings, setSavedDrawings, setDrawingIndexes]
    );
    const loadDrawing = useCallback(
        (index) => {
            const drawing = savedDrawings[index];
            appendCanvasObjectsAndSend(drawing.canvasJson.objects);
        },
        [savedDrawings]
    );

    return [savedDrawings, { saveCurrentDrawing, deleteDrawing, loadDrawing }];
}
