import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    SvgIcon,
    Tooltip,
    Zoom,
} from "@material-ui/core";
import { AccessibilityNew } from "@material-ui/icons";
import { range } from "lodash";
import React, { memo, useCallback, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { COIN_ADDITION_APPEARANCE_DURATION } from "../../constants/durations";
import { CHAR_BOTTOM_DISTANCE, CHAR_HEIGHT, CHAR_WIDTH } from "../../constants/sizes";
import { ReactComponent as CoinIcon } from "../../icons/token.svg";
import { ReactComponent as CoinsIcon } from "../../icons/two-coins.svg";
import { playSfx, sfxClick } from "../../services/audio";
import { useCoins } from "../../services/profile/coins";
import { useName } from "../../services/profile/useName";
import { useSkin } from "../../services/profile/useSkin";
import { updateActor } from "../../services/room";
import { getYourId } from "../../services/socket";
import { usePrevious } from "../../services/usePrevious";
import NameInput from "../customize/NameInput";
import SkinCustomizer from "../customize/SkinCustomizer";
import { useLocale } from "../locale/LocaleProvider";
import { actorXs } from "../player/MoveableActor";

const TitleBlock = styled.div`
    display: flex;
    align-items: center;
    white-space: pre;
`;

const CoinsKeyframes = keyframes`
5%, 80% {
    opacity: 1;
}
0%, 95%, 100% {
    opacity: 0;
}
95% {
    transform: translate(calc(-108px - 50%), 50%);
}
`;
const CoinsKeyframesRtl = keyframes`
5%, 80% {
    opacity: 1;
}
0%, 95%, 100% {
    opacity: 0;
}
95% {
    transform: translate(calc(108px + 50%), 50%);
}
`;

const CoinsAddition = styled.div`
    position: absolute;
    top: 0;
    ${({ isRtl }) => (isRtl ? "left" : "right")}: 0;
    animation: ${({ isRtl }) => (isRtl ? CoinsKeyframesRtl : CoinsKeyframes)} ${COIN_ADDITION_APPEARANCE_DURATION}ms
        both;
`;

const SettingsAction = memo(function SettingsAction() {
    const [showProfileDialog, setShowProfileDialog] = useState(false);
    const [name] = useName();
    const [indexes] = useSkin();
    const [coins] = useCoins();
    const lastCoinsValue = usePrevious(coins);
    const [addedCoins, setAddedCoins] = useState(0);
    const [showAddedCoins, setShowAddedCoins] = useState(false);
    const { isRtl, translate } = useLocale();

    useEffect(() => {
        if (coins > lastCoinsValue) {
            setAddedCoins(coins - lastCoinsValue);
            setShowAddedCoins(true);
        }
    }, [lastCoinsValue, coins]);

    useEffect(() => {
        if (showAddedCoins) {
            const timerId = setTimeout(
                () => setShowAddedCoins(false),
                [COIN_ADDITION_APPEARANCE_DURATION + 100 * addedCoins]
            );
            return () => clearTimeout(timerId);
        }
    }, [addedCoins, showAddedCoins]);

    const openProfileDialog = useCallback(() => {
        setShowProfileDialog(true);
        playSfx(sfxClick);
    }, []);
    const closeProfileDialog = useCallback(() => {
        setShowProfileDialog(false);
        const updates = { ...indexes };
        if (name) {
            updates.name = name;
        }
        updateActor(updates);
    }, [name, indexes]);

    return (
        <>
            <Tooltip title={translate("tooltipProfile")} placement="bottom">
                <Fab color="primary" size="small" onClick={openProfileDialog}>
                    <Zoom in={!showAddedCoins} appear={false}>
                        <AccessibilityNew />
                    </Zoom>
                    <Zoom in={showAddedCoins}>
                        <span style={{ position: "absolute" }}>+{addedCoins}</span>
                    </Zoom>
                </Fab>
            </Tooltip>
            {showAddedCoins ? (
                <>
                    {range(addedCoins).map((index) => (
                        <CoinsAddition
                            key={index}
                            isRtl={isRtl}
                            style={{
                                animationDelay: `${100 * index}ms`,
                                transform: `translate(calc(
                                    ${
                                        isRtl
                                            ? `50vw - 12px - 50% - ${CHAR_WIDTH / 2}px - ${-actorXs[getYourId()]}px`
                                            : `-50vw + 12px + 50% + ${CHAR_WIDTH / 2}px - ${-actorXs[getYourId()]}px`
                                    }
                                ), calc(100vh - 20px - ${CHAR_HEIGHT}px - ${CHAR_BOTTOM_DISTANCE}px))`,
                            }}>
                            <SvgIcon>
                                <CoinIcon />
                            </SvgIcon>
                        </CoinsAddition>
                    ))}
                </>
            ) : null}
            <Dialog open={showProfileDialog} onClose={closeProfileDialog} maxWidth="lg">
                <DialogTitle>
                    <TitleBlock>
                        {translate("profileOf")} <NameInput />
                        <Tooltip title={translate("tooltipCoins")}>
                            <Fab color="default" size="small" variant="extended" style={{ marginInlineStart: "auto" }}>
                                <SvgIcon style={{ marginInlineEnd: "4px" }}>
                                    <CoinsIcon />
                                </SvgIcon>
                                {coins}
                            </Fab>
                        </Tooltip>
                    </TitleBlock>
                </DialogTitle>
                <DialogContent style={{ flexShrink: 0 }}>
                    <SkinCustomizer />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeProfileDialog} color="primary">
                        {translate("dialogButtonDone")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default SettingsAction;
