import { isEmpty } from "lodash";
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { createContext } from "react";
import { STORAGE_SETTING_LOCALE } from "../../constants/storage";
import { getTexts } from "../../services/api";
import { updateLocale } from "../../services/room";
import { ensureStorageLocale } from "../../services/storageHandlers";
import { useConnection } from "../../services/useConnection";
import { useSetting } from "../../services/useSettings";
import AppSpinner from "../loading/AppSpinner";
import ErrorMessage from "../loading/ErrorMessage";

ensureStorageLocale();

const LocaleContext = createContext(undefined);

const LocaleProvider = memo(function LocaleProvider({ children }) {
    const [locale] = useSetting(STORAGE_SETTING_LOCALE, { defaultValue: "", persist: true });
    const [texts, setTexts] = useState({});
    const [isRtl, setIsRtl] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [localeTexts, setLocaleTexts] = useState("");
    const [connected] = useConnection();

    useEffect(() => {
        if (locale) {
            getTexts(locale)
                .then(({ error, texts, isRtl }) => {
                    if (error) {
                        setFetchError(true);
                        return console.error("Failed to get locale", error);
                    }
                    setLocaleTexts(locale);
                    setTexts(texts);
                    setIsRtl(isRtl);
                    document.body.dir = isRtl ? "rtl" : "ltr";
                })
                .catch((error) => {
                    setFetchError(true);
                    console.error("Failed to get locale", error);
                });
        }

        return () => {
            // no cleanup, it's not worth aborting
        };
    }, [locale]);

    useEffect(() => {
        if (localeTexts && connected) {
            updateLocale(localeTexts);
        }
    }, [localeTexts, connected]);

    const translate = useCallback(
        (textKey, params = {}, translatedParams = {}) => {
            if (!textKey) {
                return "";
            }
            let textValue = texts[textKey];
            if (!textValue) {
                console.error("Couldn't find text", textKey);
                return textKey;
            }

            textValue = textValue.replace(/{([a-z0-9]+)}/gi, (originalValue, match) => {
                if (translatedParams.hasOwnProperty(match)) {
                    return translate(translatedParams[match], params, translatedParams);
                }
                if (params.hasOwnProperty(match)) {
                    return params[match];
                }
                console.error("Couldn't find param", originalValue);
                return originalValue;
            });

            return textValue;
        },
        [texts]
    );

    const textsReady = useMemo(() => !isEmpty(texts), [texts]);

    return (
        <LocaleContext.Provider value={{ textsReady, translate, locale, isRtl }}>
            {textsReady ? children : fetchError ? <ErrorMessage /> : <AppSpinner />}
        </LocaleContext.Provider>
    );
});

export function useLocale() {
    return useContext(LocaleContext);
}

export default LocaleProvider;
