import { Howl, Howler } from "howler";
import { VOLUME_DEFAULT_VALUE, VOLUME_BGM_SONG } from "../constants/modifiers";
import { getStorageMuted, setStorageMuted } from "./storageHandlers";

const DEFAULTS = {
    preload: true,
    volume: VOLUME_DEFAULT_VALUE,
};
const SFX_DEFAULTS = {
    ...DEFAULTS,
};
const BGM_DEFAULTS = {
    ...DEFAULTS,
    loop: true,
    volume: VOLUME_BGM_SONG,
};

let muted = false;

// ================================
//               SFX
// ================================
export const sfxChatMessage = new Howl({
    ...SFX_DEFAULTS,
    src: ["sfx/chat message.mp3"],
});
export const sfxClickHigh = new Howl({
    ...SFX_DEFAULTS,
    src: ["sfx/click high.mp3"],
});
export const sfxClickLow = new Howl({
    ...SFX_DEFAULTS,
    src: ["sfx/click low.mp3"],
});
export const sfxClick = new Howl({
    ...SFX_DEFAULTS,
    src: ["sfx/click.mp3"],
});
export const sfxDraw = new Howl({
    ...SFX_DEFAULTS,
    src: ["sfx/draw.mp3"],
});
export const sfxError = new Howl({
    ...SFX_DEFAULTS,
    src: ["sfx/error.mp3"],
});
export const sfxJump = new Howl({
    ...SFX_DEFAULTS,
    src: ["sfx/jump.mp3"],
});
export const sfxNotificationInfo = new Howl({
    ...SFX_DEFAULTS,
    src: ["sfx/notification info.mp3"],
});
export const sfxNotificationSuccess = new Howl({
    ...SFX_DEFAULTS,
    src: ["sfx/notification success.mp3"],
});
export const sfxClockTick = new Howl({
    ...SFX_DEFAULTS,
    src: ["sfx/tick.mp3"],
});
export const sfxUndo = new Howl({
    ...SFX_DEFAULTS,
    src: ["sfx/undo.mp3"],
});
export const sfxExp = new Howl({
    ...SFX_DEFAULTS,
    src: ["sfx/exp.mp3"],
});
export const sfxCoin = new Howl({
    ...SFX_DEFAULTS,
    src: ["sfx/coin.mp3"],
});

// ================================
//               BGM
// ================================
export const BGM_BIRDS = new Howl({
    ...BGM_DEFAULTS,
    src: ["bgm/soundscape_park.mp3"],
});
export const BGM_SONG = new Howl({
    ...BGM_DEFAULTS,
    src: ["bgm/song.mp3"],
});

export function playSfx(sound) {
    playAudio(sound);
}
function playAudio(sound) {
    sound.play();
}

export function playBgm(sound) {
    playAudio(sound);
}

function setInitMuted() {
    setAudioMute(getStorageMuted());
}

export function setAudioMute(mute) {
    muted = mute;
    Howler.mute(mute);
    setStorageMuted(mute);
}

export function getMuted() {
    return muted;
}

setInitMuted();
