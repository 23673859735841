import { Button, ButtonGroup } from "@material-ui/core";
import interact from "interactjs";
import { useSnackbar } from "notistack";
import React, { memo, useEffect } from "react";
import { playSfx, sfxError, sfxNotificationInfo, sfxNotificationSuccess } from "../../services/audio";
import { useCoins } from "../../services/profile/coins";
import {
    offHideNotification,
    offShowNotification,
    onHideNotification,
    onShowNotification,
    sendGameStart,
    sendNotificationResponse,
} from "../../services/room";
import { getStorageVisibility, setStorageVisibility } from "../../services/storageHandlers";
import { useConnection } from "../../services/useConnection";
import { isSmallScreen } from "../../services/windowSize";
import { showGameModes } from "../toys/GamesToy";
import TextInput from "../chat/TextInput";
import { useLocale } from "../locale/LocaleProvider";
import NotificationMessage from "./NotificationMessage";

export const ACTION_DISMISS = "dismiss";
export const ACTION_PLAY_AGAIN = "play_again";
export const ACTION_PLAY_SOMETHING = "play_something";
export const ACTION_SHOW_NOTIFICATION = "show_notification";
export const ACTION_SET_SHOWN = "set_shown";
export const ACTION_RESPONSE = "response";
export const ACTION_CALLBACK = "callback";

const Notifications = memo(function Notifications() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [, { addCoins }] = useCoins();
    const [connected] = useConnection();
    const { translate } = useLocale();

    useEffect(() => {
        if (!connected) {
            return;
        }
        const persistKeysMap = {};
        const messageRemovedCallbacks = {};
        onShowNotification(
            ({
                message,
                messageKey,
                variant,
                persistKey,
                params = {},
                translatedParams = {},
                timerSeconds = 0,
                input = "",
                inputProgress = false,
                actions = [],
                responses = [],
                responsesRaw = [],
                coins = 0,
                showOnce = false,
            }) => {
                if (messageKey) {
                    message = translate(messageKey, params, translatedParams);
                } else {
                    console.error("Missing message key", message);
                }
                showNotification(
                    message,
                    variant,
                    persistKey,
                    timerSeconds,
                    input,
                    inputProgress,
                    actions,
                    responses,
                    responsesRaw,
                    coins,
                    showOnce
                );
            }
        );
        onHideNotification(({ persistKey }) => hideNotification(persistKey));

        function doActions(onClicks, persistKey) {
            for (const { action, value } of onClicks) {
                if (action === ACTION_DISMISS) {
                    hideNotification(persistKey);
                } else if (action === ACTION_SHOW_NOTIFICATION) {
                    enqueueSnackbar(value, { variant: "info" });
                } else if (action === ACTION_SET_SHOWN) {
                    setStorageVisibility(persistKey);
                } else if (action === ACTION_PLAY_AGAIN) {
                    sendGameStart(value);
                } else if (action === ACTION_PLAY_SOMETHING) {
                    showGameModes();
                } else if (action === ACTION_RESPONSE) {
                    sendNotificationResponse(value);
                } else if (action === ACTION_CALLBACK) {
                    value();
                }
            }
        }

        function submitResponse(response, persistKey) {
            sendNotificationResponse(response);
            hideNotification(persistKey);
        }

        function updateResponseProgress(response) {
            sendNotificationResponse(response);
        }

        function hideNotification(persistKey) {
            if (persistKeysMap[persistKey]) {
                if (messageRemovedCallbacks[persistKey]) {
                    messageRemovedCallbacks[persistKey]();
                }
                closeSnackbar(persistKeysMap[persistKey]);
                delete persistKeysMap[persistKey];
            }
        }

        function showNotification(
            message,
            variant,
            persistKey,
            timerSeconds,
            input,
            inputProgress,
            actions,
            responses,
            responsesRaw,
            coins,
            showOnce
        ) {
            if (persistKey) {
                // Make sure the same key replaces the old one
                hideNotification(persistKey);
                if (showOnce && getStorageVisibility(persistKey)) {
                    return;
                }
            }
            const key = enqueueSnackbar(
                <NotificationMessage
                    message={message}
                    coins={coins}
                    initialTimerSeconds={timerSeconds}
                    notificationPersistKey={persistKey}
                    messageRemovedCallbacks={messageRemovedCallbacks}
                />,
                {
                    variant,
                    persist: !!persistKey,
                    action: (
                        <>
                            {input ? (
                                <TextInput
                                    placeholder={translate(input)}
                                    onMessageValue={inputProgress ? updateResponseProgress : () => {}}
                                    sendMessage={(response) =>
                                        submitResponse(inputProgress ? "responseMessageProgress" : response, persistKey)
                                    }
                                    autoFocus={true}
                                />
                            ) : null}
                            {actions.map(({ key, onClicks }) => (
                                <Button
                                    key={key}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginInlineStart: "8px" }}
                                    onClick={() => doActions(onClicks, persistKey)}>
                                    {translate(key)}
                                </Button>
                            ))}
                            {responses.length || responsesRaw.length ? (
                                <ButtonGroup
                                    orientation={isSmallScreen() ? "vertical" : "horizontal"}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginInlineStart: "8px" }}>
                                    {[
                                        { raw: false, list: responses },
                                        { raw: true, list: responsesRaw },
                                    ].map(({ list, raw }) =>
                                        list.map((response) => (
                                            <Button key={response} onClick={() => submitResponse(response, persistKey)}>
                                                {raw ? response : translate(response)}
                                            </Button>
                                        ))
                                    )}
                                </ButtonGroup>
                            ) : null}
                        </>
                    ),
                }
            );
            if (persistKey) {
                persistKeysMap[persistKey] = key;
            }
            if (variant === "error" || variant === "warning") {
                playSfx(sfxError);
            } else if (variant === "success") {
                playSfx(sfxNotificationSuccess);
            } else {
                playSfx(sfxNotificationInfo);
            }

            // TODO is this needed to be cleaned up?
            interact(".MuiSnackbar-root").draggable({
                ignoreFrom: "input, button",
                modifiers: [
                    interact.modifiers.restrictRect({
                        restriction: document.body,
                        offset: {
                            bottom: -44,
                            top: -44,
                            left: -300,
                            right: -300,
                        },
                    }),
                ],
                listeners: {
                    // call this function on every dragmove event
                    move: function dragMoveListener(event) {
                        var target = event.target;
                        // keep the dragged position in the data-x/data-y attributes
                        var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
                        var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

                        // translate the element
                        target.style.transform = "translate(" + x + "px, " + y + "px)";

                        // update the posiion attributes
                        target.setAttribute("data-x", x);
                        target.setAttribute("data-y", y);
                    },
                },
            });

            if (coins) {
                addCoins(coins);
            }
        }

        return () => {
            for (const key in persistKeysMap) {
                hideNotification(key);
            }
            offShowNotification();
            offHideNotification();
        };
    }, [enqueueSnackbar, closeSnackbar, addCoins, translate, connected]);

    return null;
});

export default Notifications;
