import { getSharedConstant } from "../constants/constants";
import { STORAGE_SETTING_LOCALE } from "../constants/storage";
import { disconnectSocket, getSocket, getSocketMaybe } from "./socket";
import { getStorageSetting } from "./storageHandlers";

export function createRoom(callback) {
    getSocket().emit("room_create", {}, callback);
}
export function onJoinRoom(callback) {
    getSocket().on("room_join", callback);
}
export function onRoomError(callback) {
    getSocket().on("room_error", callback);
}
export function joinRoom(code, yourChar, initialRoomSettings) {
    const locale = getStorageSetting(STORAGE_SETTING_LOCALE, "");
    const version = getSharedConstant("version");
    getSocket().emit("room_join", { code, locale, version, ...yourChar, ...initialRoomSettings });
}

export function onManualError(callback) {
    getSocket().on("manual_error", callback);
}
export function onRoomState(callback) {
    getSocket().on("room_state", callback);
}

export function updateActor(updates) {
    getSocket().emit("actor_update", { updates });
}
export function onActorUpdate(callback) {
    getSocket().on("actor_update", callback);
}

export function sendUserSettings(settings) {
    getSocket().emit("user_settings", { settings });
}

export function sendRoomSetting(key, value) {
    getSocket().emit("room_setting", { key, value });
}

export function onMoveActors(callback) {
    getSocket().on("actors_move", callback);
}
export function moveActor(x, isMoving, turningRight) {
    getSocket().emit("actor_move", { x, isMoving, turningRight });
    getSocket()
        .listeners("actors_move")
        .forEach((callback) =>
            callback({
                movers: [{ x, socketId: getSocket().id, isMoving, turningRight, force: true }],
            })
        );
}

export function onJumpActor(callback) {
    getSocket().on("actor_jump", callback);
}
export function jumpActor() {
    getSocket().emit("actor_jump", {});
    getSocket()
        .listeners("actor_jump")
        .forEach((callback) => callback({ id: getSocket().id }));
}

export function onStartTyping(callback) {
    getSocket().on("actor_start_typing", callback);
}
export function sendStartTyping() {
    getSocket().emit("actor_start_typing", {});
}
export function onDoneTyping(callback) {
    getSocket().on("actor_done_typing", callback);
}
export function sendDoneTyping() {
    getSocket().emit("actor_done_typing", {});
}
export function onStartDrawing(callback) {
    getSocket().on("actor_start_drawing", callback);
}
export function sendStartDrawing() {
    getSocket().emit("actor_start_drawing", {});
}
export function onDoneDrawing(callback) {
    getSocket().on("actor_done_drawing", callback);
}
export function sendDoneDrawing() {
    getSocket().emit("actor_done_drawing", {});
}
export function onMessage(callback) {
    getSocket().on("actor_message", callback);
}
export function sendMessage(message) {
    getSocket().emit("actor_message", { message });
}
export function onEmote(callback) {
    getSocket().on("actor_emote", callback);
}
export function sendEmote(emote) {
    getSocket().emit("actor_emote", { emote });
}
export function sendNotificationResponse(response) {
    getSocket().emit("notification_response", { response });
}
export function onShowNotification(callback) {
    getSocket().on("notification_show", callback);
}
export function offShowNotification() {
    const socket = getSocketMaybe();
    if (socket) {
        socket.off("notification_show");
    }
}
export function showNotification(params) {
    getSocket()
        .listeners("notification_show")
        .forEach((callback) => callback(params));
}
export function onHideNotification(callback) {
    getSocket().on("notification_hide", callback);
}
export function offHideNotification() {
    const socket = getSocketMaybe();
    if (socket) {
        socket.off("notification_hide");
    }
}
export function onNotificationTimer(callback) {
    getSocket().on("notification_timer", callback);
}
export function offNotificationTimer(callback) {
    const socket = getSocketMaybe();
    if (socket) {
        socket.off("notification_timer", callback);
    }
}

export function onSaveDrawing(callback) {
    getSocket().on("save_drawing", callback);
}
export function onDrawPath(callback) {
    getSocket().on("actor_draw", callback);
}
export function sendDrawPath(paths) {
    getSocket().emit("actor_draw", { paths });
}
export function onDrawEdit(callback) {
    getSocket().on("actor_draw_edit", callback);
}
export function sendDrawEdit(data) {
    getSocket().emit("actor_draw_edit", data);
}
export function onDrawClear(callback) {
    getSocket().on("actor_draw_clear", callback);
}
export function sendDrawClear() {
    getSocket().emit("actor_draw_clear", {});
}
export function onDrawUndo(callback) {
    getSocket().on("actor_draw_undo", callback);
}
export function sendDrawUndo() {
    getSocket().emit("actor_draw_undo", {});
}
export function onDrawLimit(callback) {
    getSocket().on("actor_draw_limit", callback);
}
export function onToggleDisableDrawing(callback) {
    getSocket().on("actor_toggle_disable_drawing", callback);
}

export function onGameStart(callback) {
    getSocket().on("game_start", callback);
}
export function sendGameStart(gameName) {
    getSocket().emit("game_start", { gameName });
}
export function onGameFinish(callback) {
    getSocket().on("game_finish", callback);
}
export function sendGameFinish() {
    getSocket().emit("game_finish", {});
}
export function sendSpectating(spectate) {
    getSocket().emit("actor_spectate", { spectate });
}
export function onPlayerActions(callback) {
    getSocket().on("actor_actions", callback);
}
export function sendPlayerAction(targetId, action) {
    getSocket().emit("actor_action", { targetId, action });
}

export function sendParticipantActionKick(targetId) {
    sendParticipantAction("kick", targetId);
}
export function sendParticipantActionPromote(targetId) {
    sendParticipantAction("promote", targetId);
}
export function sendParticipantActionBan(targetId) {
    sendParticipantAction("ban", targetId);
}
function sendParticipantAction(action, targetId) {
    getSocket().emit("participant_action", { action, targetId });
}
export function onSpotlight(callback) {
    getSocket().on("actors_splotlight", callback);
}
export function onPutBlindfold(callback) {
    getSocket().on("actor_put_blindfold", callback);
}
export function onHideBlindfold(callback) {
    getSocket().on("actor_hide_blindfold", callback);
}

export function onLeaveRoom(callback) {
    getSocket().on("room_leave", callback);
}

export function leaveRoom() {
    const socket = getSocketMaybe();
    if (socket) {
        socket.emit("room_leave", {});
        disconnectSocket();
    }
}

export function updateLocale(locale) {
    getSocket().emit("update_locale", { locale });
}
