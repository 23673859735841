import { DEFAULT_LOCALE, LOCALES } from "../constants/locales";
import { REWARDS_BY_PART } from "../constants/skins";
import {
    AUDIO_MUTED_STORAGE_KEY,
    STORAGE_COINS,
    STORAGE_HAS_SKIN,
    STORAGE_NAME,
    STORAGE_POTATO2,
    STORAGE_SAW_NOTIFICATION,
    STORAGE_SETTING_LOCALE,
    STORAGE_SKIN_PART_PREFIX,
    STORAGE_UNICORN,
} from "../constants/storage";
import { desktopLocale, inDesktop } from "./desktop/inDesktop";
import { getRandomSkin } from "./random";

function getStorageValue(key) {
    try {
        return localStorage.getItem(key);
    } catch (error) {
        console.error("Error getting storage key", key, error);
        return null;
    }
}
function setStorageValue(key, value) {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
        console.error("Error setting storage key", key, value, error);
    }
}

export function getStorageSkinPartIndex(skinPartKey, length, { optional = false } = {}) {
    const storageKey = STORAGE_SKIN_PART_PREFIX + skinPartKey;
    let skinIndexString = getStorageValue(storageKey);
    let skinIndex = +skinIndexString;

    if (skinIndexString === null || isNaN(skinIndex) || skinIndex >= length) {
        skinIndex = getRandomSkin(getStorageUnlocks(), skinPartKey, length, { optional });
        setStorageSkinPartIndex(skinPartKey, skinIndex);
    }
    return skinIndex;
}
export function setStorageSkinPartIndex(skinPartKey, skinIndex) {
    const storageKey = STORAGE_SKIN_PART_PREFIX + skinPartKey;
    setStorageValue(storageKey, skinIndex);
}

export function getStorageSetting(settingKey, defaultValue = false) {
    const rawValue = getStorageValue(settingKey);
    if (typeof defaultValue === "boolean") {
        return rawValue === null ? defaultValue : rawValue === "true";
    }
    return rawValue || defaultValue;
}
export function setStorageSetting(settingKey, value) {
    setStorageValue(settingKey, value);
}

export function getStorageVisibility(persistKey) {
    return !!getStorageValue(STORAGE_SAW_NOTIFICATION + persistKey);
}
export function setStorageVisibility(persistKey) {
    setStorageValue(STORAGE_SAW_NOTIFICATION + persistKey, "1");
}

export function getPotato2() {
    return getStorageValue(STORAGE_POTATO2) || "";
}
export function setPotato2(potato) {
    setStorageValue(STORAGE_POTATO2, potato);
}

export function getUnicorn() {
    return getStorageValue(STORAGE_UNICORN) || "";
}

export function getStorageMuted() {
    let value = getStorageValue(AUDIO_MUTED_STORAGE_KEY);
    return value === "1";
}
export function setStorageMuted(muted) {
    setStorageValue(AUDIO_MUTED_STORAGE_KEY, muted ? "1" : "");
}

export function getStorageName() {
    return getStorageValue(STORAGE_NAME) || "";
}
export function setStorageName(name) {
    setStorageValue(STORAGE_NAME, name);
}

export function getStorageCoins() {
    return +(getStorageValue(STORAGE_COINS) || 0);
}
export function setStorageCoins(coins) {
    setStorageValue(STORAGE_COINS, coins);
}
export function getStorageHasSkin(skinPart, index) {
    return !!getStorageValue(constructHasSkinKey(skinPart, index));
}
export function setStorageHasSkin(skinPart, index) {
    setStorageValue(constructHasSkinKey(skinPart, index), 1);
}
function constructHasSkinKey(skinPart, index) {
    return `${STORAGE_HAS_SKIN}-${skinPart}-${index}`;
}
export function getStorageUnlocks() {
    const unlocks = {};
    for (let part in REWARDS_BY_PART) {
        unlocks[part] = {};
        for (let partIndex in REWARDS_BY_PART[part]) {
            unlocks[part][partIndex] = getStorageHasSkin(part, partIndex);
        }
    }
    return unlocks;
}

export function ensureStorageLocale() {
    const savedLocale = getStorageSetting(STORAGE_SETTING_LOCALE, "");
    if (!savedLocale) {
        const detectedLocale = inDesktop ? desktopLocale : navigator.language;
        let locale = LOCALES.hasOwnProperty(detectedLocale) ? detectedLocale : DEFAULT_LOCALE;
        setStorageSetting(STORAGE_SETTING_LOCALE, locale);
    }
}
