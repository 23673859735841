import { useEffect } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { getStorageName, setStorageName } from "../../services/storageHandlers";

const initialState = {
    name: getStorageName(),
};
const { useGlobalState } = createGlobalState(initialState);

export function useName() {
    const [name, setName] = useGlobalState("name");

    useEffect(() => setStorageName(name), [name]);

    return [name, setName];
}
