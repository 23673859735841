import { useEffect, useState } from "react";
import { addResizeCallback, getWindowSize, removeResizeCallback } from "./windowSize";

export function useWindowSize() {
    const [windowSizes, setWindowSizes] = useState(getWindowSize());

    useEffect(() => {
        addResizeCallback(setWindowSizes);
        return () => removeResizeCallback(setWindowSizes);
    }, []);

    return windowSizes;
}
