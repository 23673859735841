import styled, { css } from "styled-components";
import { CHAR_BOTTOM_DISTANCE, CHAT_MAX_WIDTH } from "../../constants/sizes";

export const CharPosition = styled.div`
    position: fixed;
    bottom: ${CHAR_BOTTOM_DISTANCE}px;
    left: calc(50% - ${CHAT_MAX_WIDTH / 2}px);
    user-select: none;
    display: flex;
    justify-content: center;
    width: ${CHAT_MAX_WIDTH}px;
    pointer-events: none;
    ${({ alwaysOnTop }) =>
        alwaysOnTop
            ? css`
                  z-index: 99999999;
              `
            : ``}
`;
