import { Button, Card, CardActions, CardContent, Chip, Tooltip } from "@material-ui/core";
import React, { memo, useCallback } from "react";
import {
    sendParticipantActionKick,
    sendParticipantActionPromote,
    sendParticipantActionBan,
} from "../../../services/room";
import { isYourId } from "../../../services/socket";
import { useLocale } from "../../locale/LocaleProvider";
import LazyChar from "../../player/char/LazyChar";

const CharActions = memo(function CharActions({ actorId, admin, actor, disabled }) {
    const { translate } = useLocale();

    const actionKick = useCallback(() => {
        sendParticipantActionKick(actorId);
    }, [actorId]);
    const actionPromote = useCallback(() => {
        sendParticipantActionPromote(actorId);
    }, [actorId]);
    const actionBan = useCallback(() => {
        sendParticipantActionBan(actorId);
    }, [actorId]);

    const isYou = isYourId(actorId);

    return (
        <Card raised={false}>
            <CardContent>
                <bdi style={{ display: "block", textAlign: "center", marginBottom: 8 }}>{actor.name}</bdi>
                <LazyChar {...actor} />
            </CardContent>
            <CardActions>
                {admin || isYou ? null : (
                    <>
                        <Tooltip title={translate("tooltipKick")}>
                            <span>
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    disabled={disabled}
                                    onClick={actionKick}>
                                    {translate("buttonKick")}
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title={translate("tooltipPromote")}>
                            <span>
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    disabled={disabled}
                                    onClick={actionPromote}>
                                    {translate("buttonPromote")}
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title={translate("tooltipBan")}>
                            <span>
                                <Button
                                    size="small"
                                    color="secondary"
                                    variant="contained"
                                    disabled={disabled}
                                    onClick={actionBan}>
                                    {translate("buttonBan")}
                                </Button>
                            </span>
                        </Tooltip>
                    </>
                )}
                {isYou ? <Chip label={translate("labelYou")} variant="outlined" /> : null}
                {admin ? <Chip label={translate("labelAdmin")} variant="outlined" /> : null}
            </CardActions>
        </Card>
    );
});

export default CharActions;
