import { DialogTitle } from "@material-ui/core";
import React, { memo } from "react";

const DialogTitleWithAction = memo(function DialogTitleWithAction({ children }) {
    return (
        <DialogTitle>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "20px",
                }}>
                {children}
            </div>
        </DialogTitle>
    );
});

export default DialogTitleWithAction;
