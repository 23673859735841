import { useCallback } from "react";
import screenfull from "screenfull";
import { desktopToggleFullScreen } from "../desktop/desktopMessages";
import { inDesktop } from "../desktop/inDesktop";

export function useFullscreen() {
    const toggleIsFullscreen = useCallback(() => {
        if (inDesktop) {
            return desktopToggleFullScreen();
        }
        if (screenfull.isFullscreen) {
            screenfull.exit();
        } else {
            screenfull.request();
        }
    }, []);

    return [{ isFullscreenEnabled: inDesktop ? true : screenfull.isEnabled }, { toggleIsFullscreen }];
}
