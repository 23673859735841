import React, { memo, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const backgroundBase = `
background-repeat: repeat-x;
height: 100%;
width: 10000px;
right: 0;
position: fixed;
z-index: -1;
`;

const MoveSkyKeyframes = keyframes`
0% {
    transform: translateX(0);
}
100% {
    transform: translateX(512px);
}
`;
const MoveLongSkyKeyframes = keyframes`
0% {
    transform: translateX(0);
}
100% {
    transform: translateX(1024px);
}
`;

const EmptyBackground = styled.div`
    ${backgroundBase}
    background-color: #757575; /* gray 500 */
`;

const ForestSkyBackground = styled.div`
    ${backgroundBase}
    background-color: #cfeffc;
    background-image: url(bg/forest/sky.png);
    animation: ${MoveSkyKeyframes} 20s linear infinite;
    background-position-y: calc(100% - 50px);
`;

const ForestBodyBackground = styled.div`
    ${backgroundBase}
    background-image: url(bg/forest/grass_trans.png);
    background-position-y: calc(100% + 27px);
`;

const SunsetSkyBackground = styled.div`
    ${backgroundBase}
    background-color: #e6d4be;
    background-image: url(bg/sunset/sky.png);
    animation: ${MoveLongSkyKeyframes} 40s linear infinite;
    background-position-y: calc(100% - 50px);
`;

const SunsetBodyBackground = styled.div`
    ${backgroundBase}
    background-image: url(bg/sunset/floor.png);
    background-position-y: calc(100%);
`;

const BeachSkyBackground = styled.div`
    ${backgroundBase}
    background-color: #00cdef;
    background-image: url(bg/beach/sky.png);
    animation: ${MoveLongSkyKeyframes} 40s linear infinite;
    background-position-y: calc(100% - 50px);
`;

const BeachBodyBackground = styled.div`
    ${backgroundBase}
    background-image: url(bg/beach/floor.png);
    background-position-y: calc(100%);
`;

let setBackgroundHandler;
export function setBackgroundSetting(backgroundSetting) {
    if (setBackgroundHandler) {
        setBackgroundHandler(backgroundSetting);
    }
}

const Background = memo(function Background() {
    const [backgroundSetting, setBackgroundSetting] = useState("");

    useEffect(() => {
        setBackgroundHandler = (backgroundSetting) => {
            setBackgroundSetting(backgroundSetting);
        };

        return () => {
            setBackgroundHandler = null;
        };
    }, []);

    if (backgroundSetting === "backgroundNone") {
        return <EmptyBackground />;
    }
    if (backgroundSetting === "backgroundSunset") {
        return (
            <>
                <SunsetSkyBackground />
                <SunsetBodyBackground />
            </>
        );
    }
    if (backgroundSetting === "backgroundBeach") {
        return (
            <>
                <BeachSkyBackground />
                <BeachBodyBackground />
            </>
        );
    }

    // default to forest
    return (
        <>
            <ForestSkyBackground />
            <ForestBodyBackground />
        </>
    );
});

export default Background;
