import { Button, SnackbarContent } from "@material-ui/core";
import { CancelOutlined } from "@material-ui/icons";
import React, { memo } from "react";
import { sendGameFinish } from "../../../services/room";
import { useLocale } from "../../locale/LocaleProvider";
import CharActions from "./CharActions";

const ParticipantsSettings = memo(function ParticipantsSettings({ actors, admins, disabled, currentGameName }) {
    const { translate } = useLocale();

    return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: 16 }}>
            {currentGameName ? (
                <SnackbarContent
                    message={translate("warningActionsInGame")}
                    action={
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            startIcon={<CancelOutlined />}
                            onClick={sendGameFinish}>
                            {translate("tooltipAbortGame")}
                        </Button>
                    }
                />
            ) : null}
            {Object.keys(actors).map((actorId) => (
                <CharActions
                    key={actorId}
                    actorId={actorId}
                    actor={actors[actorId]}
                    admin={!!admins[actorId]}
                    disabled={!!(disabled || currentGameName)}
                />
            ))}
        </div>
    );
});

export default ParticipantsSettings;
