import { Button, Card, CardActions, CardContent, Fade } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { getAllFeedbacks, getNextFeedback, setFeedbackRead } from "../services/api";

const Container = styled.div({
    textAlign: "center",
});

const Title = styled.h1`
    margin: 60px auto 40px;
`;

const BlockContainer = styled.div({
    margin: "0 auto 24px",
    position: "relative",
    maxWidth: "320px",
});

const FeedbackExhibit = memo(function FeedbackExhibit() {
    const [feedbackId, setFeedbackId] = useState("");
    const [feedback, setFeedback] = useState("");
    const [feedbacks, setFeedbacks] = useState([]);
    const [showFeedback, setShowFeedback] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [showAll, setShowAll] = useState(false);

    const loadNextFeedback = useCallback(() => {
        setShowFeedback(false);
        getNextFeedback()
            .then(({ feedback, error }) => {
                if (error) {
                    return Promise.reject(error);
                }
                console.info("Showing feedback", feedback);
                setFeedback(feedback.feedback);
                setFeedbackId(feedback._id);
                setShowFeedback(true);
            })
            .catch((error) => {
                console.error("Error while fetching feedback", error);
                enqueueSnackbar(`Could not display feedback: ${error}`, {
                    variant: "warning",
                    anchorOrigin: { horizontal: "right", vertical: "bottom" },
                });
            });
    }, [enqueueSnackbar]);

    useEffect(() => loadNextFeedback(), [loadNextFeedback]);

    const markFeedbackRead = useCallback(() => {
        setFeedbackRead(feedbackId)
            .then(({ error }) => {
                if (error) {
                    return Promise.reject(error);
                }
                enqueueSnackbar(`Feedback has been marked as read.`, {
                    autoHideDuration: 2000,
                });
                loadNextFeedback();
            })
            .catch((error) => {
                console.error("Error while marking feedback as read", error);
                enqueueSnackbar(`Could not mark feedback as read: ${error}`, {
                    variant: "warning",
                    anchorOrigin: { horizontal: "right", vertical: "bottom" },
                });
            });
    }, [enqueueSnackbar, feedbackId, loadNextFeedback]);

    useEffect(() => {
        if (showAll && !feedbacks.length) {
            setShowFeedback(false);
            getAllFeedbacks()
                .then(({ feedbacks, error }) => {
                    if (error) {
                        return Promise.reject(error);
                    }
                    console.info("Showing all feedbacks", feedbacks);
                    setFeedbacks(feedbacks.map((feedbackObject) => feedbackObject.feedback));
                    setShowFeedback(true);
                })
                .catch((error) => {
                    console.error("Error while fetching feedbacks", error);
                    enqueueSnackbar(`Could not display feedbacks: ${error}`, {
                        variant: "warning",
                        anchorOrigin: { horizontal: "right", vertical: "bottom" },
                    });
                });
        }
    }, [enqueueSnackbar, showAll, feedbacks]);

    const displayFeedbacks = showAll ? feedbacks : feedback ? [feedback] : [];

    return (
        <Container>
            <Title>
                Feedbacks!{" "}
                {showAll ? (
                    <Button color="primary" onClick={() => setShowAll(false)}>
                        Show unread
                    </Button>
                ) : (
                    <Button color="primary" onClick={() => setShowAll(true)}>
                        Show all
                    </Button>
                )}
            </Title>
            <div
                style={{
                    maxHeight: 700,
                    overflow: "auto",
                }}>
                {displayFeedbacks.map((displayFeedback, index) => (
                    <BlockContainer key={index}>
                        <Fade in={showFeedback}>
                            <Card>
                                <CardContent>{displayFeedback}</CardContent>
                                {feedback ? (
                                    <CardActions>
                                        <Button disabled={!showFeedback} color="primary" onClick={markFeedbackRead}>
                                            Mark as read
                                        </Button>
                                    </CardActions>
                                ) : null}
                            </Card>
                        </Fade>
                    </BlockContainer>
                ))}
            </div>
        </Container>
    );
});

export default FeedbackExhibit;
