import { Button, Card, CardActionArea, CardActions, CardContent, SvgIcon, Tooltip } from "@material-ui/core";
import { LockOutlined } from "@material-ui/icons";
import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { REWARDS_BY_PART, SKIN_PARTS_BY_KEY } from "../../constants/skins";
import { ReactComponent as CoinsIcon } from "../../icons/two-coins.svg";
import { useCoins } from "../../services/profile/coins";
import { isSkinPartLocked, useUnlocks } from "../../services/profile/unlocks";
import { useSkin } from "../../services/profile/useSkin";
import { useLocale } from "../locale/LocaleProvider";
import LazyChar from "../player/char/LazyChar";

const LockedOverlay = styled.div`
    ${({ isLocked }) => (isLocked ? "opacity: 0.4;" : "")}
`;
const LockIcon = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const CharCard = memo(function CharCard({ selected, part, partIndex, onClick = () => {} }) {
    const [indexes] = useSkin();
    const [coins] = useCoins();
    const [unlocks, { unlockSkin }] = useUnlocks();
    const { translate } = useLocale();
    const partObject = SKIN_PARTS_BY_KEY[part];

    const isLocked = isSkinPartLocked(unlocks, part, partIndex);
    const onUnlockSkinClick = useCallback(() => {
        if (coins >= REWARDS_BY_PART[part][partIndex].cost) {
            unlockSkin(part, partIndex);
        }
    }, [coins, part, partIndex, unlockSkin]);

    return (
        <Card raised={selected}>
            <CardActionArea onClick={onClick} disabled={isLocked}>
                <CardContent>
                    <div style={{ position: "relative" }}>
                        <LockedOverlay isLocked={isLocked}>
                            <LazyChar {...indexes} {...{ [part]: partIndex }} />
                        </LockedOverlay>
                        {isLocked ? (
                            <LockIcon>
                                <LockOutlined />
                            </LockIcon>
                        ) : null}
                    </div>
                </CardContent>
            </CardActionArea>
            {isLocked ? (
                <Tooltip
                    title={
                        translate("tooltipCurrentCoins", { coins }) +
                        " " +
                        (coins < REWARDS_BY_PART[part][partIndex].cost
                            ? translate("tooltipHowCoins")
                            : translate("tooltipClickUnlock"))
                    }>
                    <CardActions style={{ justifyContent: "center" }}>
                        <Button
                            size="small"
                            color="primary"
                            variant="outlined"
                            disabled={coins < REWARDS_BY_PART[part][partIndex].cost}
                            startIcon={
                                <SvgIcon>
                                    <CoinsIcon />
                                </SvgIcon>
                            }
                            onClick={onUnlockSkinClick}>
                            {REWARDS_BY_PART[part][partIndex].cost}
                        </Button>
                    </CardActions>
                </Tooltip>
            ) : (
                <CardActions style={{ justifyContent: "center" }}>
                    <Button size="small" color="primary" disabled={selected || partObject.usage} onClick={onClick}>
                        {partObject.usage
                            ? translate("skinOwned")
                            : selected
                            ? translate("skinSelected")
                            : translate("skinCanSelect")}
                    </Button>
                </CardActions>
            )}
        </Card>
    );
});

export default CharCard;
