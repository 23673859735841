import styled, { css, keyframes } from "styled-components";
import { JUMP_DURATION } from "../../constants/durations";
import { JUMP_MODIFIER } from "../../constants/modifiers";

const JumpKeyframes = keyframes`
0% { 
    animation-timing-function: cubic-bezier(0.33333, 0.66667, 0.66667, 1);
} 
55% {
    animation-timing-function: cubic-bezier(0.33333, 0, 0.66667, 0.33333);
    transform: translateY(-${JUMP_MODIFIER}px);
}
`;

export const JumpContainer = styled.div`
    position: relative;
    width: 100%;
    ${(props) =>
        props.isJumping
            ? css`
                  animation: ${JumpKeyframes} ${JUMP_DURATION}ms;
              `
            : ``}
`;
