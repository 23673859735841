import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Fab,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Switch,
    Tooltip,
} from "@material-ui/core";
import { Fullscreen, SettingsOutlined } from "@material-ui/icons";
import IdleTracker from "idle-tracker";
import React, { memo, useCallback, useEffect, useState } from "react";
import { AFK_SPECTATE_DURATION } from "../../../constants/durations";
import { getMuted, playSfx, setAudioMute, sfxClick } from "../../../services/audio";
import { desktopQuit } from "../../../services/desktop/desktopMessages";
import { isInputActive } from "../../../services/input/active";
import { inDesktop } from "../../../services/desktop/inDesktop";
import { sendUserSettings } from "../../../services/room";
import { useFullscreen } from "../../../services/settings/useFullscreen";
import { useConnection } from "../../../services/useConnection";
import { useSetting } from "../../../services/useSettings";
import { useLocale } from "../../locale/LocaleProvider";
import {
    STORAGE_SETTING_LOCALE,
    STORAGE_SETTING_PROFANITY,
    STORAGE_SETTING_STREAMER,
} from "./../../../constants/storage";
import AboutAction from "../AboutAction";
import DialogTitleWithAction from "../DialogTitleWithAction";
import Feedback from "../Feedback";
import SettingLocale from "./SettingLocales";

const KEY_ESCAPE = 27;

let leaveRoomSettingHandler = null;
export function setLeaveRoomSettingHandler(handler) {
    leaveRoomSettingHandler = handler;
}

const SettingsAction = memo(function SettingsAction() {
    const [showSettings, setShowSettings] = useState(false);
    const [streamerMode, setStreamerMode] = useSetting(STORAGE_SETTING_STREAMER);
    const [locale, setLocale] = useSetting(STORAGE_SETTING_LOCALE, { persist: true, defaultValue: "" });
    // const [privateDrawings, setPrivateDrawings] = useSetting(STORAGE_SETTING_PRIVATE);
    const [{ isFullscreenEnabled }, { toggleIsFullscreen }] = useFullscreen();
    const [audioOn, setAudioOn] = useState(!getMuted());
    const [isAfk, setIsAfk] = useState(false);
    const [spectate, setSpectate] = useState(false);
    const [profanityFilter, setProfanityFilter] = useSetting(STORAGE_SETTING_PROFANITY);
    const { translate } = useLocale();
    const [connected] = useConnection();

    const toggleMute = useCallback(() => setAudioOn((audioOn) => !audioOn), []);

    useEffect(() => setAudioMute(!audioOn), [audioOn]);

    useEffect(() => {
        const callback = (event) => {
            if (event.which === KEY_ESCAPE) {
                // Ignore when an input is focused
                if (isInputActive()) {
                    return;
                }
                // Material dialogs already close on escape
                if (showSettings) {
                    return;
                }
                setShowSettings(true);
            }
        };
        window.addEventListener("keydown", callback);
        return () => window.removeEventListener("keydown", callback);
    }, [showSettings]);

    useEffect(() => {
        const idleTracker = new IdleTracker({
            timeout: AFK_SPECTATE_DURATION,
            onIdleCallback: ({ idle }) => setIsAfk(idle),
        });
        idleTracker.start();

        return () => idleTracker.end();
    }, [setSpectate]);

    const openSettingsDialog = useCallback(() => {
        setShowSettings(true);
        playSfx(sfxClick);
    }, []);
    const closeSettingsDialog = useCallback(() => {
        setShowSettings(false);
    }, []);
    const quitApp = useCallback(() => {
        desktopQuit();
    }, []);

    const updateStreamerMode = useCallback((event) => setStreamerMode(event.target.checked), [setStreamerMode]);

    // const updatePrivateDrawings = useCallback(event => setPrivateDrawings(event.target.checked), [setPrivateDrawings]);

    const toggleSpectate = useCallback(() => setSpectate((spectate) => !spectate), []);

    const toggleProfanityFilter = useCallback(
        (event) => setProfanityFilter(event.target.checked),
        [setProfanityFilter]
    );

    const selectLocale = useCallback(
        (event) => {
            setLocale(event.target.value);
        },
        [setLocale]
    );

    useEffect(() => {
        if (connected) {
            sendUserSettings({
                streamerMode,
                // privateDrawings,
                audioOn,
                spectate,
                profanityFilter,
                isAfk,
            });
        }
    }, [connected, streamerMode, audioOn, spectate, profanityFilter, isAfk]);

    return (
        <>
            <Tooltip title={translate("tooltipPreferences")} placement="bottom">
                <Fab color="default" size="small" onClick={openSettingsDialog}>
                    <SettingsOutlined />
                </Fab>
            </Tooltip>
            <Dialog open={showSettings} onClose={closeSettingsDialog} maxWidth="xs" fullWidth>
                <DialogTitleWithAction>
                    {translate("tooltipPreferences")}
                    <AboutAction />
                </DialogTitleWithAction>
                <DialogContent style={{ flexShrink: 0 }}>
                    <SettingLocale text={translate("labelLanguage")} selectLocale={selectLocale} locale={locale} />
                </DialogContent>
                <DialogContent style={{ flexShrink: 0 }}>
                    <FormControl>
                        <FormControlLabel
                            control={<Switch checked={streamerMode} onChange={updateStreamerMode} />}
                            label={translate("labelSettingStreamer")}
                        />
                        <FormHelperText>{translate("descSettingStreamer")}</FormHelperText>
                    </FormControl>
                </DialogContent>
                {/* <DialogContent style={{ flexShrink: 0 }}>
                    <FormControl>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={privateDrawings}
                                    onChange={updatePrivateDrawings}
                                    
                                />
                            }
                            label="Private Drawings"
                        />
                        <FormHelperText>
                            Prevent drawings you create during games to be exhibited on the front page
                        </FormHelperText>
                    </FormControl>
                </DialogContent> */}
                <DialogContent style={{ flexShrink: 0 }}>
                    <FormControl>
                        <FormControlLabel
                            control={<Switch checked={spectate} onChange={toggleSpectate} />}
                            label={translate("labelSettingSpectate")}
                        />
                        <FormHelperText>{translate("descSettingSpectate")}</FormHelperText>
                    </FormControl>
                </DialogContent>
                <DialogContent style={{ flexShrink: 0 }}>
                    <FormControl>
                        <FormControlLabel
                            control={<Switch checked={profanityFilter} onChange={toggleProfanityFilter} />}
                            label={translate("labelSettingProfanity")}
                        />
                        <FormHelperText>{translate("descSettingProfanity")}</FormHelperText>
                    </FormControl>
                </DialogContent>
                <DialogContent style={{ flexShrink: 0 }}>
                    <FormControl>
                        <FormControlLabel
                            control={<Switch checked={audioOn} onChange={toggleMute} />}
                            label={translate("labelSettingAudio")}
                        />
                    </FormControl>
                </DialogContent>
                {isFullscreenEnabled ? (
                    <DialogContent style={{ flexShrink: 0 }}>
                        <FormControl>
                            <Button
                                onClick={toggleIsFullscreen}
                                variant="outlined"
                                color="primary"
                                size="small"
                                startIcon={<Fullscreen />}>
                                {translate("labelSettingFullscreen")}
                            </Button>
                        </FormControl>
                    </DialogContent>
                ) : null}
                <DialogContent style={{ flexShrink: 0 }}>
                    <Feedback />
                </DialogContent>
                <DialogActions
                    style={{
                        justifyContent: "space-between",
                        flexDirection: "row-reverse",
                    }}>
                    <Button onClick={closeSettingsDialog} color="primary">
                        {translate("dialogButtonDone")}
                    </Button>
                    {leaveRoomSettingHandler ? (
                        <>
                            <Button
                                onClick={() => {
                                    leaveRoomSettingHandler();
                                    closeSettingsDialog();
                                }}
                                color="secondary"
                                variant="text"
                                style={{ marginInlineEnd: "auto" }}>
                                {translate("dialogButtonLeave")}
                            </Button>
                        </>
                    ) : null}
                    {inDesktop ? (
                        <>
                            <Button onClick={quitApp} color="secondary" variant="text">
                                {translate("dialogButtonExit")}
                            </Button>
                        </>
                    ) : null}
                </DialogActions>
            </Dialog>
        </>
    );
});

export default SettingsAction;
