import { Fab, Tooltip } from "@material-ui/core";
import { Share } from "@material-ui/icons";
import React, { memo, useCallback } from "react";
import { DOMAIN, ROOM_PATH_PREFIX } from "../../constants/constants";
import { showNotification } from "../../services/room";
import { useLocale } from "../locale/LocaleProvider";

const ShareAction = memo(function ShareAction({ roomCode, style }) {
    const { translate } = useLocale();

    const copyLink = useCallback(() => {
        const shareUrl = DOMAIN + ROOM_PATH_PREFIX + roomCode;
        navigator.clipboard.writeText(shareUrl).then(() => {
            showNotification({ messageKey: "noticeUrlCopied", variant: "success" });
        });
    }, [roomCode]);

    return navigator.clipboard ? (
        <Tooltip title={translate("tooltipShare")} placement="bottom" style={{ ...style, flexShrink: 0 }}>
            <Fab color="primary" size="small" onClick={copyLink}>
                <Share />
            </Fab>
        </Tooltip>
    ) : null;
});

export default ShareAction;
