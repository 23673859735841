import { ReactComponent as IconHe } from "../components/flags/il.svg";
import { ReactComponent as IconEn } from "../components/flags/us.svg";
import { ReactComponent as IconZh } from "../components/flags/cn.svg";
import { ReactComponent as IconFr } from "../components/flags/fr.svg";
import { ReactComponent as IconDe } from "../components/flags/de.svg";
import { ReactComponent as IconPt } from "../components/flags/pt.svg";
import { ReactComponent as IconRu } from "../components/flags/ru.svg";
import { ReactComponent as IconEs } from "../components/flags/es.svg";

export const LOCALES = {
    en: {
        display: "English",
        icon: IconEn,
    },
    es: {
        display: "Español",
        icon: IconEs,
    },
    de: {
        display: "Deutsch",
        icon: IconDe,
    },
    pt: {
        display: "Português",
        icon: IconPt,
    },
    ru: {
        display: "Русский",
        icon: IconRu,
    },
    fr: {
        display: "Français",
        icon: IconFr,
    },
    "zh-CN": {
        display: "简体中文",
        icon: IconZh,
    },
    he: {
        display: "עברית",
        icon: IconHe,
    },
};

export const DEFAULT_LOCALE = "en";
