import { Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { setCanvasStatus } from "../services/api";
import ExhibitCanvas from "./../components/draw/ExhibitCanvas";
import { ALL_STATUSES, STATUS_REVIEW } from "./../constants/statuses";

const Container = styled.div({
    textAlign: "center",
});

const Title = styled.h1`
    margin: 60px auto 40px;
`;

const BlockContainer = styled.div({
    margin: "0 auto 24px",
    position: "relative",
});

const ReviewExhibits = memo(function ReviewExhibits() {
    const [displayStatus, setDisplayStatus] = useState(STATUS_REVIEW);
    const [nextDrawingIndex, setNextDrawingIndex] = useState(0);
    const [canvasId, setCanvasId] = useState("");
    const { enqueueSnackbar } = useSnackbar();

    const updateDisplayStatus = useCallback((event) => {
        setNextDrawingIndex((nextDrawingIndex) => nextDrawingIndex + 1);
        setDisplayStatus(event.target.value);
    }, []);
    const updateDrawingStatus = useCallback(
        (newStatus) => {
            setCanvasStatus(canvasId, newStatus)
                .then(({ error }) => {
                    if (error) {
                        return Promise.reject(error);
                    }
                    enqueueSnackbar(`Drawing has been set to '${newStatus}'`, {
                        variant: "info",
                        autoHideDuration: 2000,
                    });
                    setNextDrawingIndex((nextDrawingIndex) => nextDrawingIndex + 1);
                })
                .catch((error) => {
                    enqueueSnackbar(`Error updating status to '${newStatus}'`, {
                        variant: "warning",
                        autoHideDuration: 2000,
                    });
                });
        },
        [enqueueSnackbar, canvasId],
    );

    return (
        <Container>
            <ExhibitCanvas
                status={displayStatus}
                nextDrawingIndex={nextDrawingIndex}
                setCanvasId={setCanvasId}
            />
            <Title>Review Drawings!</Title>
            <BlockContainer>
                <FormControl variant="outlined">
                    <InputLabel>Show</InputLabel>
                    <Select onChange={updateDisplayStatus} value={displayStatus}>
                        {ALL_STATUSES.map((status) => (
                            <MenuItem key={status} value={status}>
                                {status}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </BlockContainer>
            <BlockContainer>
                <ButtonGroup variant="contained" color="primary">
                    {ALL_STATUSES.map((buttonStatus) => (
                        <Button
                            key={buttonStatus}
                            disabled={buttonStatus === displayStatus}
                            onClick={() => updateDrawingStatus(buttonStatus)}
                        >
                            Set as {buttonStatus}
                        </Button>
                    ))}
                </ButtonGroup>
            </BlockContainer>
        </Container>
    );
});

export default ReviewExhibits;
