import { Button, InputAdornment, TextField } from "@material-ui/core";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { isInputActive } from "../../services/input/active";
import { useLocale } from "../locale/LocaleProvider";

const TYPING_TIMEOUT_DURATION = 2500;

const KEY_ENTER = 13;
const KEY_RIGHT = 39;
const KEY_LEFT = 37;
const KEY_ESCAPE = 27;

const TextInput = memo(function TextInput({
    sendMessage,
    onMessageValue,
    sendStartTyping,
    sendDoneTyping,
    placeholder,
    endAdornment,
    autoFocus,
}) {
    const inputRef = useRef(null);
    const [isTyping, setIsTyping] = useState(false);
    const [message, setMessage] = useState("");
    const { translate } = useLocale();

    const updateMessage = useCallback(
        (event) => {
            const newMessage = event.target.value;
            setMessage(newMessage);
            onMessageValue && onMessageValue(newMessage);
        },
        [onMessageValue]
    );

    useEffect(() => {
        setIsTyping(!!message);
        const timerId = setTimeout(() => {
            setIsTyping(false);
        }, TYPING_TIMEOUT_DURATION);
        return () => clearTimeout(timerId);
    }, [message]);

    useEffect(() => {
        if (isTyping) {
            sendStartTyping && sendStartTyping();
        } else if (!isTyping) {
            sendDoneTyping && sendDoneTyping();
        }
    }, [sendStartTyping, sendDoneTyping, isTyping]);

    const submitMessage = useCallback(() => {
        sendMessage(message);
        setMessage("");
    }, [message, sendMessage]);

    useEffect(() => {
        const callback = (event) => {
            if (event.which === KEY_ENTER) {
                if (inputRef.current === document.activeElement) {
                    if (message) {
                        submitMessage();
                    } else {
                        inputRef.current.blur();
                    }
                } else if (!isInputActive()) {
                    inputRef.current.select();
                    inputRef.current.focus();
                }
            }
            if (event.which === KEY_RIGHT || event.which === KEY_LEFT) {
                if (inputRef.current === document.activeElement) {
                    if (!message.trim()) {
                        inputRef.current.blur();
                    }
                }
            }
            if (event.which === KEY_ESCAPE) {
                if (inputRef.current === document.activeElement) {
                    inputRef.current.blur();
                }
            }
        };
        window.addEventListener("keydown", callback);
        return () => window.removeEventListener("keydown", callback);
    }, [message, submitMessage]);

    return (
        <TextField
            autoFocus={autoFocus}
            value={message}
            onChange={updateMessage}
            size="small"
            variant="outlined"
            placeholder={placeholder}
            inputProps={{ ref: inputRef, maxLength: 100, style: { unicodeBidi: "plaintext" } }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Button onClick={submitMessage} color="primary" size="small" disabled={!message}>
                            {translate("buttonChatSubmit")}
                        </Button>
                        {endAdornment ? endAdornment : null}
                    </InputAdornment>
                ),
            }}
        />
    );
});

export default TextInput;
