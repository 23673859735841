import React, { memo } from "react";
import styled, { css } from "styled-components";

const NameWrap = styled.div`
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translate(-50%, 100%);
    white-space: nowrap;
    ${({ bg }) =>
        bg
            ? css`
                  background: rgba(0, 0, 0, 0.4);
              `
            : ""}
    color: white;
    text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black;
    line-height: 100%;
    padding: 2px 8px;
    border-radius: 4px;
`;

const Name = styled.bdi`
    display: inline-block;
    vertical-align: text-top;
`;

const NameContainer = memo(function NameContainer({ children, bg = true }) {
    return (
        <NameWrap bg={bg}>
            <Name>{children}</Name>
        </NameWrap>
    );
});

export default NameContainer;
