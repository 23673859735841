import { Button, Card, CardActions, CardContent, CircularProgress, SvgIcon } from '@material-ui/core';
import { Autorenew, MeetingRoom } from '@material-ui/icons';
import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import SettingLocale from '../components/actions/settings/SettingLocales';
import { useLocale } from '../components/locale/LocaleProvider';
import { LOCALES } from '../constants/locales';
import { STORAGE_SETTING_STREAMER } from '../constants/storage';
import { loadRooms } from '../services/api';
import { useSetting } from '../services/useSettings';

const RoomsContainer = styled.div`
    display: flex;
    width: fit-content;
    max-width: 100%;
    padding: 16px;
    gap: 16px;
    box-sizing: border-box;
    overflow: auto;
    margin: auto;
`;

const PublicRooms = memo(function PublicRooms({ joinPublicRoom, maxRooms }) {
    const [loading, setLoading] = useState(true);
    const [refreshIndex, setRefreshIndex] = useState(0);
    const [rooms, setRooms] = useState([]);
    const [selectedLocale, setSelectedLocale] = useState("");
    const [streamerMode] = useSetting(STORAGE_SETTING_STREAMER);
    const { translate } = useLocale();

    const refresh = useCallback(() => {
        setRefreshIndex((refreshIndex) => refreshIndex + 1);
    }, []);
    const selectLocale = useCallback((event) => {
        setSelectedLocale(event.target.value);
    }, []);

    useEffect(() => {
        setLoading(true);
        loadRooms(selectedLocale, maxRooms)
            .then(({ rooms }) => {
                setLoading(false);
                setRooms(rooms);
            })
            .catch((error) => {
                console.error("Error fetching rooms", error);

                setLoading(false);
                setRooms([]);
            });
    }, [refreshIndex, selectedLocale, maxRooms]);

    return (
        <>
            <SettingLocale
                disabled={loading}
                selectLocale={selectLocale}
                locale={selectedLocale}
                style={{ verticalAlign: "middle", marginInlineEnd: 8 }}
                placeholder={translate("labelLanguage")}
            />
            <Button size="small" color="primary" disabled={loading} endIcon={<Autorenew />} onClick={refresh}>
                {translate("errorButtonRefresh")}
            </Button>
            <div style={{ height: 16 }} />
            {loading ? (
                <CircularProgress />
            ) : rooms.length === 0 ? (
                <Card variant="outlined" style={{ maxWidth: 200, margin: "auto" }}>
                    <CardContent>{translate("textNoRooms")}</CardContent>
                    <CardActions style={{ justifyContent: "center" }}>
                        <Button
                            size="small"
                            color="primary"
                            disabled={loading}
                            endIcon={<Autorenew />}
                            onClick={refresh}>
                            {translate("errorButtonRefresh")}
                        </Button>
                    </CardActions>
                </Card>
            ) : (
                <RoomsContainer>
                    {rooms.map((room) => {
                        const Icon = LOCALES[room.language].icon;
                        return (
                            <Card key={room.roomCode} style={{ width: 180, textAlign: "initial", flexShrink: 0 }}>
                                <CardContent>
                                    <SvgIcon fontSize="small" style={{ verticalAlign: "middle", marginInlineEnd: 8 }}>
                                        <Icon />
                                    </SvgIcon>
                                    {streamerMode ? null : <span style={{ fontWeight: "bold" }}>{room.roomCode}</span>}
                                    <div
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}>
                                        <bdi>{room.adminName}</bdi>
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <Button size="small">
                                        {room.currentSize} / {room.maxSize}
                                    </Button>
                                    <Button
                                        size="small"
                                        color="primary"
                                        startIcon={<MeetingRoom />}
                                        onClick={() => joinPublicRoom(room.roomCode)}>
                                        {translate("labelJoin")}
                                    </Button>
                                </CardActions>
                            </Card>
                        );
                    })}
                </RoomsContainer>
            )}
        </>
    );
});

export default PublicRooms;
