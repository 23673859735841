import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fade,
    FormControl,
    IconButton,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
    Tooltip,
} from "@material-ui/core";
import {
    AccessibleForward,
    AssistantPhotoOutlined,
    CancelOutlined,
    ImageSearch,
    InfoOutlined,
    MovieFilterOutlined,
    PhoneForwardedOutlined,
    PlayCircleOutline,
    PublicOutlined,
    ReportProblemOutlined,
    SettingsOutlined,
    Timer,
} from "@material-ui/icons";
import "@simonwep/pickr/dist/themes/nano.min.css";
import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { playSfx, sfxClick } from "../../services/audio";
import { sendGameFinish, sendGameStart } from "../../services/room";
import { useLocale } from "../locale/LocaleProvider";
import { CHAR_BOTTOM_DISTANCE, TOY_GAMES_OFFSET, TOY_GAMES_HEIGHT } from "../../constants/sizes";
import NameContainer from "../player/NameContainer";
import { ROOM_OBJECT_FILTER } from "../../constants/stylings";
import { openSpecificGameSettingsDialog } from "../actions/settings/RoomSettingsAction";

const GAME_ICONS = {
    gameNameMoving: PhoneForwardedOutlined,
    gameNameGuess: ImageSearch,
    gameNameMovies: MovieFilterOutlined,
    gameNameLimitless: AccessibleForward,
    gameNameSpeed: Timer,
    gameNameTutorial: AssistantPhotoOutlined,
    gameNameCategories: PublicOutlined,
};

const Container = styled.div`
    position: fixed;
    bottom: ${CHAR_BOTTOM_DISTANCE}px;
    left: calc(50% - ${TOY_GAMES_OFFSET}px);
    transform: translateX(-50%);
`;

const GameDialogRulesText = styled.span`
    white-space: pre-wrap;
`;

const GameTitle = styled.span`
    padding: 0;
    padding-inline-start: 12px;
    padding-inline-end: 16px;
    margin-inline-end: auto;
`;

const noHeightStyles = {
    height: "0",
};
const listSubheaderStyle = {
    background: "white",
};
const hiddenPlayCircleStyle = {
    visibility: "hidden",
};

let showGameModesHandler = () => {};
export function showGameModes() {
    showGameModesHandler();
}

const GamesToy = memo(function GamesToy({ currentGameName, availableGames, boxImage }) {
    const [inGameMode, setInGameMode] = useState(false);
    const [showAbortConfirmation, setShowAbortConfirmation] = useState(false);
    const [gameRulesDialog, setGameRulesDialog] = React.useState({});
    const [showGameRulesDialog, setShowGameRulesDialog] = React.useState(false);
    const { translate } = useLocale();

    const closeGameRulesDialog = useCallback(() => setShowGameRulesDialog(false), []);
    const closeAbortConfirmation = useCallback(() => setShowAbortConfirmation(false), []);

    // Games
    function toggleGameMode() {
        playSfx(sfxClick);
        setInGameMode(!inGameMode);
    }

    function selectGame(event) {
        if (event.target.value && !currentGameName) {
            setShowAbortConfirmation(false);
            playSfx(sfxClick);
            sendGameStart(event.target.value);
        }
    }

    function showAbortConfirmationHandler(event) {
        event.stopPropagation();
        playSfx(sfxClick);
        setShowAbortConfirmation(!showAbortConfirmation);
    }

    function showGameRules(event, game) {
        event.stopPropagation();
        playSfx(sfxClick);
        setGameRulesDialog(game);
        setShowGameRulesDialog(true);
    }

    function showGameSettings(event) {
        event.stopPropagation();
        playSfx(sfxClick);
        openSpecificGameSettingsDialog();
    }

    function abortGame() {
        setShowAbortConfirmation(false);
        sendGameFinish();
    }

    useEffect(() => {
        showGameModesHandler = () => {
            setInGameMode(true);
        };
        return () => (showGameModesHandler = () => {});
    }, []);

    useEffect(() => {
        if (currentGameName) {
            setInGameMode(false);
        }
    }, [currentGameName]);

    return (
        <Container>
            <NameContainer bg={false}>{translate("labelGames")}</NameContainer>
            <Fade in={inGameMode}>
                <FormControl>
                    <Select
                        open={inGameMode}
                        onClose={toggleGameMode}
                        onChange={selectGame}
                        value=""
                        style={noHeightStyles}>
                        {availableGames.map(({ category, games }) => [
                            <ListSubheader style={listSubheaderStyle}>{translate(category)}</ListSubheader>,
                            games.map((game) => (
                                <MenuItem
                                    key={game.gameName}
                                    value={game.gameName}
                                    disabled={!!currentGameName && game.gameName !== currentGameName}>
                                    {React.createElement(GAME_ICONS[game.gameName], {
                                        color: "primary",
                                    })}{" "}
                                    <GameTitle>
                                        <ListItemText
                                            primary={translate(game.gameName)}
                                            secondary={
                                                game.gameName === currentGameName
                                                    ? translate("descGameInPlay")
                                                    : translate("descGameMinPlayers", { amount: game.minPlayers })
                                            }
                                        />
                                    </GameTitle>
                                    {game.gameName === currentGameName ? (
                                        <Tooltip title={translate("tooltipAbortGame")}>
                                            <IconButton onClick={showAbortConfirmationHandler} color="secondary">
                                                <CancelOutlined />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title={translate("tooltipPlay")}>
                                            <IconButton>
                                                <PlayCircleOutline
                                                    style={currentGameName ? hiddenPlayCircleStyle : {}}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title={translate("tooltipHowPlay")}>
                                        <IconButton onClick={(event) => showGameRules(event, game)}>
                                            <InfoOutlined />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={translate("tooltipSettings")}>
                                        <IconButton onClick={showGameSettings}>
                                            <SettingsOutlined />
                                        </IconButton>
                                    </Tooltip>
                                </MenuItem>
                            )),
                        ])}
                    </Select>
                </FormControl>
            </Fade>
            {boxImage && (
                <Tooltip title={translate("tooltipPlay")} placement="left">
                    <div onClick={toggleGameMode} style={{ cursor: "pointer" }}>
                        <img
                            src={`toys/boxes/${boxImage}.svg`}
                            alt=""
                            style={{ height: TOY_GAMES_HEIGHT, filter: ROOM_OBJECT_FILTER, pointerEvents: "none" }}
                        />
                    </div>
                </Tooltip>
            )}
            <Dialog open={showGameRulesDialog} onClose={closeGameRulesDialog}>
                <DialogTitle>{translate(gameRulesDialog.gameName)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <GameDialogRulesText>{translate(gameRulesDialog.rules)}</GameDialogRulesText>
                    </DialogContentText>
                    <DialogContentText>
                        {translate("descGameMinPlayers", { amount: gameRulesDialog.minPlayers })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeGameRulesDialog} color="primary">
                        {translate("dialogButtonOkay")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showAbortConfirmation} onClose={closeAbortConfirmation}>
                <DialogTitle>{translate("tooltipAbortGame")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{translate("confirmAbortGame")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={abortGame} color="secondary" startIcon={<ReportProblemOutlined />}>
                        {translate("dialogButtonAbortGame")}
                    </Button>
                    <Button onClick={closeAbortConfirmation} color="primary">
                        {translate("dialogButtonCancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
});

export default GamesToy;
