import { isNumber, range } from "lodash";
import { memo, useEffect } from "react";
import { SKIN_PART_KEY_EMOTE, TOTAL_EMOTES } from "../../constants/skins";
import { isInputActive } from "../../services/input/active";
import { isSkinPartLocked, useUnlocks } from "../../services/profile/unlocks";
import { sendEmote } from "../../services/room";

const EMOTE_KEYS = new Map(range(TOTAL_EMOTES).map(index => ["" + (index + 1), index]));

const EmotesInput = memo(function EmotesInput() {
    const [unlocks] = useUnlocks();

    useEffect(() => {
        window.addEventListener("keypress", sendEmoteIfKeyMatch);

        function sendEmoteIfKeyMatch(event) {
            // Ignore when an input is focused
            if (isInputActive()) {
                return;
            }
            const emoteIndex = EMOTE_KEYS.get(event.key);
            if (isNumber(emoteIndex) && !isSkinPartLocked(unlocks, SKIN_PART_KEY_EMOTE, emoteIndex)) {
                sendEmote(emoteIndex);
            }
        }

        return () => {
            window.removeEventListener("keypress", sendEmoteIfKeyMatch);
        };
    }, [unlocks]);

    return null;
});

export default EmotesInput;
