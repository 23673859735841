import { reportError } from "./api";

const reportedErrors = new Set();

export function errorsHandler() {
    window.addEventListener("error", function (event) {
        console.error("GOT AN ERROR", event.message, event.colno, event.lineno, event.filename);
        if (!reportedErrors.has(event.message)) {
            console.info("Reporting~", event.message);
            reportedErrors.add(event.message);
            reportError({
                message: event.message || "",
                filename: event.filename || "",
                col: event.colno || 0,
                line: event.lineno || 0,
                stack: event.error.stack || "",
            });
        }
        return false;
    });
}
