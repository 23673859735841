import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Fab,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Switch,
    TextField,
    Tooltip,
} from '@material-ui/core';
import { AccessibilityNew, MeetingRoom, PlayArrow, Shuffle } from '@material-ui/icons';
import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { CODE_RANDOMIZED_SIZE } from '../../constants/modifiers';
import PublicRooms from '../../containers/PublicRooms';
import { playSfx, sfxClick } from '../../services/audio';
import { useName } from '../../services/profile/useName';
import { useSkin } from '../../services/profile/useSkin';
import { generateId } from '../../services/random';
import { getUnicorn } from '../../services/storageHandlers';
import { isSmallScreen } from '../../services/windowSize';
import NameInput from '../customize/NameInput';
import SkinCustomizer from '../customize/SkinCustomizer';
import { useLocale } from '../locale/LocaleProvider';
import LazyChar from '../player/char/LazyChar';
import { CharPosition } from '../player/CharPosition';
import { STORAGE_SETTING_PUBLIC_ROOMS, STORAGE_SETTING_STREAMER } from './../../constants/storage';
import { useSetting } from './../../services/useSettings';

// import { STATUS_APPROVED } from "../../constants/statuses";
// import ExhibitCanvas from "../draw/ExhibitCanvas";
const Container = styled.div`
    text-align: center;
    margin: auto;
`;

const BlockContainer = styled.div({
    margin: "0 auto 16px",
    maxWidth: 300,
    position: "relative",
});

const Title = styled.div`
    margin: 40px auto 20px;
    min-height: 113px;
    display: flex;
    justify-content: center;
    position: relative;
`;
const TitleImg = styled.img`
    max-width: 100vw;
    height: 100%;
`;

const InlineTitle = styled.h3`
    display: inline-block;
    vertical-align: sub;
    margin: 0;
    text-shadow: 0.05em 0 white, 0 0.05em white, -0.05em 0 white, 0 -0.05em white, -0.05em -0.05em white,
        -0.05em 0.05em white, 0.05em -0.05em white, 0.05em 0.05em white;
`;
const OffsetFabRight = styled.div`
    display: inline-block;
    margin-inline-start: 12px;
`;

const SHOW_ROOMS_DELAY = 1000;

const CharSelect = memo(function CharSelect({ onCharSelect, code, setCode, setInitialRoomSettings }) {
    const [name] = useName();
    const [showCustomizeDialog, setShowCustomizeDialog] = useState(false);
    const [publicRoom, setPublicRoom] = useSetting(STORAGE_SETTING_PUBLIC_ROOMS, { defaultValue: true });
    const [showPublicRooms, setShowPublicRoom] = useState(false);
    const [showXRooms, setShowXRoom] = useState(false);
    const [indexes, { randomizeSkins }] = useSkin();
    const [streamerMode] = useSetting(STORAGE_SETTING_STREAMER);
    const { translate } = useLocale();
    const [showRoomsDialog, setShowRoomsDialog] = useState(false);
    const unicorn = getUnicorn();

    const openRoomsDialog = useCallback(() => {
        setShowRoomsDialog(true);
    }, []);
    const closeRoomsDialog = useCallback(() => {
        setShowRoomsDialog(false);
    }, []);

    const openCustomizeDialog = useCallback(() => {
        setShowCustomizeDialog(true);
        playSfx(sfxClick);
    }, []);
    const closeCustomizeDialog = useCallback(() => {
        setShowCustomizeDialog(false);
    }, []);

    const togglePublicRoom = useCallback(() => {
        setPublicRoom(!publicRoom);
    }, [publicRoom, setPublicRoom]);

    useEffect(() => {
        setInitialRoomSettings({ publicRoom });
    }, [setInitialRoomSettings, publicRoom]);

    const selectChar = useCallback(() => {
        playSfx(sfxClick);
        onCharSelect({
            name,
            ...indexes,
        });
    }, [name, indexes, onCharSelect]);
    const updateCode = useCallback(
        (event) => {
            if (!event.target.value.match(/[^a-zA-Z0-9]/g)) {
                setCode(event.target.value);
            }
        },
        [setCode]
    );
    const randomizeCode = useCallback(() => {
        setCode(generateId(CODE_RANDOMIZED_SIZE));
    }, [setCode]);

    const createRoom = useCallback(() => {
        randomizeCode();
        selectChar();
    }, [randomizeCode, selectChar]);

    const joinPublicRoom = useCallback(
        (roomCode) => {
            setCode(roomCode);
            selectChar();
        },
        [setCode, selectChar]
    );

    useEffect(() => {
        const timerId = setTimeout(() => {
            setShowPublicRoom(true);
        }, SHOW_ROOMS_DELAY);

        return () => {
            clearTimeout(timerId);
        };
    }, []);

    const canJoin = name && code;

    return (
        <Container>
            {/* <ExhibitCanvas status={STATUS_APPROVED} /> */}
            <CharPosition alwaysOnTop={!isSmallScreen()}>
                <LazyChar {...indexes} shadow preloadAll />
            </CharPosition>
            <Title>
                <TitleImg src="logos/hahadoodle.png" alt="Haha Doodle" />
            </Title>
            <BlockContainer>
                <NameInput autoFocus={true} />
            </BlockContainer>
            <BlockContainer>
                <InlineTitle>{translate("labelChar")}:</InlineTitle>
                <Tooltip title={translate("tooltipRandomize")}>
                    <OffsetFabRight>
                        <Fab color="primary" size="small" onClick={randomizeSkins}>
                            <Shuffle />
                        </Fab>
                    </OffsetFabRight>
                </Tooltip>
                <Tooltip title={translate("tooltipCustomize")}>
                    <OffsetFabRight>
                        <Fab color="primary" size="small" onClick={openCustomizeDialog}>
                            <AccessibilityNew />
                        </Fab>
                    </OffsetFabRight>
                </Tooltip>
                <Dialog open={showCustomizeDialog} onClose={closeCustomizeDialog} maxWidth="lg">
                    <DialogTitle>{translate("titleCharCustomize")}</DialogTitle>
                    <DialogContent style={{ flexShrink: 0 }}>
                        <SkinCustomizer />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeCustomizeDialog} color="primary">
                            {translate("dialogButtonDone")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </BlockContainer>
            <BlockContainer
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 12,
                }}>
                <div style={{ width: "50%" }}>
                    <Tooltip title={translate("descRoomSettingPublic")}>
                        <FormControl style={{ marginBottom: 8 }}>
                            <FormControlLabel
                                control={<Switch checked={publicRoom} onChange={togglePublicRoom} />}
                                label={<InlineTitle>{translate("labelRoomSettingPublic")}</InlineTitle>}
                            />
                        </FormControl>
                    </Tooltip>
                    <Tooltip
                        title={
                            code
                                ? translate("tooltipCreateCodeError", {
                                      field: translate("labelRoomCode"),
                                  })
                                : name
                                ? translate("tooltipCreateOkay")
                                : translate("tooltipCreateError", {
                                      field: translate("labelName"),
                                  })
                        }>
                        <span style={{ whiteSpace: "nowrap" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={createRoom}
                                disabled={!name || !!code}
                                startIcon={<PlayArrow />}>
                                {translate("labelCreate")}
                            </Button>
                        </span>
                    </Tooltip>
                </div>
                <InlineTitle style={{ flexShrink: 0 }}>{translate("labelOr")}</InlineTitle>
                <div style={{ width: "50%" }}>
                    <TextField
                        label={translate("labelRoomCode")}
                        variant="outlined"
                        size="small"
                        type={streamerMode ? "password" : "text"}
                        style={{
                            marginBottom: 8,
                        }}
                        value={code}
                        onChange={updateCode}
                        inputProps={{ size: 7, maxLength: 6 }}
                        InputProps={{
                            endAdornment: (
                                <Tooltip title={translate("tooltipRandomize")}>
                                    <InputAdornment position="end">
                                        <IconButton onClick={randomizeCode}>
                                            <Shuffle />
                                        </IconButton>
                                    </InputAdornment>
                                </Tooltip>
                            ),
                        }}
                    />
                    <Tooltip
                        title={
                            canJoin
                                ? translate("tooltipStartOkay")
                                : translate("tooltipStartError", {
                                      field1: translate("labelName"),
                                      field2: translate("labelRoomCode"),
                                  })
                        }>
                        <span>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={selectChar}
                                disabled={!canJoin}
                                startIcon={<MeetingRoom />}>
                                {translate("labelJoin")}
                            </Button>
                        </span>
                    </Tooltip>
                </div>
            </BlockContainer>
            <BlockContainer style={{ padding: 16 }}>
                <Divider />
            </BlockContainer>
            <BlockContainer style={{ maxWidth: "initial" }}>
                {isSmallScreen() ? (
                    <>
                        <Button onClick={openRoomsDialog} color="primary" variant="contained">
                            {translate("titlePublicRooms")}
                        </Button>
                        <Dialog open={showRoomsDialog} onClose={closeRoomsDialog} maxWidth="xs" fullWidth>
                            <DialogTitle>{translate("titlePublicRooms")}</DialogTitle>
                            <DialogContent>
                                <PublicRooms joinPublicRoom={joinPublicRoom} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeRoomsDialog} color="primary">
                                    {translate("dialogButtonClose")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                ) : (
                    <>
                        <InlineTitle style={{ display: "block", marginBottom: 8 }}>
                            {translate("titlePublicRooms")}
                        </InlineTitle>
                        {showPublicRooms ? <PublicRooms joinPublicRoom={joinPublicRoom} /> : null}
                        {showPublicRooms && unicorn ? (
                            showXRooms ? (
                                <>
                                    <InlineTitle style={{ display: "block", marginBottom: 8 }}>~X~</InlineTitle>
                                    <PublicRooms joinPublicRoom={joinPublicRoom} maxRooms />
                                </>
                            ) : (
                                <Button size="small" color="primary" onClick={() => setShowXRoom(true)}>
                                    ~X~
                                </Button>
                            )
                        ) : null}
                    </>
                )}
            </BlockContainer>
        </Container>
    );
});

export default CharSelect;
