import { useCallback, useEffect, useState } from "react";
import { getStorageSetting, setStorageSetting } from "./storageHandlers";

const callbacks = new Set();

export function useSetting(settingKey, { persist = true, defaultValue = false } = {}) {
    const [setting, setSetting] = useState(defaultValue);

    useEffect(() => {
        if (persist) {
            setSetting(getStorageSetting(settingKey, defaultValue));
        }

        function callback(key, newSetting) {
            if (key === settingKey) {
                setSetting(newSetting);
            }
        }
        callbacks.add(callback);
        return () => callbacks.delete(callback);
    }, [settingKey, persist, defaultValue]);

    const updateSetting = useCallback(
        (newSetting) => {
            if (persist) {
                setStorageSetting(settingKey, newSetting);
            }
            for (const callback of callbacks) {
                callback(settingKey, newSetting);
            }
        },
        [settingKey, persist]
    );

    return [setting, updateSetting];
}
