import React, { lazy, memo, Suspense } from "react";

const Lazied = lazy(() => import("./PartsChar"));

const LazyChar = memo(function LazyChar(props) {
    return (
        <Suspense fallback={<span />}>
            <Lazied {...props} />
        </Suspense>
    );
});

export default LazyChar;
