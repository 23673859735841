import { CHAR_WIDTH, MAX_MOVE_X } from "../constants/sizes";
import { getWindowSize } from "./windowSize";

export function getBoundedX(x) {
    if (isBeyondBounds(x)) {
        const maxWidth = getBounds();
        x = x > maxWidth ? maxWidth : -maxWidth;
    }
    return x;
}

export function isBeyondBounds(x) {
    const maxWidth = getBounds();
    return Math.abs(x) > maxWidth;
}

function getBounds() {
    return Math.min(getWindowSize().width / 2 - CHAR_WIDTH / 2, MAX_MOVE_X);
}
