import { CircularProgress } from "@material-ui/core";
import React, { memo } from "react";
import styled from "styled-components";

const LoadingContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, 0);
`;

const AppSpinner = memo(function AppSpinner() {
    return (
        <LoadingContainer>
            <CircularProgress size={80} thickness={4.4} />
        </LoadingContainer>
    );
});

export default AppSpinner;
