import { AppBar, Tab, Tabs } from "@material-ui/core";
import { range } from "lodash";
import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { CHAR_WIDTH } from "../../constants/sizes";
import { SKIN_PARTS_BY_KEY, SKIN_PARTS_STORE_ORDER } from "../../constants/skins";
import { playSfx, sfxClick } from "../../services/audio";
import { isSkinPartLocked, useUnlocks } from "../../services/profile/unlocks";
import { useSkin } from "../../services/profile/useSkin";
import { useLocale } from "../locale/LocaleProvider";
import CharCard from "./CharCard";

const cardPadding = 32;
const cardWidth = CHAR_WIDTH + cardPadding;
const cardSpace = 12;

const Container = styled.div`
    max-width: ${cardWidth * 5 + cardSpace * 4 + cardPadding}px;
`;
const SelectionGrid = styled.div`
    display: grid;
    grid-gap: ${cardSpace}px;
    grid-template-columns: repeat(auto-fit, ${cardWidth}px);
    padding: 16px;
    align-content: flex-start;
`;

const SkinCustomizer = memo(function SkinCustomizer() {
    const [expandedPart, setExpandedPart] = React.useState(0);
    const [indexes, { setSkinIndexes }] = useSkin();
    const [unlocks] = useUnlocks();
    const { translate } = useLocale();

    const handleChange = useCallback((event, panelIndex) => {
        setExpandedPart(panelIndex);
    }, []);

    const selectSkin = (part, partIndex) => () => {
        const partObject = SKIN_PARTS_BY_KEY[part];
        if (!partObject.usage && !isSkinPartLocked(unlocks, part, partIndex)) {
            setSkinIndexes({ [part]: partIndex });
            playSfx(sfxClick);
        }
    };

    return (
        <Container>
            <AppBar position="static" color="default">
                <Tabs
                    value={expandedPart}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable">
                    {SKIN_PARTS_STORE_ORDER.map(({ key, titleKey }) => (
                        <Tab key={key} label={translate(titleKey)} />
                    ))}
                </Tabs>
            </AppBar>

            {SKIN_PARTS_STORE_ORDER.map(({ key, total, optional = false }, index) => (
                <span key={key}>
                    {expandedPart === index ? (
                        <SelectionGrid>
                            {[false, true].map((showUnlocked) =>
                                (optional ? [-1] : []).concat(range(total)).map((partIndex) => {
                                    const isLocked = isSkinPartLocked(unlocks, key, partIndex);
                                    return showUnlocked === isLocked ? (
                                        <CharCard
                                            key={partIndex}
                                            part={key}
                                            partIndex={partIndex}
                                            selected={indexes[key] === partIndex}
                                            onClick={selectSkin(key, partIndex)}
                                        />
                                    ) : null;
                                })
                            )}
                        </SelectionGrid>
                    ) : null}
                </span>
            ))}
        </Container>
    );
});

export default SkinCustomizer;
