import Parallel from "paralleljs";

export function floodFill(fcanvas, fillColor, pointer) {
    const imageData = fcanvas
        .getContext()
        .getImageData(0, 0, fcanvas.lowerCanvasEl.width, fcanvas.lowerCanvasEl.height);

    return new Promise((resolve, reject) => {
        const p = new Parallel([imageData.data, imageData.width, imageData.height, fillColor, pointer]);

        p.spawn((args) => {
            const imageDataData = args[0],
                imageWidth = args[1],
                imageHeight = args[2],
                fillColor = args[3],
                pointer = args[4];
            // Taken from https://jsfiddle.net/av01d/dfvp9j2u/

            var mouseX = Math.round(pointer.x),
                mouseY = Math.round(pointer.y),
                parsedColor = hexToRgb(fillColor),
                getPointOffset = function (x, y) {
                    return 4 * (y * imageWidth + x);
                },
                targetOffset = getPointOffset(mouseX, mouseY),
                clickedColor = imageDataData.slice(targetOffset, targetOffset + 4);

            if (isColorAtPoint(clickedColor, 0, parsedColor)) {
                return [];
            }
            // Perform flood fill
            var data = fill(
                imageDataData,
                getPointOffset,
                {
                    x: mouseX,
                    y: mouseY,
                },
                clickedColor,
                imageWidth,
                imageHeight
            );

            if (0 === data.width || 0 === data.height) {
                return [];
            }
            return [data];

            function isColorAtPoint(array1, offset, array2) {
                let length = array2.length,
                    start = offset + length;

                // Iterate (in reverse) the items being compared in each array, checking their values are
                // within tolerance of each other
                while (start-- && length--) {
                    if (Math.abs(array1[start] - array2[length]) > 0) {
                        return false;
                    }
                }

                return true;
            }
            // The actual flood fill implementation
            function fill(imageData, getPointOffsetFn, clickPoint, clickedColor, width, height) {
                const directions = [
                        [1, 0],
                        [0, 1],
                        [0, -1],
                        [-1, 0],
                    ],
                    borderPoints = [],
                    points = [clickPoint],
                    seen = new Set();
                let key,
                    x,
                    y,
                    offset,
                    x2,
                    y2,
                    minX = -1,
                    maxX = -1,
                    minY = -1,
                    maxY = -1;

                let point;
                // Keep going while we have points to walk
                while (!!(point = points.pop())) {
                    x = point.x;
                    y = point.y;
                    key = x + "," + y;
                    if (seen.has(key)) {
                        continue;
                    }
                    seen.add(key);
                    // offset = getPointOffsetFn(x, y);

                    if (x > maxX) {
                        maxX = x;
                    }
                    if (y > maxY) {
                        maxY = y;
                    }
                    if (x < minX || minX === -1) {
                        minX = x;
                    }
                    if (y < minY || minY === -1) {
                        minY = y;
                    }

                    // add neighbours onto stack to traverse the fill area
                    let i = directions.length;
                    let markedCoord = false;
                    while (i--) {
                        // imageData[offset + i] = color[i];
                        // Get the new coordinate by adjusting x and y based on current step
                        x2 = x + directions[i][0];
                        y2 = y + directions[i][1];
                        offset = getPointOffsetFn(x2, y2);

                        // If new coordinate is out of bounds, or we've already added it, then skip to
                        // trying the next neighbour without adding this one
                        if (
                            x2 < 0 ||
                            y2 < 0 ||
                            x2 >= width ||
                            y2 >= height ||
                            !isColorAtPoint(imageData, offset, clickedColor)
                        ) {
                            if (!markedCoord) {
                                markedCoord = true;
                                borderPoints.push({ x, y });
                            }
                            continue;
                        }

                        // Push neighbour onto points array to be processed, and tag as seen
                        points.push({
                            x: x2,
                            y: y2,
                        });
                    }
                }

                const pointsToHandle = new Set(borderPoints);
                pointsToHandle.delete(borderPoints[0]);
                const sortedPoints = [borderPoints[0]];
                while (pointsToHandle.size > 0) {
                    const currentPoint = sortedPoints[sortedPoints.length - 1];

                    let minDistance = Infinity;
                    let minDistancePoint = null;
                    pointsToHandle.forEach((point) => {
                        const distance = Math.sqrt(
                            Math.pow(point.x - currentPoint.x, 2) + Math.pow(point.y - currentPoint.y, 2)
                        );
                        if (distance < minDistance) {
                            minDistance = distance;
                            minDistancePoint = point;
                        }
                    });
                    if (minDistancePoint) {
                        pointsToHandle.delete(minDistancePoint);
                        sortedPoints.push(minDistancePoint);
                    }
                }

                const trimmedPoints = [];
                for (let i = 0; i < sortedPoints.length; i++) {
                    const point = sortedPoints[i];
                    if (i > 0 && i < sortedPoints.length - 1) {
                        const lastPoint = trimmedPoints[trimmedPoints.length - 1];
                        const nextPoint = sortedPoints[i + 1];
                        if (
                            (point.x === lastPoint.x && point.x === nextPoint.x) ||
                            (point.y === lastPoint.y && point.y === nextPoint.y)
                        ) {
                            continue;
                        }
                    }
                    trimmedPoints.push(point);
                }

                return {
                    x: minX,
                    y: minY,
                    width: maxX - minX,
                    height: maxY - minY,
                    borderPoints: trimmedPoints,
                };
            }

            function hexToRgb(hex) {
                hex = hex.replace("#", "");
                const rgb = [],
                    re = new RegExp("(.{2})", "g");
                hex.match(re).map(function (l) {
                    rgb.push(parseInt(hex.length % 2 ? l + l : l, 16));
                    return "";
                });
                if (rgb.length === 3) {
                    rgb.push(255);
                }
                return rgb;
            }
        }).then(
            ([data]) => {
                if (!data) {
                    return resolve({});
                }

                resolve({ data });
            },
            (error) => {
                console.error("Had an error filling canvas", error);
                resolve({});
            }
        );
    });
}
