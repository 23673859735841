import { Fade } from "@material-ui/core";
import IdleTracker from "idle-tracker";
import { useSnackbar } from "notistack";
import React, { memo, useEffect, useState } from "react";
import { AFK_LOBBY_DURATION, EXHIBIT_FADE_DURATION, EXHIBIT_INTERVAL } from "../../constants/durations";
import { STATUS_APPROVED } from "../../constants/statuses";
import { abortNextCanvas, getNextCanvas } from "../../services/api";
import { useLocale } from "../locale/LocaleProvider";
import DisplayCanvas, { loadCanvasObjects } from "./DisplayCanvas";

const CANVAS_ID = "Exhibit";

const ExhibitCanvas = memo(function ExhibitCanvas({ status, nextDrawingIndex, setCanvasId }) {
    const [startRefetchTimer, setStartRefetchTimer] = useState(true);
    const [isAfk, setIsAfk] = useState(false);
    const [shouldFetch, setShouldFetch] = useState(true);
    const [showCanvas, setShowCanvas] = useState(false);
    const [canvasJson, setCanvasJson] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const { isRtl, translate } = useLocale();

    useEffect(() => {
        const idleTracker = new IdleTracker({
            timeout: AFK_LOBBY_DURATION,
            onIdleCallback: ({ idle }) => setIsAfk(idle),
        });
        idleTracker.start();
        return () => idleTracker.end();
    }, []);

    useEffect(() => {
        if (nextDrawingIndex) {
            setShouldFetch(true);
        }
    }, [nextDrawingIndex]);

    useEffect(() => {
        if (setCanvasId && canvasJson) {
            setCanvasId(canvasJson._id);
        }
    }, [setCanvasId, canvasJson]);

    useEffect(() => {
        if (startRefetchTimer) {
            const timerId = setTimeout(() => setShouldFetch(true), EXHIBIT_INTERVAL);
            return () => {
                clearTimeout(timerId);
            };
        }
    }, [startRefetchTimer]);

    useEffect(() => {
        if (shouldFetch && !isAfk) {
            setStartRefetchTimer(false);
            getNextCanvas(status)
                .then(({ canvas, error }) => {
                    if (error) {
                        return Promise.reject(error);
                    }
                    setCanvasJson(canvas);
                    console.info("Showing canvas", canvas._id);
                    setShowCanvas(false);
                })
                .catch((error) => {
                    console.error("Error while fetching canvas", error);
                    if (status !== STATUS_APPROVED) {
                        enqueueSnackbar(`Could not display preview: ${error}`, {
                            variant: "warning",
                            anchorOrigin: { horizontal: "right", vertical: "bottom" },
                        });
                    }
                    setShouldFetch(false);
                });
            return () => abortNextCanvas();
        }
    }, [enqueueSnackbar, shouldFetch, isAfk, status]);

    useEffect(() => {
        if (!showCanvas && canvasJson) {
            const timerId = setTimeout(() => {
                loadCanvasObjects(CANVAS_ID, canvasJson.objects);
                if (canvasJson.caption && canvasJson.author) {
                    enqueueSnackbar(
                        translate("showCanvas", { caption: canvasJson.caption, author: canvasJson.author }),
                        {
                            variant: "info",
                            anchorOrigin: { horizontal: "right", vertical: "bottom" },
                            TransitionProps: { direction: isRtl ? "right" : "left" },
                        }
                    );
                }
                setShouldFetch(false);
                setShowCanvas(true);
                setStartRefetchTimer(true);
            }, EXHIBIT_FADE_DURATION);
            return () => {
                clearTimeout(timerId);
            };
        }
    }, [enqueueSnackbar, showCanvas, canvasJson, isRtl, translate]);

    return (
        <Fade in={showCanvas} timeout={EXHIBIT_FADE_DURATION}>
            <div>
                <DisplayCanvas id={CANVAS_ID} />
            </div>
        </Fade>
    );
});

export default ExhibitCanvas;
