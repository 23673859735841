const currentUrl = new URL(window.location.href);
const appParam = currentUrl.searchParams.get("app");
const steamLanguage = currentUrl.searchParams.get("steamLanguage");

const STEAM_TO_WEB_LOCALE = {
    english: "en",
    schinese: "zh-CN",
    french: "fr",
    german: "de",
    portuguese: "pt",
    russian: "ru",
    spanish: "es",
};

export const inDesktop = appParam === "desktop";

export const desktopLocale = STEAM_TO_WEB_LOCALE[steamLanguage];
