import { fromPairs } from "lodash";
import { useCallback } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { SKIN_PARTS_WEARABLE } from "../../constants/skins";
import { playSfx, sfxClickHigh } from "../../services/audio";
import { getStorageSkinPartIndex, setStorageSkinPartIndex } from "../../services/storageHandlers";
import { getRandomSkin } from "../random";
import { useUnlocks } from "./unlocks";

function setIndexesStorage(indexes) {
    for (const part of SKIN_PARTS_WEARABLE) {
        if (indexes.hasOwnProperty(part.key)) {
            setStorageSkinPartIndex(part.key, indexes[part.key]);
        }
    }
}

const initialState = {
    skinIndexes: fromPairs(
        SKIN_PARTS_WEARABLE.map((part) => [
            part.key,
            getStorageSkinPartIndex(part.key, part.total, { optional: part.optional }),
        ]),
    ),
};
const { useGlobalState } = createGlobalState(initialState);

export function useSkin() {
    const [skinIndexes, setSkinIndexes] = useGlobalState("skinIndexes");
    const [unlocks] = useUnlocks();

    const updateSkinIndexes = useCallback(
        (newIndexes) => {
            setIndexesStorage(newIndexes);
            setSkinIndexes((currentIndexes) => ({ ...currentIndexes, ...newIndexes }));
        },
        [setSkinIndexes],
    );

    const randomizeSkins = useCallback(() => {
        updateSkinIndexes(
            fromPairs(
                SKIN_PARTS_WEARABLE.map((part) => [
                    part.key,
                    getRandomSkin(unlocks, part.key, part.total, { optional: part.optional }),
                ]),
            ),
        );
        playSfx(sfxClickHigh);
    }, [updateSkinIndexes, unlocks]);

    return [skinIndexes, { randomizeSkins, setSkinIndexes: updateSkinIndexes }];
}
