export const JUMP_MODIFIER = 120;
export const SPEED_MODIFIER = 5;
export const ACC_MODIFIER = 0.8;
export const MIN_MOVE_DEGREE = 15;
export const DIM_OPACITY = 0.5;
export const SECONDS_TO_PLAY_TICK = 10;
export const VOLUME_DEFAULT_VALUE = 0.25;
export const VOLUME_BGM_SONG = 0.15;
export const CODE_RANDOMIZED_SIZE = 5;

export const INITIAL_PENCIL_WIDTH = 6;
export const MIN_PENCIL_WIDTH = 1;
export const MAX_PENCIL_WIDTH = 30;
export const INITIAL_PENCIL_COLOR = "#000000";
export const PENCIL_ERASE_COLOR = "#FFFFFF";
export const MAX_UNDO_STACK_SIZE = 50;
